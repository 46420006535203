import styled from 'styled-components';
import { Grid, TextField } from '@material-ui/core';

export const FormContainer = styled(Grid)`
  text-align: start;
  align-items: baseline;
`;

export const FormInput = styled(TextField)`
  width: 100%;
`;

export const ImageInput = styled.div`
  width: 225px;
  height: 175px;
  img {
    max-width: 200px;
    max-height: 150px;
  }
`;

export const FormButtonContainer = styled.div`
  margin: 30px 0;
  display: flex;
  justify-content: space-between;
`;
