import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { SessionContext } from '../../context/sessionContext';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { userData } = useContext(SessionContext);

  const lastSubscriptionDate = new Date(userData?.subscriptionExpiryDate);
  lastSubscriptionDate.setUTCHours(23, 59, 59, 999);

  const isAuthorize = lastSubscriptionDate >= new Date();

  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuthorize && !!userData?.subscriptionExpiryDate ? (
          <>
            {alert(
              'Your subscription is expired, please make the payment to continue.'
            )}
            <Redirect
              to={{ pathname: '/bill', state: { from: props.location } }}
            />
          </>
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default PrivateRoute;
