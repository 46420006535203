import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const SecuritiesTable = ({ securities }) => (
  <TableContainer component={Paper}>
    <Table aria-label='simple table'>
      <TableHead>
        <TableRow>
          <TableCell>#</TableCell>
          <TableCell>Security Item</TableCell>
          <TableCell>Location</TableCell>
          <TableCell>Username</TableCell>
          <TableCell>Password/Security Code</TableCell>
          <TableCell>Secondary Code</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {securities?.map((security, index) => (
          <TableRow key={index}>
            <TableCell>{index + 1}</TableCell>
            <TableCell>{security.item}</TableCell>
            <TableCell>{security.location}</TableCell>
            <TableCell>{security.username}</TableCell>
            <TableCell>{security.password}</TableCell>
            <TableCell>{security.secondaryPassword}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default SecuritiesTable;
