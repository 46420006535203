import React, { Component } from 'react';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBTabPane,
  MDBTabContent,
} from 'mdbreact';
import axios from 'axios';
import { POSTURL } from '../Navigation/Constant';
import Cookies from 'universal-cookie';

import { InviteUserForm, UsersTable } from '../components';

const normalizeInput = (value, previousValue) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) return currentValue;
    if (cvLength < 7)
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  }
};

class Users extends Component {
  constructor(props) {
    super(props);
    // this.updateHandler = this.updateHandler.bind(this);
    this.onEditHandler = this.onEditHandler.bind(this);
    // this.Cancel = this.Cancel.bind(this);

    this.delete = this.delete.bind(this);
    this.state = {
      userType: '',
      Manage_acc_data: [],
      Invite_data: [],
      onManageAcc: true,
      onInvite: false,
      onSndInvite: false,
      username: '',
      email: '',
      number: '',
      accessctrl: '',
      activated: '',
      isEditing: false,
      inital_data: true,
      showButton: true,
      editbutton: true,
      editableIndex: -1,
      indexToEditContact: -1,
      activeItemClassicTabs1: '1',
      value: '',
      modal2: false,
      message: '',
      show_resend_message: '',
      compelted1: true,
      compelted2: false,
      compelted3: false,
      modal101: false,
      sendSubscriptionEmails: false,
      sendReminderEmails: false,
      sendLeaseIncreaseEmails: false,
      sendLeaseExpirationEmails: false,

      // showPopup: false,

      // resnd:""
    };

    this.onSortChange3 = this.onSortChange3.bind(this);

    const cookie = new Cookies();
    let user_id = cookie.get('myCat');
    //console.log("user_id",user_id);
    if (user_id) {
      user_id = {
        user_id: user_id[0].id,
      };
    }

    axios
      .post(POSTURL + 'fetch_users', user_id)
      .then((res) => {
        //console.log("fetch_users success ",res);
        var accc = [];
        accc = res.data.Data;
        //console.log("accc",accc);

        this.setState({ Manage_acc_data: accc });
        //console.log("Manage_acc_data",this.state.Manage_acc_data);
      })
      .catch(
        (err) => console.log('error: ')

        // console.log('error: ', err)
      );
    // this.delete = this.delete.bind(this);
    // this.togglePopup = this.togglePopup.bind(this);
    // this.changeSpecificUserDetails = this.changeSpecificUserDetails.bind(this);
    // const cookie = new Cookies();
    //    cookie.set('editUser',this.state.Manage_acc_data);
    //    //console.log("editUser",this.state.Manage_acc_data);
    //    //console.log("cookie.get('editUser')",cookie.get('editUser'));
  }

  toggle(nr, _id) {
    //console.log("nr",nr);
    //console.log("id",_id);
    if (_id) {
      this.work_order_id_for_details = _id;

      let user_id = {
        user_id: _id,
      };

      axios
        .post(POSTURL + 'fetch_single_user', user_id)
        .then((res) => {
          //console.log("fetch_single_user success ",res);
          var data = res.data.data;

          this.setState({
            sendBillingEmails: data.sendBillingEmails,
            sendSubscriptionEmails: data.sendSubscriptionEmails,
            sendReminderEmails: data.sendReminderEmails,
            sendLeaseIncreaseEmails: data.sendLeaseIncreaseEmails,
            sendLeaseExpirationEmails: data.sendLeaseExpirationEmails,
          });
        })
        .catch((err) =>
          // console.log('error: ', err)
          console.log('error: ')
        );
    } else {
      this.setState({
        sendBillingEmails: false,
        sendSubscriptionEmails: false,
        sendReminderEmails: false,
        sendLeaseIncreaseEmails: false,
        sendLeaseExpirationEmails: false,
      });
    }
    let modalNumber = 'modal' + nr;
    this.setState({
      [modalNumber]: !this.state[modalNumber],
    });
  }

  componentDidMount() {
    this.setState({
      message: '',
    });

    const cookie = new Cookies();

    if (cookie.get('myCat')) {
    } else {
      window.location.href = '/';
    }
    let user_id = cookie.get('myCat');
    //console.log("user_id",user_id);
    if (user_id) {
      user_id = {
        user_id: user_id[0].id,
      };
    }

    axios
      .post(POSTURL + 'fetch_users', user_id)
      .then((res) => {
        //console.log("fetch_users success ",res);
        var accc = [];
        accc = res.data.Data;
        //console.log("accc",accc);

        this.setState({ Manage_acc_data: accc });
        //console.log("Manage_acc_data",this.state.Manage_acc_data);
      })
      .catch((err) =>
        // console.log('error: ', err)
        console.log('error: ')
      );
  }

  onChange_checkbox1 = (e) => {
    //console.log("fjghfjd",this.state.sendBillingEmails);
    //console.log("isChecked:boolean");
    this.setState({
      sendBillingEmails: !this.state.sendBillingEmails,
    });
    //console.log("e.target.value",e[0]);
  };

  onChange_checkbox2 = (e) => {
    //console.log("fjghfjd",this.state.sendSubscriptionEmails);
    //console.log("isChecked:boolean");
    this.setState({
      sendSubscriptionEmails: !this.state.sendSubscriptionEmails,
    });
    //console.log("e.target.value",e[0]);
  };
  onChange_checkbox3 = (e) => {
    //console.log("fjghfjd",this.state.sendReminderEmails);
    //console.log("isChecked:boolean");
    this.setState({
      sendReminderEmails: !this.state.sendReminderEmails,
    });
    //console.log("e.target.value",e[0]);
  };
  onChange_checkbox4 = (e) => {
    //console.log("fjghfjd",this.state.sendLeaseIncreaseEmails);
    //console.log("isChecked:boolean");
    this.setState({
      sendLeaseIncreaseEmails: !this.state.sendLeaseIncreaseEmails,
    });
    //console.log("e.target.value",e[0]);
  };
  onChange_checkbox5 = (e) => {
    //console.log("fjghfjd",this.state.sendLeaseExpirationEmails);
    //console.log("isChecked:boolean");
    this.setState({
      sendLeaseExpirationEmails: !this.state.sendLeaseExpirationEmails,
    });
    //console.log("e.target.value",e[0]);
  };

  Save_reminder_email = (e) => {
    e.preventDefault();

    var _id = {
      _id: this.work_order_id_for_details,
      sendBillingEmails: this.state.sendBillingEmails,
      sendSubscriptionEmails: this.state.sendSubscriptionEmails,
      sendReminderEmails: this.state.sendReminderEmails,
      sendLeaseIncreaseEmails: this.state.sendLeaseIncreaseEmails,
      sendLeaseExpirationEmails: this.state.sendLeaseExpirationEmails,
    };

    //console.log("_id",_id);

    axios
      .post(POSTURL + 'userEmailCapabilities ', _id)
      .then((res) => {
        //console.log("userEmailCapabilities  success ",res);
      })
      .catch((err) => console.log('error: ', err));

    const cookie = new Cookies();
    var user_id = cookie.get('myCat');
    //console.log("user_id",user_id);
    if (user_id) {
      user_id = {
        user_id: user_id[0].id,
      };
    }

    axios
      .post(POSTURL + 'fetch_users', user_id)
      .then((res) => {
        //console.log("fetch_users success ",res);
        var accc = [];
        accc = res.data.Data;
        //console.log("accc",accc);

        this.setState({ Manage_acc_data: accc, modal101: false });
        //console.log("Manage_acc_data",this.state.Manage_acc_data);
      })
      .catch((err) => console.log('error: '));
  };

  toggleClassicTabs1 = (tab) => () => {
    this.setState({
      message: '',
    });
    if (this.state.activeItemClassicTabs1 !== tab) {
      this.setState({
        activeItemClassicTabs1: tab,
      });
    }

    if (tab === 1) {
      this.setState({
        onManageAcc: true,
        onInvite: false,
        onSndInvite: false,
      });
      this.setState({
        compelted1: true,
        compelted2: false,
        compelted3: false,
      });

      const cookie = new Cookies();
      var user_id = cookie.get('myCat');
      //console.log("user_id",user_id);
      if (user_id) {
        user_id = {
          user_id: user_id[0].id,
        };
      }

      axios
        .post(POSTURL + 'fetch_users', user_id)
        .then((res) => {
          //console.log("fetch_users success ",res);
          var accc = [];
          accc = res.data.Data;
          //console.log("accc",accc);

          this.setState({ Manage_acc_data: accc });
          //console.log("Manage_acc_data",this.state.Manage_acc_data);
        })
        .catch((err) => console.log('error: '));
    }
    if (tab === 2) {
      this.setState({
        compelted1: false,
        compelted2: true,
        compelted3: false,
      });
      this.setState({
        onManageAcc: false,
        onInvite: true,
        onSndInvite: false,
      });

      const cookie = new Cookies();
      user_id = cookie.get('myCat');
      //console.log("user_id",user_id);
      if (user_id) {
        user_id = {
          user_id: user_id[0].id,
        };
      }

      axios
        .post(POSTURL + 'fetch_invites', user_id)
        .then((res) => {
          //console.log("fetch_invites success ",res);
          var acsss = [];
          acsss = res.data.data;
          //console.log("acsss",acsss);
          // this.setState({
          //         resnd:acsss[0].email
          // })
          this.setState({ Invite_data: acsss });
          //console.log("Invite_data",this.state.Invite_data);
        })
        .catch((err) => console.log('error: '));
    }
    if (tab === 3) {
      this.setState({
        compelted1: false,
        compelted2: false,
        compelted3: true,
      });

      this.setState({
        onManageAcc: false,
        onInvite: false,
        onSndInvite: true,
      });
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  myphnChangeHandler = ({ target: { value } }) => {
    //console.log("value",value);
    this.setState((prevState) => ({
      number: normalizeInput(value, prevState.number),
    }));
  };

  myphnChangeHandler1 = ({ target: { value } }) => {
    //console.log("myphnChangeHandler1",value);
    this.setState((prevState) => ({
      mobile: normalizeInput(value, prevState.mobile),
    }));
  };

  myEditedPhnChangeHandler = ({ target: { value } }) => {
    this.setState((prevState) => ({
      ['mobile' + this.state.indexToEditContact]: normalizeInput(
        value,
        prevState['mobile' + this.state.indexToEditContact]
      ),
    }));
  };

  ChangeHandler = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  onManageAcc = (event) => {
    event.preventDefault();

    this.setState({
      onManageAcc: true,
      onInvite: false,
      onSndInvite: false,
    });
  };

  onClickHandler = (event, data) => {
    event.preventDefault();

    this.setState({ accessctrl: data });
  };

  subSndInvite = (event) => {
    event.preventDefault();
    if (this.state.email !== '' && !this.validateEmail(this.state.email)) {
      alert('Please enter valid email address.');
    } else {
      const cookie = new Cookies();
      var user_id = cookie.get('myCat');

      const sndInvites = {
        email: this.state.email,
        number: this.state.number,
        username: this.state.username,
        activated: this.state.activated,
        userType: this.state.userType,
        companyId: user_id[0].companyId,
        user_id: user_id[0].id,
      };

      axios
        .post(POSTURL + 'sendInvite', sndInvites)
        .then((res) => {
          var css = res.data;
          if (css.status === 1) {
            this.setState({
              message: css.message,
            });

            setTimeout(
              function () {
                this.setState({
                  message: css.message,
                });
              }.bind(this),
              5000
            );
          }
        })
        .catch((err) => console.log('error: '));
      this.setState({
        onSndInvite: true,
        onManageAcc: false,
        onInvite: false,
        email: '',
        username: ' ',
        number: '',
        accessctrl: '',
        activated: '',
        userType: '',
        onEditHandler: false,
        updateHandler: false,
        Cancel: false,
        editableIndex: -1,
      });
    }
  };
  myChangeHandler = (event) => {
    this.setState({ [event.target.id]: event.target.value });
    this.setState({
      message: '',
    });
  };

  reSend = (event, email, _id, username, accessctrl) => {
    event.preventDefault();
    const cookie = new Cookies();
    var user_id = cookie.get('myCat');

    const sndInvites = {
      email: email,
      user_id: user_id[0].id,
      username: username,
      accessctrl: accessctrl,

      resend: _id,
    };

    axios
      .post(POSTURL + 'sendInvite', sndInvites)
      .then((res) => {
        this.setState({
          show_resend_message: 'Invitation resend successfully!',
        });

        setTimeout(
          function () {
            this.setState({
              show_resend_message: '',
            });
          }.bind(this),
          3000
        );
      })
      .catch((err) => console.log('error: '));
  };

  delete = (data) => {
    var id = {
      id: data,
    };
    const cookie = new Cookies();
    var user_id = cookie.get('myCat');
    if (user_id) {
      user_id = {
        user_id: user_id[0].id,
      };
    }

    axios
      .post(POSTURL + 'delete_user', id)
      .then((res) => {
        axios
          .post(POSTURL + 'fetch_users', user_id)
          .then((res) => {
            var accc = [];
            accc = res.data.Data;

            this.setState({ Manage_acc_data: accc });
          })
          .catch((err) => console.log('error: '));
      })
      .catch((err) => console.log('error: '));
  };

  validateEmail(email) {
    var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  }

  onSortChange3 = (e) => {
    this.setState({ [e.target.id]: e.target.value });
    var data = e.target.value;
    this.setState({
      userType: data,
    });
  };

  onEditHandler = (event, index, data) => {
    this.setState({
      onEditHandler: !this.state.onEditHandler,
      inital_data: false,
      showButton: false,
      editbutton: false,
      username: data.username,
      email: data.email,
      mobile: data.mobile,
      accessctrl: data.accessctrl,
      activated: data.activated,
      editableIndex: index,
    });
  };

  contactEdit = (event, data, i) => {
    if (
      this.state['email' + i] !== '' &&
      !this.validateEmail(this.state['email' + i])
    ) {
      alert('Please enter valid email address.');
    } else {
      var ProjectArr = [...this.state.Manage_acc_data];
      ProjectArr[i].username = this.state['username' + i];
      ProjectArr[i].email = this.state['email' + i];
      ProjectArr[i].mobile = this.state['mobile' + i];
      ProjectArr[i].accessctrl = this.state['accessctrl' + i];
      this.setState({ Manage_acc_data: ProjectArr, indexToEditContact: -1 });
    }

    const cookie = new Cookies();
    var user_id = cookie.get('myCat');

    const updateUser = {
      username: this.state['username' + i],
      email: this.state['email' + i],
      number: this.state['mobile' + i],
      accessctrl: this.state['accessctrl' + i],
      _id: data._id,
      superadmin_id: user_id[0].id,
    };
    axios
      .post(POSTURL + 'edit_user', updateUser)
      .then((res) => {
        //console.log("edit_user success ",res);
      })
      .catch((err) => console.log('error: '));
    if (user_id) {
      user_id = {
        user_id: user_id[0].id,
      };
    }

    axios
      .post(POSTURL + 'fetch_users', user_id)
      .then((res) => {
        var accc = [];
        accc = res.data.Data;

        this.setState({ Manage_acc_data: accc });
      })
      .catch((err) => console.log('error: '));
  };

  myEditedPhnChangeHandler = ({ target: { value } }) => {
    this.setState((prevState) => ({
      ['mobile' + this.state.indexToEditContact]: normalizeInput(
        value,
        prevState['mobile' + this.state.indexToEditContact]
      ),
    }));
  };
  onSortChange31 = (e) => {
    this.setState({ [e.target.id]: e.target.value });
    var data = e.target.value;
    this.setState({
      ['accessctrl' + this.state.indexToEditContact]: data,
    });
  };

  render() {
    return (
      <MDBContainer className='mt-70 usercontainer' flex fluid>
        <MDBRow style={{ paddingBottom: '10px' }}>
          <MDBCol size='6'>
            <span className='float-left'>
              <h2
                style={{
                  color: '#183650',
                  fontWeight: '400',
                  paddingTop: '10px',
                }}
              >
                Users
              </h2>
            </span>
          </MDBCol>

          <MDBCol size='6'>
            <MDBBtn
              rounded
              color='success'
              className='float-right roundcorner add_new_acc'
              style={{ padding: '10px 20px' }}
              active={this.state.activeItemClassicTabs1 === '3'}
              onClick={this.toggleClassicTabs1('3')}
            >
              ADD NEW ACCOUNT
            </MDBBtn>
          </MDBCol>
        </MDBRow>
        <MDBRow
          className='resndmsg'
          style={{
            color: 'red',
            justifyContent: 'center',
            textAlign: 'center',
            fontWeight: '400',
          }}
        >
          {this.state.show_resend_message}
        </MDBRow>

        <div>
          <MDBNav
            tabs
            className='bgcolordark usertab '
            style={{ color: 'white', fontSize: '15px' }}
          >
            <MDBNavItem>
              <MDBNavLink
                to='#!'
                className=''
                active={this.state.activeItemClassicTabs1 === '1'}
                onClick={this.toggleClassicTabs1('1')}
                style={{ zIndex: '-1' }}
              ></MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBNavLink
                to='#!'
                className={
                  this.state.compelted1 ? 'background_color_active' : ''
                }
                active={this.state.activeItemClassicTabs1 === '1'}
                onClick={this.toggleClassicTabs1('1')}
              >
                Manage Accounts
              </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBNavLink
                to='#!'
                className={
                  this.state.compelted3 ? 'background_color_active' : ''
                }
                active={this.state.activeItemClassicTabs1 === '3'}
                onClick={this.toggleClassicTabs1('3')}
              >
                Send Invites
              </MDBNavLink>
            </MDBNavItem>
          </MDBNav>
          <MDBTabContent activeItem={this.state.activeItemClassicTabs1}>
            <MDBTabPane tabId='1'>
              <div className='animation'>
                {this.state.activeItemClassicTabs1 === '1' && <UsersTable />}
              </div>
            </MDBTabPane>
            <MDBTabPane tabId='3'>
              {this.state.activeItemClassicTabs1 === '3' && <InviteUserForm />}
            </MDBTabPane>
          </MDBTabContent>
        </div>
        <div className='blank_div_users'></div>
      </MDBContainer>
    );
  }
}

export default Users;
