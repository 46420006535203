import React, { useContext, useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';

import { Container } from '../styles';
import { addPropertyDocument, getPropertyById } from '../../api/property';
import { SessionContext } from '../../context/sessionContext';
import {
  DocumentForm,
  DocumentsTable,
  PropertyStepper,
} from '../../components';

const DocumentsCreateEdit = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const { userData } = useContext(SessionContext);
  const { id } = useParams();

  const getProperty = async () => {
    const property = await getPropertyById(id);
    if (property.documents.length > 0) {
      setDocuments(property.documents);
    }
  };

  const addDocument = async (data) => {
    setLoading(true);
    try {
      if (!data.documentBase64) {
        toast.error('You must choose a file');
        return;
      }
      const property = await addPropertyDocument(
        { ...data, userId: userData._id },
        id
      );
      setDocuments(property.documents);
      toast.success('Document added');
    } catch {
      toast.error('An error ocurred');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProperty();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <Typography variant='h3'>Add Documents</Typography>
      <PropertyStepper step={7} propertyId={id} />
      <DocumentForm
        loading={loading}
        addDocument={addDocument}
        onSave={() => history.push('/')}
      />
      <DocumentsTable documents={documents} />
    </Container>
  );
};

export default DocumentsCreateEdit;
