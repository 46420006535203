import React from 'react';

import { Container, Description } from './styles';

const PropertyDescription = ({ property }) => (
  <Container>
    <Description>
      <b>Property Name: </b>
      {property?.name}
    </Description>
    <Description>
      <b>Store No: </b>
      {property?.no}
    </Description>
    <Description>
      <b>Sq. ft: </b>
      {property?.sqFt}
    </Description>
    <Description>
      <b>Base Monthly rent: </b>
      {property?.monthlyPay}
    </Description>
    <Description>
      <b>NNN Charges: </b>
      {property?.nnnCharges}
    </Description>
    <Description>
      <b>Total Rent: </b>
      {property?.monthlyPay + property?.nnnCharges}
    </Description>
    <Description>
      <b>Property Address 1: </b>
      {property?.address1}
    </Description>
    <Description>
      <b>Property Address 2: </b>
      {property?.address2}
    </Description>
    <Description>
      <b>Country: </b>
      {property?.country?.name}
    </Description>
    <Description>
      <b>State: </b>
      {property?.state?.name}
    </Description>
    <Description>
      <b>City: </b>
      {property?.city}
    </Description>
    <Description>
      <b>Zip Code: </b>
      {property?.zipCode}
    </Description>
  </Container>
);

export default PropertyDescription;
