import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';

const Error = () => (
  <MDBContainer className='mt-70 propertiescontainer' flex fluid>
    <div className='propinnerdiv'>
      404 not founds
      <MDBRow className='prop_head' style={{ marginBottom: '20px' }}>
        <MDBCol size='4' style={{ zIndex: '-5' }}>
          <span className='float-left'>
            <h2
              className='headingp'
              style={{
                color: '#183650',
                marginLeft: '-12px',
                fontWeight: '400',
              }}
            >
              Properties
            </h2>
          </span>
        </MDBCol>
        404 not founds
        {/*    */}
      </MDBRow>
      <div className='blank_div_property'></div>
    </div>
    <div className='blankdiv_nodata'></div>
  </MDBContainer>
);

export default Error;
