import React from 'react';
import moment from 'moment';
import { Typography } from '@material-ui/core';

import useWindowSize from '../../utils/useWindowSize';
import { Container, Description } from './styles';

const BillTitleDescription = ({ bill, isSubscribe }) => {
  const [width] = useWindowSize();

  return (
    <Container>
      {width > 775 && (
        <Description width='50%'>
          <Typography variant='h3'>Billing</Typography>
          {isSubscribe && (
            <Typography>Current Subscription - {bill.subscription}</Typography>
          )}
        </Description>
      )}
      <Description width={width > 775 ? '50%' : '100%'} textAlign='end'>
        {isSubscribe ? (
          <>
            <Typography variant={width > 775 ? 'h3' : 'h5'}>
              Amount Due - ${(bill?.due ? bill.due : 0).toFixed(2)}
            </Typography>
            <Typography>
              Your {bill.subscription} plan will automatically renew on -{' '}
              {moment(bill.expirationDate).format('MM/DD/YYYY')}
            </Typography>
          </>
        ) : (
          <>
            <Typography variant='h3'>You are not subscribed</Typography>
            <Typography>
              Your can use My Lease Reminder until -{' '}
              {moment(bill.expirationDate).format('MM/DD/YYYY')}
            </Typography>
          </>
        )}
      </Description>
    </Container>
  );
};
export default BillTitleDescription;
