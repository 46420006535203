import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Typography } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';

import { Container } from '../styles';
import {
  CreateEditUtilityForm,
  PropertyStepper,
  UtilitiesTable,
} from '../../components';
import { updateProperty, getPropertyById } from '../../api/property';

const UtilitiesCreateEdit = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [utilities, setUtilities] = useState([]);
  const { id } = useParams();

  const addUtility = (utility) => setUtilities([...utilities, utility]);

  const onSave = async () => {
    setLoading(true);
    try {
      await updateProperty({ utilities }, id);
      history.push(`/security/${id}`);
    } catch {
      toast.error('An error ocurred');
    } finally {
      setLoading(false);
    }
  };

  const getProperty = async () => {
    const property = await getPropertyById(id);
    if (property.utilities.length > 0) {
      setUtilities(property.utilities);
    }
  };

  useEffect(() => {
    getProperty();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <Typography variant='h3'>Add Utilities</Typography>
      <PropertyStepper step={4} propertyId={id} />
      <CreateEditUtilityForm
        addUtility={addUtility}
        onSave={onSave}
        onCancel={() => history.push('/')}
        loading={loading}
      />
      <UtilitiesTable utilities={utilities} />
    </Container>
  );
};

export default UtilitiesCreateEdit;
