import React from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';

import { promoCode } from '../../api/bill';
import { FormContainer, FormInput } from './styles';
import Button from '../button';

const CouponCodeForm = () => {
  const form = useFormik({
    initialValues: {
      code: '',
    },
    onSubmit: async (values) => {
      try {
        const user = JSON.parse(localStorage.getItem('userData'));
        console.log({ code: values.code, userId: user._id });
        await promoCode(user._id, values.code);
        toast.success('Coupon applied');
      } catch {
        toast.error('Invalid Coupon');
      }
    },
  });

  return (
    <div style={{ textAlign: 'left' }}>
      <h1>Coupon Code</h1>
      <FormContainer container spacing={3} style={{ marginBottom: 10 }}>
        <FormContainer item xs={12}>
          <FormInput
            name='code'
            variant='outlined'
            label='Code'
            style={{ maxWidth: 300 }}
            value={form.values.code}
            onChange={form.handleChange}
          />
        </FormContainer>
      </FormContainer>
      <Button
        variant='contained'
        type='submit'
        custom='success'
        onClick={form.handleSubmit}
      >
        Apply Coupon
      </Button>
    </div>
  );
};

export default CouponCodeForm;
