import styled from 'styled-components';
import AppBar from '@material-ui/core/AppBar';

export const Container = styled.div`
  margin-top: 70px;
  padding-left: 120px;
  padding-right: 65px;
  @media only screen and (max-width: 750px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const Bar = styled(AppBar)`
  background-color: #183650 !important;
  border-radius: 30px;
  .MuiTabs-flexContainer {
    display: flex;
    overflow: auto;
  }
  span {
    background-color: transparent;
  }
`;
