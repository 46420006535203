import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Typography } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';

import { Container } from '../styles';
import { NotesForm, NotesTable, PropertyStepper } from '../../components';
import { updateProperty, getPropertyById } from '../../api/property';

const NotesCreateEdit = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState([]);
  const { id } = useParams();

  const addNote = (note) => setNotes([...notes, note]);

  const onSave = async () => {
    setLoading(true);
    try {
      await updateProperty({ notes }, id);
      history.push(`/utility/${id}`);
    } catch {
      toast.error('An error ocurred');
    } finally {
      setLoading(false);
    }
  };

  const getProperty = async () => {
    const property = await getPropertyById(id);
    if (property.notes.length > 0) {
      setNotes(property.notes);
    }
  };

  useEffect(() => {
    getProperty();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <Typography variant='h3'>Add Notes</Typography>
      <PropertyStepper step={3} propertyId={id} />
      <NotesForm
        addNote={addNote}
        onSave={onSave}
        onCancel={() => history.push('/')}
        loading={loading}
      />
      <NotesTable notes={notes} />
    </Container>
  );
};

export default NotesCreateEdit;
