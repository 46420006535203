import React, { useContext, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import useWindowSize from '../../utils/useWindowSize';
import { Container, Card } from './styles';
import { SessionContext } from '../../context/sessionContext';
import { getRenewalExpiration } from '../../api/company';

const RenewalExpirationDescription = () => {
  const [width] = useWindowSize();
  const [exRen, setExRen] = useState({
    renewingWithin1year: 0,
    renewingWithin6months: 0,
    expiringWithin1year: 0,
    expiringWithin6months: 0,
  });
  const { company } = useContext(SessionContext);

  const getProperties = async () => {
    const expirationRenewal = await getRenewalExpiration(
      company.replaceAll(';', '%3B')
    );
    setExRen(expirationRenewal);
  };

  useEffect(() => {
    getProperties();
  }, [company]); // eslint-disable-line react-hooks/exhaustive-deps

  return width > 812 ? (
    <Card elevation={3}>
      <Typography variant='h4'>Upcoming Renewals & Expirations</Typography>
      <Container
        style={{ justifyContent: 'space-around', alignItems: 'center' }}
      >
        <Typography variant='h1'>{exRen.renewingWithin1year}</Typography>
        <Typography style={{ marginLeft: 10 }}>
          Renewing <br /> within 1 Year
        </Typography>
        <Typography variant='h1'>{exRen.expiringWithin1year}</Typography>
        <Typography style={{ marginLeft: 10 }}>
          Expiring <br /> within 1 Year
        </Typography>
        <Typography variant='h1'>{exRen.renewingWithin6months}</Typography>
        <Typography style={{ marginLeft: 10 }}>
          Renewing <br /> within 6 months
        </Typography>
        <Typography variant='h1'>{exRen.expiringWithin6months}</Typography>
        <Typography style={{ marginLeft: 10 }}>
          Expiring <br /> within 6 months
        </Typography>
      </Container>
    </Card>
  ) : (
    <>
      <Grid container spacing={24}>
        <Grid item xs={4}>
          <Typography style={{ marginTop: 40 }}>1 Year</Typography>
          <Typography style={{ marginTop: 35 }}>6 Months</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>Renewing</Typography>
          <Typography variant='h3'>{exRen.renewingWithin1year}</Typography>
          <Typography variant='h3'>{exRen.renewingWithin6months}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>Expiring</Typography>
          <Typography variant='h3'>{exRen.expiringWithin1year}</Typography>
          <Typography variant='h3'>{exRen.expiringWithin6months}</Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default RenewalExpirationDescription;
