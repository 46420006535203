import React, { Component } from 'react';
import {
  MDBContainer,
  MDBTabPane,
  MDBTabContent,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBIcon,
  MDBRow,
  MDBCol,
} from 'mdbreact';
import {
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardTitle,
  MDBCardText,
} from 'mdbreact';
import axios from 'axios';
import Cookies from 'universal-cookie';

import { POSTURL } from '../Navigation/Constant';

import ReactTooltip from 'react-tooltip';
class TabsClassic1 extends Component {
  constructor() {
    super();
    this.state = {
      activeItemClassicTabs1: '1',
      fetch_properties: [],
      fetch_recipets: [],
      fetchAllUserAccounts_data: [],
      link: '',
      single_recipet_fetch: [],
      single_invoice_fetch: [],
      fetch_invoice: [],
      tab1: true,
      tab2: false,
      tab3: false,
      tab4: false,
    };
  }

  componentDidMount() {
    const linking = {
      link: window.location.href.split('?'),
    };

    console.log('linking', linking);
    if (linking.link[1]) {
      var user_id = {
        user_id: linking.link[1],
      };
      this.setState({
        link: linking.link[1],
      });
      axios
        .post(POSTURL + 'fetch_property_store_details', user_id)
        .then((res) => {
          console.log('fetch_property_store_details success ', res);
          var css = res.data.data;
          if (css) {
            this.setState({
              fetch_properties: css,
            });
          }
        }) // re-direct to dashfetch_propertiesboard after successful profile update
        .catch((err) => console.log('error: ', err));
    }
  }
  View_details = (data, event) => {
    console.log('data', data);

    console.log('inside View_details');
    const cookie = new Cookies();
    cookie.set('myproperties', data);
    console.log("cookie.get('myproperties')", cookie.get('myproperties'));
    cookie.get('myproperties');
    this.props.history.push(`/store_details?${data}`);
  };

  toggleClassicTabs1 = (tab) => () => {
    if (this.state.activeItemClassicTabs1 !== tab) {
      this.setState({
        activeItemClassicTabs1: tab,
      });
    }
    const linking = {
      link: window.location.href.split('?'),
    };
    console.log('linking', linking);
    if (this.state.link) {
      var user_id = {
        user_id: this.state.link,
      };

      if (tab === 1) {
        console.log('inside 1');
        this.setState({
          tab1: true,
          tab2: false,
          tab3: false,
          tab4: false,
        });

        axios
          .post(POSTURL + 'fetch_property_store_details', user_id)
          .then((res) => {
            console.log('fetch_property_store_details success ', res);
            var css = [];
            css = res.data.data;
            if (css) {
              this.setState({
                fetch_properties: css,
              });
              // this.fecth_single_recipet_data(css[0]._id)
            }
          });
      }
      if (tab === 2) {
        this.setState({
          tab1: false,
          tab2: true,
          tab3: false,
          tab4: false,
        });

        console.log('inside 2');
        axios.post(POSTURL + 'fetch_users', user_id).then((res) => {
          console.log('fetch_users success ', res);
          var css = [];
          css = res.data.Data;
          if (css) {
            this.setState({
              fetchAllUserAccounts_data: css,
            });
            // this.fecth_single_recipet_data(css[0]._id)
          }
        });
      }
      if (tab === 3) {
        this.setState({
          tab1: false,
          tab2: false,
          tab3: true,
          tab4: false,
        });

        console.log('inside 3');

        axios
          .post(POSTURL + 'fetchInvoices', user_id)
          .then((res) => {
            console.log('fetchInvoices success ', res);
            var css = [];
            css = res.data.data;
            if (css) {
              this.setState({
                fetch_invoice: css,
              });
              this.fecth_single_invoice_data(css[0]._id);
            }
          }) // re-direct to dashboard after successful profile update
          .catch(
            (err) => console.log('error: ', err)
            // dispatch({this.state.data
            // 	type: GET_ERRORS,
            // 	payload: err.response
            // })
          );
      }

      if (tab === 4) {
        this.setState({
          tab1: false,
          tab2: false,
          tab3: false,
          tab4: true,
        });
        console.log('inside 4');

        axios.post(POSTURL + 'fetchReciepts', user_id).then((res) => {
          console.log('fetchReciepts success ', res);
          var css = [];
          css = res.data.data;
          if (css) {
            this.setState({
              fetch_recipets: css,
            });
            // this.fecth_single_recipet_data(css[0]._id)
          }
        });
      }
    }
  };

  fecth_single_recipet_data = (data, index, event) => {
    console.log('index', index);
    if (this.state.link) {
      var id = {
        id: data,
      };

      axios
        .post(POSTURL + 'fetch_single_receipt', id)
        .then((res) => {
          console.log('fetch_single_receipt success ', res);
          var css = [];
          css = res.data.Data[0];
          if (css) {
            this.setState({
              single_recipet_fetch: css,
            });

            console.log(
              'single_recipet_fetch',
              this.state.single_recipet_fetch
            );
          }
        }) // re-direct to dashboard after successful profile update
        .catch(
          (err) => console.log('error: ', err)
          // dispatch({
          // 	type: GET_ERRORS,
          // 	payload: err.response
          // })
        );
    }
  };

  fecth_single_invoice_data = (data, event) => {
    console.log('data', data);
    var id = {
      id: data,
    };

    axios
      .post(POSTURL + 'fetch_single_invoices', id)
      .then((res) => {
        console.log('fetch_single_invoices success ', res);
        var css = [];
        css = res.data.Data[0];
        if (css) {
          this.setState({
            single_invoice_fetch: css,
          });
        }
      }) // re-direct to dashboard after successful profile update
      .catch((err) => console.log('error: ', err));
  };
  delete_property = (_id, sr_no, event) => {
    console.log('fdhgfhfdshfgj', sr_no);
    console.log('_id', _id);
    const cookie = new Cookies();
    var user_data = cookie.get('myCat');
    console.log('user_id', user_data[0].id);
    var user_id = user_data[0].id;
    user_id = {
      user_id: user_data[0].id,
      sr_no: sr_no,
    };

    var check_delete_response;
    axios
      .post(POSTURL + 'delete_property_store_details', user_id)
      .then((res) => {
        console.log('delete_property_store_details success ', res);
        check_delete_response = res.data;
      });
    if (check_delete_response) {
      axios
        .post(POSTURL + 'fetch_property_store_details', user_id)
        .then((res) => {
          console.log('fetch_property_store_details success ', res);
          var css = res.data.data;
          if (css) {
            this.setState({
              fetch_properties: css,
            });
          }
        }) // re-direct to dashboard after successful profile update
        .catch(
          (err) => console.log('error: ', err)
          // dispatch({
          // 	type: GET_ERRORS,
          // 	payload: err.response
          // })
        );
    }
  };

  edit_property = (data) => {
    this.props.history.push(`/add_properties?edited?${data.store_sr_no}`);
  };
  back = (e) => {
    window.location.href = 'admin_panel';
  };

  render() {
    return (
      <MDBContainer className='admintabmaindiv' fluid>
        <MDBRow>
          <MDBCol className='text-left'>
            <h4>User</h4>
          </MDBCol>
          <MDBCol>
            <MDBBtn
              rounded
              color='success'
              className='float-right roundcorner back_buta'
              onClick={(event) => this.back()}
            >
              Back
            </MDBBtn>
          </MDBCol>
        </MDBRow>
        <MDBNav
          Tabs
          className='bgcolordark usertab'
          style={{ color: 'white', fontSize: '15px' }}
        >
          <MDBNavItem
            className={
              this.state.tab1 ? 'active_nav_bill' : 'nonactive_nav_bill'
            }
          >
            <MDBNavLink
              to='admin_tab'
              style={{
                color: 'white',
                paddingLeft: '50px',
                paddingTop: '15px',
                paddingBottom: '15px',
              }}
              active={this.state.activeItemClassicTabs1 === '1'}
              onClick={this.toggleClassicTabs1('1')}
            >
              Properties
            </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem
            className={
              this.state.tab2 ? 'active_nav_bill' : 'nonactive_nav_bill'
            }
          >
            <MDBNavLink
              to='admin_tab'
              style={{
                color: 'white',
                paddingTop: '15px',
                paddingBottom: '15px',
              }}
              active={this.state.activeItemClassicTabs1 === '2'}
              onClick={this.toggleClassicTabs1('2')}
            >
              User
            </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem
            className={
              this.state.tab3 ? 'active_nav_bill' : 'nonactive_nav_bill'
            }
          >
            <MDBNavLink
              to='admin_tab'
              style={{
                color: 'white',
                paddingTop: '15px',
                paddingBottom: '15px',
              }}
              active={this.state.activeItemClassicTabs1 === '3'}
              onClick={this.toggleClassicTabs1('3')}
            >
              Invoice
            </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem
            className={
              this.state.tab4 ? 'active_nav_bill' : 'nonactive_nav_bill'
            }
          >
            <MDBNavLink
              to='admin_tab'
              style={{
                color: 'white',
                paddingTop: '15px',
                paddingBottom: '15px',
              }}
              active={this.state.activeItemClassicTabs1 === '4'}
              onClick={this.toggleClassicTabs1('4')}
            >
              Receipt
            </MDBNavLink>
          </MDBNavItem>
        </MDBNav>
        <MDBTabContent
          className=''
          activeItem={this.state.activeItemClassicTabs1}
        >
          <MDBTabPane tabId='1'>
            <MDBRow className='animation' style={{ paddingLeft: '40px' }}>
              <MDBRow className='mt-50 cardrow'>
                {this.state.fetch_properties.map((fetch_properties_data) => {
                  return (
                    <MDBCol
                      className='card_col'
                      style={{ marginTop: '15px', marginBottom: '15px' }}
                    >
                      <MDBCard
                        className='greyborder card_color'
                        style={{ width: '22rem', borderRadius: '8px' }}
                      >
                        {fetch_properties_data.images1[0] && (
                          <MDBCardImage
                            className='img-fluid card_img'
                            src={fetch_properties_data.images1[0].path}
                            style={{
                              borderRadius: '8px 8px 0px 0px',
                              width: '350px',
                              height: '239px',
                            }}
                            waves
                          />
                        )}

                        <div className='cardtitlediv'>
                          <MDBCardTitle>
                            <h6 className='float-left d-flex '>
                              <div className='title_card'>
                                {fetch_properties_data.store_name}
                              </div>

                              <div
                                className='action_span justify-content-end'
                                style={{ paddingLeft: '50px' }}
                              >
                                <span
                                  className='d-inline'
                                  outline
                                  color='dark'
                                  style={{
                                    padding: '6px',
                                    marginRight: '3px',
                                    border: '0.5px solid lightgrey',
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                  }}
                                >
                                  <img
                                    alt=''
                                    src='../../img/document2.png'
                                    data-tip='edit'
                                    style={{ width: '18px' }}
                                    onClick={(event) =>
                                      this.edit_property(fetch_properties_data)
                                    }
                                  />
                                  <ReactTooltip />
                                </span>
                                <span
                                  className='d-inline '
                                  outline
                                  color='dark'
                                  style={{
                                    padding: '6px',
                                    marginRight: '5px',
                                    border: '0.5px solid lightgrey',
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                  }}
                                >
                                  <img
                                    alt=''
                                    src='../../img/bin.png'
                                    data-tip='delete'
                                    style={{ marginTop: '-2px', width: '18px' }}
                                    onClick={(event) =>
                                      this.delete_property(
                                        fetch_properties_data._id,
                                        fetch_properties_data.store_sr_no
                                      )
                                    }
                                  />
                                  <ReactTooltip />
                                </span>
                              </div>
                            </h6>
                          </MDBCardTitle>
                        </div>
                        <MDBCardBody>
                          <div
                            className='add_div'
                            class='d-flex justify-content-start'
                            style={{
                              fontStyle: 'italic',
                              marginBottom: '10px',
                            }}
                          >
                            {/*<img src="../../img/pin.png"/>*/}
                            <MDBIcon
                              icon='map-marker-alt'
                              style={{ paddingRight: '5px' }}
                            />
                            <span style={{ fontSize: '12px' }}></span>
                          </div>
                          <MDBCardText>
                            <MDBTable
                              className=' card_table'
                              borderless
                              responsiveMd
                            >
                              <MDBTableHead></MDBTableHead>
                              <MDBTableBody>
                                <tr>
                                  <td className='text-left font13'>
                                    {new Date(
                                      fetch_properties_data.current_termination_date
                                    ).toLocaleDateString()}
                                  </td>
                                  <td className='text-right font13'></td>
                                </tr>
                                <tr>
                                  <td className='text-left font13'>
                                    {' '}
                                    Term Avail
                                  </td>
                                  <td className='text-right font13'>
                                    {fetch_properties_data.terms_avaial}
                                  </td>
                                </tr>
                                <tr>
                                  <td className='text-left font13'>
                                    Renewal Notice Time
                                  </td>
                                  <td className='text-right font13'>
                                    {fetch_properties_data.renewal_notice_time}
                                  </td>
                                </tr>
                                <tr>
                                  <td className='text-left font13'>
                                    Current Term Renewal Date
                                  </td>
                                  <td className='text-right font13'>
                                    {new Date(
                                      fetch_properties_data.current_term_renewal_date
                                    ).toLocaleDateString()}
                                  </td>
                                </tr>
                                <tr>
                                  <td className='text-left font13'>
                                    Full Lease Exp
                                  </td>
                                  <td className='text-right font13'>
                                    {new Date(
                                      fetch_properties_data.full_lease_expiration
                                    ).toLocaleDateString()}
                                  </td>
                                </tr>
                              </MDBTableBody>
                            </MDBTable>
                          </MDBCardText>
                          <div className='d-flex justify-content-center'>
                            <MDBBtn
                              rounded
                              color='success'
                              className='float-right roundcorner add_new_property'
                              onClick={(event) =>
                                this.View_details(
                                  fetch_properties_data.store_sr_no
                                )
                              }
                            >
                              {' '}
                              View Details
                            </MDBBtn>
                          </div>
                        </MDBCardBody>
                      </MDBCard>
                    </MDBCol>
                  );
                })}
              </MDBRow>
            </MDBRow>
            <div style={{ height: '250px' }}></div>
          </MDBTabPane>
          <MDBTabPane tabId='2'>
            <MDBTable className='text-left tableusers animation' responsiveMd>
              <MDBTableHead>
                <tr>
                  <th className='tablehead'>Sr No</th>
                  <th className='tablehead'>Full Name</th>
                  <th className='tablehead'>Email</th>
                  <th className='tablehead'>Phone Number</th>
                  <th className='tablehead'>Access Control</th>
                  <th className='tablehead'>Activated</th>
                  {/*

               <th className="tablehead">Actions</th>
             */}
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {this.state.fetchAllUserAccounts_data.map(
                  (fetchAllUserAccounts_data1, i) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{fetchAllUserAccounts_data1.username}</td>
                        <td>{fetchAllUserAccounts_data1.email}</td>
                        <td>{fetchAllUserAccounts_data1.number}</td>
                        <td>{fetchAllUserAccounts_data1.accessctrl}</td>
                        <td>{fetchAllUserAccounts_data1.accessctrl}</td>

                        {/* <td>
           <span className="d-inline greyborder edit_user" outline color="dark" style={{padding: "5px",marginRight:"5px",borderRadius:"5px"}}>
               <img src="../../img/document2.png" style={{height:"20px",marginTop: "-4px"}}/>
            </span>
            <span className="d-inline greyborder" outline color="dark" style={{padding: "5px",marginRight:"5px",borderRadius:"5px"}}>
             <img src="../../img/bin.png" style={{height:"20px",marginTop: "-4px"}}/>
            </span>
           </td>   */}
                      </tr>
                    );
                  }
                )}
              </MDBTableBody>
            </MDBTable>
            <div style={{ height: '250px' }}></div>
          </MDBTabPane>
          <MDBTabPane tabId='3'>
            <MDBRow
              className='animation'
              style={{ paddingTop: '20px', height: '550px' }}
            >
              <MDBCol
                md='6'
                style={{
                  paddingLeft: '20px',
                  borderRight: '1px solid lightgrey',
                }}
              >
                <MDBTable
                  className='text-left invoicetable'
                  responsive
                  bordered
                >
                  <MDBTableHead>
                    <tr>
                      <th className='tablehead'>Date Create</th>
                      <th className='tablehead'>Description</th>
                      <th className='tablehead'>Amount</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {this.state.fetch_invoice.map((fetch_invoice_data) => {
                      return (
                        <tr
                          onClick={(event) =>
                            this.fecth_single_invoice_data(
                              fetch_invoice_data._id
                            )
                          }
                        >
                          <td>
                            {new Date(
                              fetch_invoice_data.date
                            ).toLocaleDateString()}
                          </td>
                          <td>{fetch_invoice_data.invoiceno}</td>
                          <td>${fetch_invoice_data.amountDue}</td>
                        </tr>
                      );
                    })}
                  </MDBTableBody>
                </MDBTable>
              </MDBCol>
              <MDBCol
                md='6'
                className='justify-content-center'
                style={{ textAlign: '-webkit-center' }}
              >
                <div className='text-left invoicediv'>
                  <MDBRow>
                    <h3>Invoice</h3>
                  </MDBRow>
                  <MDBRow style={{ paddingBottom: '20px' }}>
                    <div>
                      {this.state.single_invoice_fetch && (
                        <MDBCol style={{ paddingLeft: '0px' }}>
                          <div>
                            <h6>
                              Company name -
                              {this.state.single_invoice_fetch.invoiceno}
                            </h6>
                          </div>
                          <div style={{ paddingTop: '6px' }}>
                            Street Address -
                            {this.state.single_invoice_fetch.invoiceno}
                          </div>
                          <div style={{ paddingTop: '6px' }}>
                            City -{this.state.single_invoice_fetch.invoiceno}
                          </div>
                          <div style={{ paddingTop: '6px' }}>
                            zip -{this.state.single_invoice_fetch.invoiceno}
                          </div>
                        </MDBCol>
                      )}
                    </div>
                    <div>
                      {this.state.single_invoice_fetch && (
                        <MDBCol>
                          <div style={{ paddingTop: '6px' }}>
                            Invoice No -
                            {this.state.single_invoice_fetch.invoiceno}
                          </div>
                          <div style={{ paddingTop: '6px' }}>
                            Date -
                            {new Date(
                              this.state.single_invoice_fetch.date
                            ).toLocaleDateString()}
                          </div>
                          <div style={{ paddingTop: '6px' }}>
                            Name -{this.state.single_invoice_fetch.invoiceno}
                          </div>
                          <div style={{ paddingTop: '6px' }}>
                            Email -{this.state.single_invoice_fetch.invoiceno}
                          </div>
                        </MDBCol>
                      )}
                    </div>
                  </MDBRow>
                  <MDBRow>
                    <MDBTable
                      className='text-left receiptable'
                      responsive
                      bordered
                    >
                      <MDBTableHead>
                        <tr>
                          <th>Sr no</th>
                          <th>Description</th>
                          <th>Amount</th>
                        </tr>
                      </MDBTableHead>
                      <MDBTableBody>
                        <tr>
                          <td>1</td>
                          <td>{this.state.single_invoice_fetch.invoiceno}</td>
                          <td>{this.state.single_invoice_fetch.amountDue}</td>
                        </tr>
                      </MDBTableBody>
                    </MDBTable>
                  </MDBRow>
                  <MDBRow>
                    <i>Thank you for your bussiness!</i>
                    <span class='font14'>
                      If you have any question about this invoice, please
                      contact
                      <br />
                      [Name, Phone, email@address.com]
                    </span>
                  </MDBRow>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBTabPane>
          <MDBTabPane tabId='4'>
            <MDBRow className='animation' style={{ paddingTop: '20px' }}>
              <MDBCol
                md='6'
                style={{
                  paddingLeft: '20px',
                  borderRight: '1px solid lightgrey',
                }}
              >
                <MDBTable className='text-left receiptable' responsive bordered>
                  <MDBTableHead>
                    <tr>
                      <th className='tablehead'>Date Create</th>
                      <th className='tablehead'>Description</th>
                      <th className='tablehead'>Amount</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {this.state.fetch_recipets.map((fetch_recipets_data, i) => {
                      return (
                        <tr
                          onClick={(event) =>
                            this.fecth_single_recipet_data(
                              fetch_recipets_data._id,
                              i
                            )
                          }
                        >
                          <td>
                            {new Date(
                              fetch_recipets_data.date
                            ).toLocaleDateString()}
                          </td>
                          <td>{fetch_recipets_data.invoiceno}</td>
                          <td>${fetch_recipets_data.amount}</td>
                        </tr>
                      );
                    })}
                  </MDBTableBody>
                </MDBTable>
              </MDBCol>
              <MDBCol
                md='6'
                className='justify-content-center'
                style={{ textAlign: '-webkit-center' }}
              >
                <div className='text-left receiptdiv'>
                  <MDBRow>
                    <h3>RECEIPT</h3>
                  </MDBRow>
                  <MDBRow style={{ paddingTop: '0px' }}>
                    <MDBCol style={{ paddingTop: '0px' }}>
                      {this.state.single_recipet_fetch && (
                        <div style={{ paddingTop: '0px' }}>
                          <div>
                            Invoice.no. -{' '}
                            {this.state.single_recipet_fetch.receivedfrom}
                          </div>
                          <div>
                            Received From -{' '}
                            {this.state.single_recipet_fetch.receivedfrom}
                          </div>
                          <div>
                            Balance Due -{' '}
                            {this.state.single_recipet_fetch.balancedDue}
                          </div>
                        </div>
                      )}
                    </MDBCol>
                    <MDBCol>
                      {this.state.single_recipet_fetch && (
                        <div style={{ paddingTop: '0px' }}>
                          <div>
                            Date:-{' '}
                            {new Date(
                              this.state.single_recipet_fetch.date
                            ).toLocaleDateString()}
                          </div>
                          <div>
                            Amount- {this.state.single_recipet_fetch.amount}
                          </div>
                          <div></div>
                          <div>
                            Cash- {this.state.single_recipet_fetch.invoiceno}
                          </div>
                          <div>
                            {' '}
                            Money Order-{' '}
                            {this.state.single_recipet_fetch.invoiceno}
                          </div>
                          <div>
                            Check No.-{' '}
                            {this.state.single_recipet_fetch.invoiceno}
                          </div>
                        </div>
                      )}
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBTable
                      className='text-left receiptable'
                      responsive
                      bordered
                    >
                      <MDBTableHead>
                        <tr>
                          <th>Sr.No</th>
                          <th>Description</th>
                          <th>Amount</th>
                        </tr>
                      </MDBTableHead>
                      <MDBTableBody>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>

                        <tr>
                          <td colspan='2'>Total</td>
                          <td></td>
                        </tr>
                      </MDBTableBody>
                    </MDBTable>
                  </MDBRow>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBTabPane>
        </MDBTabContent>
      </MDBContainer>
    );
  }
}

export default TabsClassic1;
