import React, { useState } from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { MDBCol, MDBBtn } from 'mdbreact';

import { register } from '../../api/user';
import { createCompany } from '../../api/company';

const RegisterForm = () => {
  const [loading, setLoading] = useState(false);
  const form = useFormik({
    initialValues: {
      username: '',
      name: '',
      email: '',
      password: '',
    },
    onSubmit: async (values) => {
      if (values.password.length < 8) {
        toast.error('password too short');
      } else if (values.password.length > 50) {
        toast.error('password too long');
      } else if (values.password.search(/\d/) === -1) {
        toast.error(
          'Password must have at least one capital letter and one number'
        );
      } else if (values.password.search(/[a-zA-Z]/) === -1) {
        toast.error(
          'Password must have at least one capital letter and one number'
        );
      } else if (values.password.search(/[`!@#$%^&*_+\-=;:\\|,.?~]/) === -1) {
        toast.error('Password must have at least one special character');
      } else {
        try {
          setLoading(true);
          const { name, ...rest } = values;
          const response = await register(rest);
          await createCompany({ userId: response._id, name });
          toast.success('Register successfully, please check you email');
        } catch (err) {
          toast.error('An error ocurred, please try again leather');
        } finally {
          setLoading(false);
        }
      }
    },
  });
  return (
    <MDBCol className='formhomecol' md='3'>
      <form onSubmit={form.handleSubmit}>
        <div className='formdiv' style={{ padding: '0px 0px 10px 0px' }}>
          <h5 className='text-left reg_head'>Register Now</h5>
          <div className='form_div'>
            <label
              htmlFor='defaultFormCardNameEx'
              className=' font-weight-light float-left'
            >
              Full name
            </label>
            <input
              type='text'
              name='username'
              className='form-control'
              value={form.values.username}
              required={true}
              onChange={form.handleChange}
            />
          </div>
          <div className='form_div'>
            <label
              htmlFor='defaultFormCardNameEx'
              className=' font-weight-light float-left'
            >
              Company Name
            </label>
            <input
              type='text'
              name='name'
              className='form-control'
              value={form.values.name}
              required={true}
              onChange={form.handleChange}
            />
          </div>
          <div className='form_div'>
            <label
              htmlFor='defaultFormCardNameEx'
              className=' font-weight-light float-left'
            >
              Email Address
            </label>
            <input
              type='email'
              name='email'
              className='form-control'
              value={form.values.email}
              required={true}
              onChange={form.handleChange}
            />
          </div>
          <div className='form_div'>
            <label
              htmlFor='defaultFormCardNameEx'
              className=' font-weight-light float-left'
            >
              Password
            </label>
            <input
              type='password'
              name='password'
              className='form-control'
              value={form.values.password}
              required={true}
              onChange={form.handleChange}
            />
          </div>
          <div className='text-center black-text' style={{ marginTop: '-6px' }}>
            {loading ? (
              <div className='spinner-border text-danger' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
            ) : (
              <MDBBtn
                rounded
                color='success'
                className='roundcorner register_mainpage'
                type='submit'
                style={{
                  padding: '10px 34px',
                  textTransform: 'none',
                }}
              >
                Register
                <img
                  src='../../img/Group 10.png'
                  alt='group10'
                  style={{ paddingLeft: '10px' }}
                />
              </MDBBtn>
            )}
          </div>
        </div>
      </form>
    </MDBCol>
  );
};

export default RegisterForm;
