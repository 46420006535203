import React, { useContext } from 'react';

import TopBar from './topBar';
import SideBar from './sideBar';
import { SessionContext } from '../../context/sessionContext';

const Navigation = () => {
  const { userData } = useContext(SessionContext);

  return (
    <header>
      <TopBar role={userData?.role} subscription={userData?.subscription} />
      <SideBar role={userData?.role} />
    </header>
  );
};

export default Navigation;
