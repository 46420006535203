import React, { useState, createContext } from 'react';
import { Auth } from 'aws-amplify';

const AccountContext = createContext();

const Account = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [cognitoUser, setCognitoUser] = useState();

  const authenticate = async (Username, Password) => {
    const user = await Auth.signIn(Username, Password);
    setCognitoUser(user);
    console.info(user);
    if (
      !!user.challengeName &&
      user.challengeName === 'NEW_PASSWORD_REQUIRED'
    ) {
      throw new Error('NEW_PASSWORD_REQUIRED');
    } else {
      setToken(user.signInUserSession.idToken.jwtToken);
      localStorage.setItem('token', user.signInUserSession.idToken.jwtToken);
      localStorage.setItem('email', user.attributes.email);
    }
  };

  const getSession = async () => {
    try {
      const session = await Auth.currentSession();
      localStorage.setItem('token', session.accessToken.jwtToken);
    } catch (error) {
      console.log(error);
    }
  };

  const logout = async () => {
    try {
      await Auth.signOut();
      setToken(null);
      localStorage.removeItem('token');
      localStorage.removeItem('email');
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  const newPassword = async (newPassword) => {
    await Auth.completeNewPassword(cognitoUser, newPassword);
  };

  const forgotPassword = async (user) => {
    await Auth.forgotPassword(user);
  };

  const forgotPasswordSubmit = async (user, code, newPassword) => {
    await Auth.forgotPasswordSubmit(user, code, newPassword);
  };

  const changePassword = async (oldPassword, newPassword) => {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(user, oldPassword, newPassword);
  };

  const confirmSignUp = async (username, code) => {
    try {
      await Auth.confirmSignUp(username, code);
    } catch (error) {
      console.log('error confirming sign up', error);
    }
  };

  return (
    <AccountContext.Provider
      value={{
        authenticate,
        changePassword,
        confirmSignUp,
        getSession,
        newPassword,
        forgotPasswordSubmit,
        forgotPassword,
        logout,
        token,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export { AccountContext, Account };
