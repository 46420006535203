import axios from './axios';

export const billData = (id) => axios.get(`/bill/${id}`);

export const createStripe = (id, pmId) => axios.post(`/bill/${id}/${pmId}`);

export const createSubscription = (id, data) =>
  axios.post(`/bill/subscription/${id}`, data);

export const updateStripe = (id, pmId) => axios.patch(`/bill/${id}/${pmId}`);

export const promoCode = (id, promo) =>
  axios.patch(`/bill/coupon/${id}/${promo}`);

export const updateSubscription = (id, data) =>
  axios.patch(`/bill/subscription/${id}`, data);

export const cancelSubscription = (id) =>
  axios.delete(`/bill/subscription/${id}`);
