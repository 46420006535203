import axios from './axios';

export const getPropertyById = (id) => axios.get(`/property/${id}`);

export const getPropertiesByCompany = (id) =>
  axios.get(`/property/${id}/company`);

export const getPropertiesByCompanies = (ids) =>
  axios.get(`/property/companies?ids=${ids}`);

export const createProperty = (data) => axios.post('/property', data);

export const addPropertyDocument = (data, id) =>
  axios.post(`/property/document/${id}`, data);

export const updateProperty = (data, id) =>
  axios.patch(`/property/${id}`, data);

export const deleteProperty = (id) => axios.delete(`/property/${id}`);
