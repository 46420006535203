import axios from './axios';

export const login = (data) => axios.post('/user/login', data);

export const changePassword = (data) =>
  axios.post('/user/changePassword', data);

export const findUser = (id) => axios.get(`/user/${id}`);

export const findWorker = (id) => axios.get(`/user/workers/${id}`);

export const updateUser = (id, data) => axios.patch(`/user/${id}`, data);

export const register = (data) => axios.post('/user', data);

export const invite = (data) => axios.post('/user/invite', data);

export const updateUserExpiration = (data) =>
  axios.post('/user/updateUserExpiration', data);

export const deleteUser = (id) => axios.delete(`/user/${id}`);
