import styled from 'styled-components';
import Button from '@material-ui/core/Button';

const MLRButton = styled(Button)`
  border-radius: 10em !important;
  ${(props) =>
    props.custom === 'success' &&
    'color: #fff !important; background-color: #00c851 !important;'}
`;

export default MLRButton;
