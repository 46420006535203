import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const ChargesTable = ({ charges }) => (
  <TableContainer component={Paper}>
    <Table aria-label='simple table'>
      <TableHead>
        <TableRow>
          <TableCell>Date Create</TableCell>
          <TableCell>Company</TableCell>
          <TableCell>Amount</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {charges?.map((charge, index) => (
          <TableRow key={index}>
            <TableCell>
              {new Date(
                parseInt(charge.created.toString() + '000')
              ).toDateString()}
            </TableCell>
            <TableCell>{charge.description}</TableCell>
            <TableCell>${(charge.amount / 100).toFixed(2)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default ChargesTable;
