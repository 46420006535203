import React, { useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { FormButtonContainer, FormContainer, FormInput } from './styles';
import Button from '../button';

const NotesForm = ({ addNote, onSave, loading, onCancel }) => {
  const [note, setNote] = useState('');
  return (
    <>
      <FormContainer container spacing={3}>
        <FormContainer item xs={12}>
          <FormInput
            name='note'
            variant='outlined'
            label='Note'
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </FormContainer>
      </FormContainer>
      <FormButtonContainer>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <div>
              <Button variant='contained' color='secondary' onClick={onCancel}>
                Cancel
              </Button>
              <Button
                variant='contained'
                custom='success'
                onClick={() => {
                  addNote(note);
                  setNote('');
                }}
                endIcon={<AddCircleOutlineIcon />}
              >
                Add
              </Button>
            </div>
            <Button
              variant='contained'
              type='submit'
              custom='success'
              onClick={onSave}
              endIcon={<ArrowForwardIosIcon />}
            >
              Save and Continue
            </Button>
          </>
        )}
      </FormButtonContainer>
    </>
  );
};
export default NotesForm;
