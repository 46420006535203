import React from 'react';
import styled from 'styled-components';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '../button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';

const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const PropertyCard = ({ property, edit, deleted, details }) => (
  <Card style={{ width: 350, margin: 20, textAlign: 'left' }}>
    <CardMedia
      style={{ height: 250 }}
      image={property.image ?? 'https://myleasereminder.com/no_data.png'}
      title='Property'
    />
    <CardContent>
      <TextContainer>
        <Typography variant='h6'>{property.name}</Typography>
        <div>
          <Tooltip title='Delete'>
            <IconButton
              aria-label='delete'
              size='small'
              onClick={() => deleted(property.srNo)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title='Edit'>
            <IconButton
              aria-label='edit'
              size='small'
              onClick={() => edit(property.srNo)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </div>
      </TextContainer>

      <Typography style={{ marginBottom: 10 }} variant='subtitle2'>
        <LocationOnIcon />
        {property.address1}
      </Typography>
      <TextContainer>
        <p>Current Term Date:</p>
        <b>{moment(property.currentTerminationDate).format('YYYY-MM-DD')}</b>
      </TextContainer>
      <TextContainer>
        <p>Term Avail:</p>
        <b>{property.terms}</b>
      </TextContainer>
      <TextContainer>
        <p>Renewal Notice Time:</p>
        <b>{property.renewalNoticeTime}</b>
      </TextContainer>
      <TextContainer>
        <p>Current Term Renewal Date:</p>
        <b>{moment(property.currentTermRenewalDate).format('YYYY-MM-DD')}</b>
      </TextContainer>
      <TextContainer>
        <p>Full Lease Exp:</p>
        <b>{moment(property.fullLeaseExpiration).format('YYYY-MM-DD')}</b>
      </TextContainer>
    </CardContent>
    <CardActions style={{ justifyContent: 'center' }}>
      <Button
        custom='success'
        size='large'
        onClick={() => details(property.srNo)}
      >
        View Details
      </Button>
    </CardActions>
  </Card>
);

export default PropertyCard;
