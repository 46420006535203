import React, { useEffect, useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useParams } from 'react-router-dom';

import { Container } from '../styles';
import {
  DocumentsTable,
  EquipmentTable,
  NotesTable,
  PersonDescription,
  PropertyDescription,
  SecuritiesTable,
  UtilitiesTable,
} from '../../components';
import { getPropertyById } from '../../api/property';

const PropertiesDetails = () => {
  const [property, setProperty] = useState({});
  const { id } = useParams();

  const getProperty = async () => {
    const myProperty = await getPropertyById(id);
    setProperty(myProperty);
  };

  useEffect(() => {
    getProperty();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <Typography variant='h3'>{property?.name}</Typography>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <Typography variant='h5'>Property</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PropertyDescription property={property ?? {}} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <Typography variant='h5'>Owner</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PersonDescription person={property.owner ?? {}} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <Typography variant='h5'>Landlord</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PersonDescription person={property.landLord ?? {}} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel2a-content'
          id='panel2a-header'
        >
          <Typography variant='h5'>Notes</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <NotesTable notes={property?.notes ?? []} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel2a-content'
          id='panel2a-header'
        >
          <Typography variant='h5'>Utilities</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <UtilitiesTable utilities={property?.utilities ?? []} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel2a-content'
          id='panel2a-header'
        >
          <Typography variant='h5'>Securities</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SecuritiesTable securities={property?.securities ?? []} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel2a-content'
          id='panel2a-header'
        >
          <Typography variant='h5'>Equipments</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <EquipmentTable equipments={property?.equipments ?? []} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel2a-content'
          id='panel2a-header'
        >
          <Typography variant='h5'>Documents</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DocumentsTable documents={property?.documents ?? []} />
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

export default PropertiesDetails;
