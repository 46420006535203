import React, { useContext, useEffect, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import styled from 'styled-components';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from 'mdbreact';

import useWindowSize from '../../utils/useWindowSize';
import { SessionContext } from '../../context/sessionContext';
import { findUser } from '../../api/user';
import { createCompany } from '../../api/company';

const Text = styled.h5`
  color: white;
  margin: 0;
  font-size: 18px;
`;

const Container = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10;
`;

const MySelect = styled(Select)`
  color: #fff !important;
  margin-right: 12px;
  svg {
    color: #fff !important;
  }
`;

const TopBar = ({ role, subscription }) => {
  const [width] = useWindowSize();
  const [menu, setMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);
  const { userId, company, setCompany, logout } = useContext(SessionContext);
  const history = useHistory();
  const form = useFormik({
    initialValues: { name: '' },
    onSubmit: async (values) => {
      setLoading(!loading);
      try {
        await createCompany({ name: values.name, userId });
        await getUser();
        setOpen(!open);
      } catch {
        toast.error('An error ocurred please try again');
      } finally {
        setLoading(!loading);
      }
    },
  });

  const getUser = async () => {
    const me = await findUser(userId);
    setUser(me);
  };

  const handleClose = () => {
    setMenu(!menu);
    setAnchorEl(null);
  };

  const handleOpen = (e) => {
    setMenu(!menu);
    setAnchorEl(e.currentTarget);
  };

  useEffect(() => {
    getUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <MDBModal toggle={() => setOpen(!open)} isOpen={open} centered>
        <MDBModalHeader>Create Company</MDBModalHeader>
        <MDBModalBody style={{ textAlign: 'start' }}>
          <form>
            <label htmlFor='formGroupExampleInput'>Company Name:</label>
            <input
              type='text'
              name='name'
              className='form-control'
              value={form.values.name}
              onChange={form.handleChange}
            />
          </form>
        </MDBModalBody>
        <MDBModalFooter>
          {loading ? (
            <div className='spinner-border text-danger' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          ) : (
            <>
              <MDBBtn color='secondary' onClick={() => setOpen(!open)}>
                Close
              </MDBBtn>
              <MDBBtn color='primary' onClick={form.submitForm}>
                Create
              </MDBBtn>
            </>
          )}
        </MDBModalFooter>
      </MDBModal>
      <AppBar style={{ backgroundColor: '#183650' }} position='static'>
        <Toolbar style={{ justifyContent: 'space-between' }}>
          {width > 775 ? (
            <Text>Welcome Back, {user?.username}</Text>
          ) : (
            <IconButton color='inherit' onClick={handleOpen}>
              <MenuIcon />
              <Menu anchorEl={anchorEl} open={menu} onClose={handleClose}>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    history.push('/properties');
                  }}
                >
                  Properties
                </MenuItem>
                {(role === 'owner' || role === 'admin') && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      history.push('/users');
                    }}
                  >
                    Users
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => {
                    handleClose();
                    history.push('/bill');
                  }}
                >
                  Billing
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    history.push('/settings');
                  }}
                >
                  Settings
                </MenuItem>
              </Menu>
            </IconButton>
          )}
          <div style={{ display: 'flex' }}>
            <MySelect defaultValue={company}>
              {user?.companies?.map((item) => (
                <MenuItem
                  key={item._id}
                  value={item._id}
                  onClick={() => setCompany(item._id)}
                >
                  {item.name}
                </MenuItem>
              ))}
              <MenuItem
                value='all'
                onClick={() => {
                  const ids = user?.companies
                    ?.map((item) => item._id)
                    .join(';');
                  setCompany(ids);
                }}
              >
                All companies
              </MenuItem>
              <Divider />
              {subscription === 'pro' &&
                (role === 'admin' || role === 'owner') && (
                  <MenuItem value='create' onClick={() => setOpen(!open)}>
                    Create Company
                  </MenuItem>
                )}
            </MySelect>
            <Button
              color='inherit'
              onClick={() => {
                logout();
                history.push('/');
              }}
            >
              Logout
            </Button>
          </div>
        </Toolbar>
      </AppBar>
    </Container>
  );
};

export default TopBar;
