import React, { useContext, useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import {
  BillTitleDescription,
  ChangePlanModal,
  ChargesTable,
  CouponCodeForm,
  PlanDescription,
  StripeCard,
} from '../../components';
import { Bar, Container } from '../styles';
import {
  billData,
  updateSubscription,
  createSubscription,
  cancelSubscription,
} from '../../api/bill';
import { updateUserExpiration } from '../../api/user';
import { SessionContext } from '../../context/sessionContext';
import { toast } from 'react-toastify';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

const Bill = () => {
  const { userData, setUserData } = useContext(SessionContext);
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const [bill, setBill] = useState({});
  const [planModal, setPlanModal] = useState(false);
  const [subscription, setSubscription] = useState('');

  const handleChange = (_, newValue) => setTab(newValue);

  const handlePlanModal = () => setPlanModal(!planModal);

  const onSubscription = (type) => {
    setSubscription(type);
    handlePlanModal();
  };

  const onYes = async (type) => {
    setLoading(true);
    try {
      const user = !!userData?.subId
        ? await updateSubscription(
            !!userData?.workFor ? userData.workFor : userData._id,
            { subscription, type }
          )
        : await createSubscription(
            !!userData?.workFor ? userData.workFor : userData._id,
            { subscription, type }
          );
      const data = await updateUserExpiration({
        id: userData._id,
        workFor: !!userData?.workFor ? userData?.workFor : null,
      });
      setUserData({
        ...user,
        subscriptionExpiryDate: data.subscriptionExpiryDate,
      });
      await getBillData();
      toast.success('You are subscribe to My Lease Reminder');
    } catch {
      toast.error('Error please try again');
    } finally {
      handlePlanModal();
      setLoading(false);
    }
  };

  const onCancelSubscription = async () => {
    try {
      const user = await cancelSubscription(userData?._id);
      setUserData({ ...user });
      toast.success('Subscription Canceled');
    } catch {
      toast.error('Error please try again');
    }
  };

  const getBillData = async () => {
    const myBill = await billData(userData?._id);
    setBill(myBill);
  };

  useEffect(() => {
    !!userData?._id && getBillData();
  }, [userData]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <BillTitleDescription bill={bill} isSubscribe={userData?.renewalMode} />
      <Bar position='static'>
        <Tabs
          value={tab}
          onChange={handleChange}
          aria-label='simple tabs example'
        >
          <Tab label='Recent Payments' />
          <Tab label='Credit Card' />
          <Tab label='Upgrade Plan' />
          <Tab label='Coupon' />
        </Tabs>
      </Bar>
      <TabPanel value={tab} index={0}>
        <ChargesTable charges={bill?.charges ?? []} />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <Elements stripe={stripePromise}>
          <StripeCard
            bill={bill}
            userData={userData}
            setUserData={setUserData}
          />
        </Elements>
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <PlanDescription
          subscription={bill?.subscription ?? 'free'}
          onSubscription={onSubscription}
          onChancelSubscription={onCancelSubscription}
          isSubscribe={userData?.renewalMode}
        />
      </TabPanel>
      <TabPanel value={tab} index={3}>
        <CouponCodeForm />
      </TabPanel>
      <ChangePlanModal
        open={planModal}
        plan={subscription}
        handleOpen={handlePlanModal}
        handleYes={onYes}
        loading={loading}
      />
    </Container>
  );
};

export default Bill;
