import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MDBNavItem, MDBNav, MDBNavLink } from 'mdbreact';

const SideBar = ({ role }) => {
  const [hover, setHover] = useState({});
  const location = useLocation();
  return (
    <div className='sidenav'>
      <MDBNav>
        <MDBNavItem>
          <MDBNavLink
            to='/properties'
            className={
              location.pathname.toString().includes('properties') &&
              'background_color_active'
            }
            active={location.pathname.toString().includes('properties')}
            onMouseOver={() => setHover({ properties: true })}
            onMouseOut={() => setHover({})}
          >
            <img src='../../img/dashboard.png' alt='dashboard' />
            {hover.properties && <div className='menu_name'>Properties</div>}
          </MDBNavLink>
        </MDBNavItem>
        <MDBNavItem>
          {(role === 'owner' || role === 'admin') && (
            <MDBNavLink
              to='/users'
              className={
                location.pathname.toString().includes('users') &&
                'background_color_active'
              }
              active={location.pathname.toString().includes('users')}
              onMouseOver={() => setHover({ user: true })}
              onMouseOut={() => setHover({})}
            >
              <img src='../../img/user.png' alt='user' />
              {hover.user && <div className='menu_name'>Users</div>}
            </MDBNavLink>
          )}
        </MDBNavItem>
        <MDBNavItem>
          {(role === 'owner' || role === 'admin') && (
            <MDBNavLink
              to='/bill'
              className={
                location.pathname.toString().includes('bill') &&
                'background_color_active'
              }
              active={location.pathname.toString().includes('bill')}
              onMouseOver={() => setHover({ billing: true })}
              onMouseOut={() => setHover({})}
            >
              <img src='../../img/atm.png' alt='atm' />
              {hover.billing && <div className='menu_name'>Billing</div>}
            </MDBNavLink>
          )}
        </MDBNavItem>

        <MDBNavItem>
          {role === 'super_admin' && (
            <MDBNavLink
              to='/admin_panel'
              className={
                location.pathname.toString().includes('admin_panel') &&
                'background_color_active'
              }
              active={location.pathname.toString().includes('admin_panel')}
              onMouseOver={() => setHover({ admin: true })}
              onMouseOut={() => setHover({})}
            >
              <img src='../../img/dashboard.png' alt='dashboard' />

              {hover.admin && <div className='menu_name'>Admin panel</div>}
            </MDBNavLink>
          )}
        </MDBNavItem>

        <MDBNavItem>
          <MDBNavLink
            to='/setting'
            className={
              location.pathname.toString().includes('setting') &&
              'background_color_active'
            }
            active={location.pathname.toString().includes('setting')}
            onMouseOver={() => setHover({ settings: true })}
            onMouseOut={() => setHover({})}
          >
            <img src='../../img/cog.png' alt='cog' />
            {hover.settings && <div className='menu_name'>Settings</div>}
          </MDBNavLink>
        </MDBNavItem>
      </MDBNav>
    </div>
  );
};

export default SideBar;
