import React from 'react';

import { Container, Description } from './styles';

const PersonDescription = ({ person }) => (
  <Container>
    <Description>
      <b>Owner Name: </b>
      {person?.name}
    </Description>
    <Description>
      <b>Primary email: </b>
      {person?.primaryEmail}
    </Description>
    <Description>
      <b>Other Email: </b>
      {person?.otherEmail}
    </Description>
    <Description>
      <b>Primary Phone: </b>
      {person?.phone}
    </Description>
    <Description>
      <b>Other Phone: </b>
      {person?.otherPhone}
    </Description>
    <Description>
      <b>Address 1: </b>
      {person?.address1}
    </Description>
    <Description>
      <b>Address 2: </b>
      {person?.address2}
    </Description>
    <Description>
      <b>Country: </b>
      {person?.country?.name}
    </Description>
    <Description>
      <b>State: </b>
      {person?.state?.name}
    </Description>
    <Description>
      <b>City: </b>
      {person?.city}
    </Description>
    <Description>
      <b>Zip Code: </b>
      {person?.zipCode}
    </Description>
  </Container>
);

export default PersonDescription;
