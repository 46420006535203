import React from 'react';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBMedia,
} from 'mdbreact';
import ScrollAnimation from 'react-animate-on-scroll';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

const Contenthome = () => {
  return (
    <div className='contenthome_div' md='12'>
      <MDBContainer className='mt-100' style={{ marginBottom: '100px' }}>
        <MDBRow>
          <MDBCol md='6'>
            <h6 className='text-left'>WELCOME TO</h6>
            <h2 className='text-left'>MY LEASE REMINDER</h2>
            <p className='text-left'>
              MLR helps businesses manage their lease location and sends
              upcoming lease renewal, lease expiration and other remainders!
            </p>
          </MDBCol>
          <MDBCol md='6' className='video_divouter'>
            <div className='video_div'>
              <img
                alt=''
                src='../../img/music-and-multimedia.png'
                className='img-fluid'
              />
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <MDBContainer className='mt-70' fluid>
        <MDBRow>
          <MDBCol md='4' style={{ padding: '0px' }}>
            <img
              alt=''
              src='../../img/Mask Group 2.png'
              className='img-fluid img1'
            />
          </MDBCol>
          <MDBCol
            md='8'
            style={{ backgroundColor: '#f6f6f6', padding: '20px' }}
          >
            <MDBRow>
              <MDBCol
                md='12'
                className='text-justify'
                style={{ paddingLeft: '70px' }}
              >
                <span>
                  Our bespoke
                  <br />
                  <h1>APPROACH</h1>
                </span>
              </MDBCol>
              <MDBCol
                md='6'
                className='text-left homecol1'
                style={{ padding: '0px 30px' }}
              >
                <ul>
                  <li className='list-unstyled content_list'>
                    <MDBMedia>
                      <MDBMedia left middle className='mr-3' href='#'>
                        <ScrollAnimation animateIn='fadeInUp'>
                          <MDBMedia
                            object
                            src='../../img/letter.png'
                            alt=''
                            className='home_list_img'
                          />
                        </ScrollAnimation>
                      </MDBMedia>
                      <MDBMedia body>
                        <ScrollAnimation animateIn='fadeInUp'>
                          Keep track of property details and automatically
                          receive important reminders{' '}
                        </ScrollAnimation>
                      </MDBMedia>
                    </MDBMedia>
                  </li>
                  <li className='list-unstyled content_list'>
                    <MDBMedia>
                      <MDBMedia left middle className='mr-3' href='#'>
                        <ScrollAnimation animateIn='fadeInUp'>
                          <MDBMedia
                            object
                            src='../../img/folder.png'
                            alt=''
                            className='home_list_img'
                          />
                        </ScrollAnimation>
                      </MDBMedia>
                      <MDBMedia body>
                        <ScrollAnimation animateIn='fadeInUp'>
                          Keep track of lease agreements and importnat documents
                        </ScrollAnimation>
                      </MDBMedia>
                    </MDBMedia>
                  </li>
                  <li className='list-unstyled content_list'>
                    <MDBMedia>
                      <MDBMedia left middle className='mr-3' href='#'>
                        <ScrollAnimation animateIn='fadeInUp'>
                          <MDBMedia
                            object
                            src='../../img/administration.png'
                            alt=''
                            className='home_list_img'
                          />
                        </ScrollAnimation>
                      </MDBMedia>
                      <MDBMedia body>
                        <ScrollAnimation animateIn='fadeInUp'>
                          Share property information with other within your
                          organization
                        </ScrollAnimation>
                      </MDBMedia>
                    </MDBMedia>
                  </li>
                </ul>
              </MDBCol>
              <MDBCol
                md='6'
                className='text-left homecol2'
                style={{ padding: '0px 30px' }}
              >
                <ul>
                  <li className='list-unstyled content_list'>
                    <MDBMedia>
                      <MDBMedia left middle className='mr-3' href='#'>
                        <ScrollAnimation animateIn='fadeInUp'>
                          <MDBMedia
                            object
                            src='../../img/document.png'
                            alt='Generic placeholder image'
                            className='home_list_img'
                          />
                        </ScrollAnimation>
                      </MDBMedia>
                      <MDBMedia body>
                        <ScrollAnimation animateIn='fadeInUp'>
                          Keep track of equipment and other warranties
                        </ScrollAnimation>
                      </MDBMedia>
                    </MDBMedia>
                  </li>
                  <li className='list-unstyled content_list'>
                    <MDBMedia>
                      <MDBMedia left middle className='mr-3' href='#'>
                        <ScrollAnimation animateIn='fadeInUp'>
                          <MDBMedia
                            object
                            src='../../img/list.png'
                            alt='Generic placeholder image'
                            className='home_list_img'
                          />
                        </ScrollAnimation>
                      </MDBMedia>
                      <MDBMedia body>
                        <ScrollAnimation animateIn='fadeInUp'>
                          Access key contact information for utilities, signage
                          and more
                        </ScrollAnimation>
                      </MDBMedia>
                    </MDBMedia>
                  </li>
                  <li className='list-unstyled content_list'>
                    <MDBMedia>
                      <MDBMedia left middle className='mr-3' href='#'>
                        <ScrollAnimation animateIn='fadeInUp'>
                          <MDBMedia
                            object
                            src='../../img/notebook.png'
                            alt='Generic placeholder image'
                            className='home_list_img'
                          />
                        </ScrollAnimation>
                      </MDBMedia>
                      <MDBMedia body>
                        <ScrollAnimation animateIn='fadeInUp'>
                          Access security information such as safe combinations
                          and security cameras
                        </ScrollAnimation>
                      </MDBMedia>
                    </MDBMedia>
                  </li>
                </ul>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <MDBContainer className='testimonialmain'>
        <div>
          <div>
            <h2 className='text-center'>WHAT OUR CLIENTS SAY</h2>
          </div>
          {/*<div id="multi-item-example" class="carousel testimonial-carousel slide carousel-multi-item mb-5" data-ride="carousel" >
                    <ol class="carousel-indicators" style={{top:"320px"}}>
                        <li data-target="#multi-item-example" data-slide-to="0" class="light-blue darken-4"></li>
                        <li data-target="#multi-item-example" data-slide-to="1" class="active light-blue darken-4"></li>
                        <li data-target="#multi-item-example" data-slide-to="2" class="light-blue darken-4"></li>
                    </ol>
                    <div class="carousel-inner" role="listbox">*/}
          {/*<!--First slide-->*/}
          {/*<div class="carousel-item active">*/}
          {/*<!--Grid column-->*/}
          {/*<div class="">
                                  <div class="testimonial">
                                <div>
                                    <h2 className="text-center" style={{padding:" 40px 0px"}}><img alt="" src="../../img/quote.png"/> </h2>
                                </div>
                                <div>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                                    , when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                </div>
                                <div>
                                    <h5 className="backgroundline" style={{margin:" 20px 0px"}}>
                                        <span><MDBIcon icon="quote-left" /></span>
                                    </h5>
                                </div>
                                <div>
                                    <h6>
                                    JOHN DEO
                                    </h6>
                                    <p>Founder. Google Inc.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>*/}
          <Carousel>
            <div>
              <div>
                <h2 className='text-center' style={{ padding: ' 40px 0px' }}>
                  <img
                    alt=''
                    src='../../img/quote.png'
                    style={{ width: '60px' }}
                  />{' '}
                </h2>
              </div>
              <div>
                {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                        , when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p> */}
              </div>
              <div>
                <h5 className='backgroundline' style={{ margin: ' 20px 0px' }}>
                  <span>
                    <MDBIcon icon='quote-left' />
                  </span>
                </h5>
              </div>
              <div>
                <h6>JOHN DEO</h6>
                <p>Founder. Google Inc.</p>
              </div>
            </div>
            <div>
              <div>
                <h2 className='text-center' style={{ padding: ' 40px 0px' }}>
                  <img
                    alt=''
                    src='../../img/quote.png'
                    style={{ width: '60px' }}
                  />{' '}
                </h2>
              </div>
              <div>
                {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                        , when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p> */}
              </div>
              <div>
                <h5 className='backgroundline' style={{ margin: ' 20px 0px' }}>
                  <span>
                    <MDBIcon icon='quote-left' />
                  </span>
                </h5>
              </div>
              <div>
                <h6>JOHN DEO</h6>
                <p>Founder. Google Inc.</p>
              </div>
            </div>
            <div>
              <img alt='' src='assets/3.jpeg' style={{ display: 'none' }} />
              <div>
                <h2 className='text-center' style={{ padding: ' 40px 0px' }}>
                  <img
                    alt=''
                    src='../../img/quote.png'
                    style={{ width: '60px' }}
                  />{' '}
                </h2>
              </div>
              <div>
                {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                        , when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p> */}
              </div>
              <div>
                <h5 className='backgroundline' style={{ margin: ' 20px 0px' }}>
                  <span>
                    <MDBIcon icon='quote-left' />
                  </span>
                </h5>
              </div>
              <div>
                <h6>JOHN DEO</h6>
                <p>Founder. Google Inc.</p>
              </div>
            </div>
          </Carousel>
        </div>
      </MDBContainer>
      <MDBContainer className='register_now'>
        <h2 className='text-center'>GIVE IT A TRY</h2>
        <MDBBtn
          rounded
          color='success'
          className='roundcorner text-center'
          type='submit'
        >
          Register Now
          <img
            alt=''
            src='../../img/Group 10.png'
            style={{ paddingLeft: '10px' }}
          />
        </MDBBtn>
      </MDBContainer>
    </div>
  );
};

export default Contenthome;
