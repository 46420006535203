import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from '../button';
import { CircularProgress } from '@material-ui/core';

const ChangePlanModal = ({ open, plan, handleOpen, handleYes, loading }) => (
  <Dialog
    open={open}
    onClose={handleOpen}
    aria-labelledby='alert-dialog-title'
    aria-describedby='alert-dialog-description'
  >
    <DialogTitle id='alert-dialog-title'>
      {plan.charAt(0).toUpperCase() + plan.slice(1)} Plan
    </DialogTitle>
    <DialogContent>
      <DialogContentText id='alert-dialog-description'>
        Please choose your payment type Monthly or Annual. <br />
        Annual {plan === 'basic' ? '$99.00' : '$289.00'} save 20%. <br />
        Monthly {plan === 'basic' ? '$9.99' : '$29.99'}.
      </DialogContentText>
    </DialogContent>
    <DialogActions style={{ justifyContent: 'center' }}>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Button onClick={() => handleYes('annual')} custom='success'>
            Annual
          </Button>
          <Button onClick={() => handleYes('monthly')} custom='success'>
            Monthly
          </Button>
          <Button onClick={handleOpen} variant='contained' color='secondary'>
            Cancel
          </Button>
        </>
      )}
    </DialogActions>
  </Dialog>
);

export default ChangePlanModal;
