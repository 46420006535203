import React from 'react';
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from 'mdbreact';

const FooterPage = () => {
  return (
    <MDBFooter
      color='brown darken-1'
      className='font-small pt-4  paddingresponsive fixed-bottom footermlr'
      fixed='bottom'
    >
      <MDBContainer
        fluid
        className='text-center text-md-left'
        style={{ paddingTop: '50px' }}
      >
        <MDBRow>
          <MDBCol md='5' className='title_div'>
            <h5 className='title'>
              <img
                alt=''
                src='../../img/Logo.png'
                className='img-fluid'
                style={{ height: '100%' }}
              />
            </h5>
          </MDBCol>
          <MDBCol md='3' className='footlink'>
            <ul className='listaligin footer_list'>
              <li className='list-unstyled' style={{ padding: '10px' }}>
                <a href='/'>HOME</a>
              </li>
              <li className='list-unstyled' style={{ padding: '10px' }}>
                <a href='/aboutus'>ABOUT US</a>
              </li>
              <li className='list-unstyled' style={{ padding: '10px' }}>
                <a href='/pricing'>PRICING</a>
              </li>
              <li className='list-unstyled' style={{ padding: '10px' }}>
                <a href='/contactus'>CONTACT US</a>
              </li>
              <li className='list-unstyled' style={{ padding: '10px' }}>
                <a href='/'>REGISTER</a>
              </li>
              <li className='list-unstyled' style={{ padding: '10px' }}>
                <a href='/'>LOGIN</a>
              </li>
            </ul>
          </MDBCol>
          <MDBCol md='4' className='footcontc'>
            <h5 className='title resfootertitle' style={{ marginLeft: '38px' }}>
              GET IN TOUCH
            </h5>
            <ul className='list-group foot_list'>
              <li
                className='list-unstyled'
                style={{ margin: '10px', display: 'flex' }}
              >
                <div md='1'>
                  <img
                    alt=''
                    src='../../img/pin.png'
                    style={{ paddingRight: '10px' }}
                  />
                </div>
                <div md='11' style={{ fontSize: '12px' }}>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </div>
              </li>
              <li
                className='list-unstyled'
                style={{ margin: '10px', display: 'flex' }}
              >
                <div md='1'>
                  <img
                    alt=''
                    src='../../img/mailw.png'
                    style={{ paddingRight: '10px' }}
                  />
                </div>
                <div md='11' style={{ fontSize: '12px' }}>
                  info@myleaseremainder.com
                </div>
              </li>
            </ul>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <div className='footer-copyright py-3 footercopy'>
        <hr className='my-2 hr_tag' />
        <MDBContainer
          className='text-left icongroupres'
          fluid
          style={{ color: 'white', paddingBottom: '20px', fontSize: '12px' }}
        >
          Copyright &copy; 2012-2020 myleaseremainder(.mlr).All Right Reserved
        </MDBContainer>
        <MDBContainer
          className='text-right icongroupres'
          style={{
            marginTop: '-40px',
            paddingBottom: '20px',
            cursor: 'pointer',
          }}
        >
          <img
            alt=''
            src='../../img/social-facebook-circular-button1.png'
            style={{ height: '30px', marginRight: '10px' }}
          />
          <img
            alt=''
            src='../../img/twitter-circular-button1.png'
            style={{ height: '30px', marginRight: '10px' }}
          />
          <img
            alt=''
            src='../../img/social-linkedin-circular-button1.png'
            style={{ height: '30px', marginRight: '10px' }}
          />
        </MDBContainer>
        <div></div>
      </div>
    </MDBFooter>
  );
};

export default FooterPage;
