import React, { useContext, useState } from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

import { AccountContext } from '../../context/account';

const ForgotPassword = () => {
  const history = useHistory();
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const { forgotPassword, forgotPasswordSubmit } = useContext(AccountContext);
  const form = useFormik({
    initialValues: {
      email: '',
      code: '',
      password: '',
    },
    onSubmit: async (values) => {
      setLoading(true);
      if (step === 1) {
        try {
          await forgotPassword(values.email);
          toast.success('Please check your email for the verification code');
          setStep(2);
        } catch {
          toast.error('An error occurred');
        } finally {
          setLoading(false);
        }
      }
      if (step === 2) {
        try {
          await forgotPasswordSubmit(
            values.email,
            values.code,
            values.password
          );
          toast.success('Password changed successfully');
          history.push('/');
        } catch {
          toast.error('An error occurred');
        } finally {
          setLoading(false);
        }
      }
    },
  });

  return (
    <div style={{ marginTop: 100 }}>
      {step === 1 && (
        <form
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          onSubmit={form.handleSubmit}
        >
          <Typography variant='h4' gutterBottom>
            Please enter your email
          </Typography>
          <TextField
            style={{ marginBottom: '1em' }}
            name='email'
            label='Email'
            variant='outlined'
            value={form.values.email}
            onChange={form.handleChange}
          />
          <Button type='submit' variant='contained' color='primary'>
            Get Code
          </Button>
        </form>
      )}
      {step === 2 && (
        <form
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          onSubmit={form.handleSubmit}
        >
          <Typography variant='h4' gutterBottom>
            New Password
          </Typography>
          <TextField
            style={{ marginBottom: '1em' }}
            name='code'
            label='Code'
            variant='outlined'
            value={form.values.code}
            onChange={form.handleChange}
          />
          <TextField
            style={{ marginBottom: '1em' }}
            type='password'
            name='password'
            label='New Password'
            variant='outlined'
            value={form.values.password}
            onChange={form.handleChange}
          />
          <Button type='submit' variant='contained' color='primary'>
            Reset Password
          </Button>
        </form>
      )}
      <Backdrop open={loading}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </div>
  );
};

export default ForgotPassword;
