import React, { useState } from 'react';
import { CircularProgress, Typography } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import Button from '../button';
import {
  FormButtonContainer,
  FormContainer,
  FormInput,
  ImageInput,
} from './styles';

const DocumentForm = ({ loading, addDocument, onSave }) => {
  const [file, setFile] = useState({
    filename: 'No File',
    documentBase64: '',
    description: '',
  });
  const readFile = (e) => {
    const files = e.target.files;

    if (files && files[0]) {
      const FR = new FileReader();

      FR.addEventListener('load', function (e) {
        setFile({
          ...file,
          filename: files[0].name,
          documentBase64: e.target.result,
        });
      });

      FR.readAsDataURL(files[0]);
    }
  };
  return (
    <>
      <FormContainer container spacing={3}>
        <FormContainer item xs={12} md={6}>
          <ImageInput>
            <input
              id='raised-button-file'
              type='file'
              hidden
              onChange={readFile}
            />
            <label htmlFor='raised-button-file'>
              <Button variant='contained' component='span'>
                Upload Document
              </Button>
            </label>
            <br />
            <Typography>{file?.filename}</Typography>
          </ImageInput>
        </FormContainer>
        <FormContainer item xs={12} md={6}>
          <FormInput
            name='description'
            variant='outlined'
            label='description'
            multiline
            rowsMax={8}
            rows={8}
            value={file.description}
            onChange={(e) => setFile({ ...file, description: e.target.value })}
          />
        </FormContainer>
      </FormContainer>
      <FormButtonContainer>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <div>
              <Button variant='contained' color='secondary'>
                Cancel
              </Button>
              <Button
                variant='contained'
                custom='success'
                onClick={() => {
                  addDocument(file);
                  setFile({
                    name: 'No File',
                    base64: '',
                    description: '',
                  });
                }}
                endIcon={<AddCircleOutlineIcon />}
              >
                Add
              </Button>
            </div>
            <Button
              variant='contained'
              type='submit'
              custom='success'
              onClick={onSave}
              endIcon={<ArrowForwardIosIcon />}
            >
              Save
            </Button>
          </>
        )}
      </FormButtonContainer>
    </>
  );
};

export default DocumentForm;
