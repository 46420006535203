import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const EquipmentTable = ({ equipments }) => (
  <TableContainer component={Paper}>
    <Table aria-label='simple table'>
      <TableHead>
        <TableRow>
          <TableCell>#</TableCell>
          <TableCell>Item Description</TableCell>
          <TableCell>Purchase Date</TableCell>
          <TableCell>Purchase From</TableCell>
          <TableCell>Model #</TableCell>
          <TableCell>Serial #</TableCell>
          <TableCell>Invoice/Receipt#</TableCell>
          <TableCell>Equipment Exp. Date</TableCell>
          <TableCell>Note</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {equipments?.map((equipment, index) => (
          <TableRow key={index}>
            <TableCell>{index + 1}</TableCell>
            <TableCell>{equipment.item}</TableCell>
            <TableCell>{equipment.purchaseDate}</TableCell>
            <TableCell>{equipment.from}</TableCell>
            <TableCell>{equipment.model}</TableCell>
            <TableCell>{equipment.serial}</TableCell>
            <TableCell>{equipment.invoice}</TableCell>
            <TableCell>{equipment.expirationDate}</TableCell>
            <TableCell>{equipment.note}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default EquipmentTable;
