import React, { Component } from 'react';
import { withRouter } from 'react-router';
import axios from 'axios';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBNavItem,
  MDBNavbarNav,
  MDBNavLink,
  MDBNavbar,
  MDBCollapse,
  MDBNavbarToggler,
} from 'mdbreact';
import { POSTURL } from '../Navigation/Constant';
import { LoginForm, RegisterForm, PlanDescription } from '../components';

class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      modal8: false,
      modal3: false,
      username: '',
      email: '',
      password: '',
      email1: '',
      password1: '',
      inital_data: true,
      onForgot: false,
      inital_stage: true,
      showButton: true,
      ShowSubmit: false,
      second_data: false,
      register_now: false,
      confirmpassword: '',
      newpassword: '',
      reset_pas: false,
      login_data: false,
      onReg: false,
      accessctrl: '',
      activated: '',
      superadmin_id: '',
      type: '',
      invalid_pass: '',
      modal4: false,
      position: '',
      message: '',
      show_login_page: false,
      verified_email_status: false,
      res_pas_data: '',
      message1: '',
      modal1: false,
    };
  }

  toggle = (nr) => () => {
    let modalNumber = 'modal' + nr;
    this.setState({
      [modalNumber]: !this.state[modalNumber],
    });
  };

  componentDidMount() {
    let link = window.location.href.split('?');

    if (link.length === 3) {
      this.setState({
        reset_pas: true,
        login_data: false,
      });
      if (link[1] === 'type=forgetPassword') {
        this.setState({
          reset_pas: true,
          login_data: false,
          register_now: false,
        });
      }

      if (link[1] === 'SID=') {
        let invite_id = {
          invite_id: link[2],
        };
        this.setState({
          reset_pas: false,
          login_data: false,
        });
        //console.loge.log("inside model1_invites");

        axios
          .post(POSTURL + 'fetch_single_invites', invite_id)
          .then((res) => {
            //console.loge.log("fetch_single_invites success ",res);
            let css = res.data.data;
            if (css.alreadyRegistered === false) {
              this.setState({
                username: css.username,
                email: css.email,
              });
            } else {
              //console.loge.log("fjhdjh");
              this.setState({
                modal1: true,
              });

              //console.loge.log("this.state.model1",this.state.model1);
            }

            this.setState({
              accessctrl: css.accessctrl,
              activated: css.activated,
              superadmin_id: css.superadmin_id,
            });

            // history.push("/dashboard")
          }) // re-direct to dashboard after successful profile update
          .catch(
            (err) => console.loge.log('error: ', err)
            // dispatch({
            //   type: GET_ERRORS,
            //   payload: err.response
            // })
          );
      }
    }

    if ((link.length = 3)) {
      if (link[1] === 'VerifyToken=') {
        //console.loge.log("inside verfiyyy");
        let invite_id = {
          invite_id: link[2],
        };

        this.setState({
          reset_pas: false,
          login_data: false,
        });
        //console.loge.log("imodal1nside emailVerification");

        axios.post(POSTURL + 'emailVerification', invite_id).then((res) => {
          //console.loge.log("emailVerification success ",res);
          let css = res.data.status;
          let data = res.data.message;
          if (css === 1) {
            // alert("email verification done successfully");

            setTimeout(
              function () {
                this.setState({
                  message:
                    'Email verification successful, please log in to continue.',
                });
              }.bind(this),
              1000
            );
          } else {
            setTimeout(
              function () {
                this.setState({
                  message: data,
                });
              }.bind(this),
              1000
            );
          }
          this.setState({
            verified_email_status: true,
            login_data: true,
            inital_data: true,
            register_now: false,
          });
        });
      }
    }
  }
  register_now11 = (event) => {
    window.location.href = '/';
  };

  onChangeHandler = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  toggleCollapse = () => {
    this.setState({ isOpen1: !this.state.isOpen1 });
  };

  onForgot = (event, data) => {
    event.preventDefault();
    this.setState({
      inital_data: false,
      onSubmitHandler: false,
      inital_stage: false,
      showButton: false,
      ShowSubmit: true,
      second_data: true,
      login_data: false,
      res_pas_data: '',
      message: '',
      invalid_pass: '',
    });
  };

  Submit = (event, data) => {
    this.setState({
      res_pas_data: '',
    });
    event.preventDefault();
    const forgot_User = {
      email: this.state.email1,
    };
    //console.loge.log("forgot_User",forgot_User);
    axios
      .post(POSTURL + 'forget_password', forgot_User)
      .then((res) => {
        //console.loge.log("forgot password success ",res);
        if (res.data.status === 1) {
          setTimeout(
            function () {
              this.setState({
                res_pas_data: 'Email sent successfully',
              });
            }.bind(this),
            1000
          );

          let link = window.location.href.split('?');
          //console.loge.log("linking",link);
          // alert("Mail sent successfully!!")
          if (link.length === 3) {
            this.setState({
              reset_pas: true,
              second_data: false,
              login_data: false,
            });
          }
        } else {
          // alert("email id not found");
          this.setState({
            res_pas_data: "Email doesn't exist",
          });
          setTimeout(
            function () {
              this.setState({
                res_pas_data: ' ',
              });
            }.bind(this),
            3000
          );

          this.setState({
            login_data: false,
          });
        }
      })
      .catch((err) => console.loge.log('error: ', err));
    this.setState({
      email1: '',
    });
  };

  SaveHandler = (event) => {
    event.preventDefault();
    let link = window.location.href.split('=');
    //console.loge.log("linking",link);
    const reset_User_pass = {
      newpassword: this.state.newpassword,
      confirmpassword: this.state.confirmpassword,
      id: link[2],
    };
    //console.loge.log("reset_User_pass", reset_User_pass);

    if (this.state.newpassword === this.state.confirmpassword) {
      // alert("your password set successfully!");
      axios
        .post(POSTURL + 'reset_password_home', reset_User_pass)
        .then((res) => {
          //console.loge.log("reset_password_home success ",res);
          if (res.data.status) {
            setInterval(
              function () {
                this.setState({
                  message: 'Password reset successful!',
                  show_login_page: true,
                  login_data: true,
                  register_now: false,
                });
              }.bind(this),
              2000
            );
          }
        })
        .catch((err) => console.loge.log('error: ', err));
      this.setState({
        newpassword: '',
        confirmpassword: '',
        login_data: true,
        reset_pas: false,
      });
    } else {
      alert('new Password and confirm Password are not matched');
    }
  };

  loginClick = (event) => {
    event.preventDefault();
    this.setState({
      login_data: true,
      inital_data: true,
      register_now: false,
      inital_stage: true,
      showButton: true,
      reset_pas: false,
    });
  };

  onReg = (event) => {
    event.preventDefault();
    this.setState({
      register_now: true,
      login_data: false,
      // inital_data:true,
      second_data: false,
    });
  };

  onReg1 = (event) => {
    event.preventDefault();
    this.setState({
      register_now: true,
      login_data: false,
      second_data: false,
      res_pas_data: '',
    });
  };
  onlogg = (event) => {
    event.preventDefault();
    this.setState({
      register_now: false,
      login_data: true,
      second_data: false,
      inital_data: true,
      showButton: true,
      inital_stage: true,
    });
  };

  accept_invitation = (event) => {
    //console.loge.log("accept_invitation");
    let link = window.location.href.split('?');
    // };
    //console.loge.log("linking",link);

    if (link.length === 3) {
      if (link[1] === 'SID=') {
        let invite_id = {
          invite_id: link[2],
        };
        axios
          .post(POSTURL + 'addCompanyToProfile', invite_id)
          .then((res) => {
            //console.loge.log("addCompanyToProfile success ",res);
            let css = res.data;
            if (css.status === 1) {
              this.setState({
                modal1: false,
              });
            }
          })
          .catch((err) => console.loge.log('error: ', err));
      }
    }
  };

  toggleCollapse = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    return (
      <div className='animation1'>
        <div>
          <div className='homebg' style={{ height: '700px' }}>
            <MDBContainer className='d-flex uppernav' fluid>
              <MDBContainer className='d-flex uppernav_inner'>
                <MDBCol md='6'>
                  <MDBContainer className='text-left icongroupres'>
                    <img
                      alt=''
                      src='../../img/social-facebook-circular-button1.png'
                      style={{ height: '20px', marginRight: '10px' }}
                    />
                    <img
                      alt=''
                      src='../../img/twitter-circular-button1.png'
                      style={{ height: '20px', marginRight: '10px' }}
                    />
                    <img
                      alt=''
                      src='../../img/social-linkedin-circular-button1.png'
                      style={{ height: '20px', marginRight: '10px' }}
                    />
                  </MDBContainer>
                </MDBCol>
                <MDBCol md='6' className='d-flex call_mail'>
                  <div>
                    <MDBContainer>
                      <a href='mailto:info@myleaseremainder.com'>
                        <img
                          alt=''
                          src='../../img/mail.png'
                          style={{ paddingRight: '10px' }}
                        />
                        <span style={{ fontSize: '10px', color: '#fff' }}>
                          info@myleaseremainder.com
                        </span>
                      </a>
                    </MDBContainer>
                  </div>
                  {/* <div>
                         <MDBContainer>
                             <img alt="" src="../../img/call.png" style={{paddingRight:"10px"}} /><span style={{fontSize:"10px"}}>+1989 989 8989</span>
                         </MDBContainer>
                     </div> */}
                </MDBCol>
              </MDBContainer>
            </MDBContainer>
            <MDBContainer>
              <MDBContainer className='second_navcontainer'>
                <h5 className='float-left'>
                  <a href='https://www.myleasereminder.com'>
                    <img
                      alt=''
                      src='../../img/Logo.png'
                      className='img-fluid logo_mainnav'
                      style={{ height: '50px' }}
                    />
                  </a>
                </h5>

                <MDBNavbar
                  dark
                  expand='md'
                  className='justify-content-end second_nav'
                >
                  <MDBNavbarToggler onClick={this.toggleCollapse} />
                  <MDBCollapse
                    id='navbarCollapse3'
                    isOpen={this.state.isOpen}
                    navbar
                  >
                    <MDBNavbarNav className='hoverovernavmenu'>
                      <MDBNavItem style={{ padding: '0px 0px' }}>
                        <MDBNavLink to='/'>HOME</MDBNavLink>
                      </MDBNavItem>
                      <MDBNavItem style={{ padding: '0px 0px' }}>
                        <MDBNavLink to='/aboutus'>ABOUT US</MDBNavLink>
                      </MDBNavItem>
                      <MDBNavItem style={{ padding: '0px 0px' }}>
                        <MDBNavLink className='activehomenavitem' to='/pricing'>
                          PRICING
                        </MDBNavLink>
                      </MDBNavItem>
                      <MDBNavItem style={{ padding: '0px 0px' }}>
                        <MDBNavLink to='/contactus'>CONTACT US</MDBNavLink>
                      </MDBNavItem>
                      <MDBNavItem></MDBNavItem>
                    </MDBNavbarNav>
                  </MDBCollapse>
                  <div
                    style={{
                      borderLeft: '1px solid white',
                      paddingLeft: '30px',
                      marginTop: ' -13px',
                    }}
                  >
                    <a href='#!'>
                      <img
                        alt=''
                        src='../../img/login.png'
                        className='img-fluid'
                        onClick={this.loginClick}
                        style={{ width: ' 25px', height: ' 25px' }}
                      />
                    </a>
                    <span className='login_text' onClick={this.loginClick}>
                      Login
                    </span>
                  </div>
                </MDBNavbar>
              </MDBContainer>
              <MDBContainer className='float-left'>
                <MDBRow>
                  <MDBCol md='8' />
                  {this.state.register_now === true && <RegisterForm />}
                  {this.state.login_data === true && (
                    <LoginForm onReg={this.onReg} />
                  )}

                  {this.state.second_data === true && (
                    <MDBCol md='3'>
                      <div
                        className='formdiv11 logindivresponsive'
                        style={{
                          backgroundColor: 'rgba(23,67,88,0.5)',
                          marginBottom: '30px',
                          padding: '2px 10px 60px 10px',
                          height: '576px',
                        }}
                      >
                        <div
                          style={{
                            color: 'white',
                            height: '58px',
                            textAlign: 'center',
                          }}
                        >
                          {this.state.res_pas_data}
                        </div>
                        <div>
                          <h5
                            className='text-left login_head'
                            style={{ paddingTop: '40px' }}
                          >
                            Forgot Password
                          </h5>
                          <div className='form_div'>
                            <label
                              htmlFor='defaultFormCardNameEx'
                              className=' font-weight-light float-left'
                              style={{ fontWeight: '400' }}
                            >
                              Email Address
                            </label>
                            <input
                              type='email'
                              id='email1'
                              name={this.state.email1}
                              className='form-control'
                              required={true}
                              value={this.state.email1}
                              onChange={this.onChangeHandler}
                            />
                          </div>

                          <div className='form_div' style={{ height: '20px' }}>
                            <p
                              onClick={(event) => this.onReg1(event)}
                              style={{ cursor: 'pointer' }}
                            >
                              {' '}
                              Register Now ?{' '}
                            </p>
                          </div>
                          <div className='form_div' style={{ height: '20px' }}>
                            <p
                              onClick={(event) => this.onlogg(event)}
                              style={{ cursor: 'pointer' }}
                            >
                              {' '}
                              Login Now ?{' '}
                            </p>
                          </div>
                          <MDBBtn
                            rounded
                            color='success'
                            className='roundcorner register_mainpage'
                            type='submit'
                            onClick={this.Submit}
                            style={{
                              padding: '10px 32px',
                              textTransform: 'none',
                            }}
                          >
                            Submit
                            <img
                              alt=''
                              src='../../img/Group 10.png'
                              style={{ paddingLeft: '10px' }}
                            />
                          </MDBBtn>
                        </div>
                      </div>
                    </MDBCol>
                  )}

                  {this.state.reset_pas === true && (
                    <MDBCol md='3'>
                      <div>
                        <span style={{ color: 'white', textAlign: 'center' }}>
                          {this.state.res_pas_data}
                        </span>

                        <div
                          className='formdiv1'
                          style={{ backgroundColor: 'rgba(23,67,88,0.5)' }}
                        >
                          <h4 className='text-left login_head'>
                            Reset Password
                          </h4>

                          <div className='form_div'>
                            <label
                              htmlFor='defaultFormCardNameEx'
                              className=' font-weight-light float-left'
                            >
                              New Password
                            </label>
                            <input
                              type='password'
                              id='newpassword'
                              name={this.state.newpassword}
                              className='form-control'
                              required={true}
                              value={this.state.newpassword}
                              onChange={this.onChangeHandler}
                              style={{ borderRadius: '0px' }}
                            />
                          </div>

                          <div className='form_div'>
                            <label
                              htmlFor='defaultFormCardNameEx'
                              className=' font-weight-light float-left'
                            >
                              Confirm Password
                            </label>
                            <input
                              type='password'
                              id='confirmpassword'
                              name={this.state.confirmpassword}
                              className='form-control'
                              required={true}
                              value={this.state.confirmpassword}
                              onChange={this.onChangeHandler}
                              style={{ borderRadius: '0px' }}
                            />
                          </div>
                          <div>
                            <MDBBtn
                              rounded
                              color='success'
                              className='roundcorner register_mainpage'
                              style={{
                                padding: '12px 45px',
                                textTransform: 'none',
                              }}
                              type='submit'
                              onClick={this.SaveHandler}
                            >
                              Save
                              <img
                                alt=''
                                src='../../img/Group 10.png'
                                style={{ paddingLeft: '10px' }}
                              />
                            </MDBBtn>
                          </div>
                        </div>
                      </div>
                    </MDBCol>
                  )}
                </MDBRow>
              </MDBContainer>
            </MDBContainer>
          </div>
        </div>

        <MDBContainer className='mt-70 contactus_div'>
          <MDBRow>
            <MDBCol style={{ marginBottom: '25px' }}>
              <h2 style={{ fontWeight: '400', color: ' #183650' }}>Pricing</h2>
            </MDBCol>
          </MDBRow>
          <PlanDescription showFree onSubscription={() => console.log()} />
        </MDBContainer>
        <div className='blankdiv'></div>
      </div>
    );
  }
}

export default withRouter(Pricing);
