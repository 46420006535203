import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Typography } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';

import { Container } from '../styles';
import {
  CreateEditEquipmentForm,
  EquipmentTable,
  PropertyStepper,
} from '../../components';
import { updateProperty, getPropertyById } from '../../api/property';

const EquipmentCreateEdit = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [equipments, setEquipments] = useState([]);
  const { id } = useParams();

  const addEquipment = (equipment) => setEquipments([...equipments, equipment]);

  const onSave = async () => {
    setLoading(true);
    try {
      await updateProperty({ equipments }, id);
      history.push(`/document/${id}`);
    } catch {
      toast.error('An error ocurred');
    } finally {
      setLoading(false);
    }
  };

  const getProperty = async () => {
    const property = await getPropertyById(id);
    if (property.equipments.length > 0) {
      setEquipments(property.equipments);
    }
  };

  useEffect(() => {
    getProperty();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <Typography variant='h3'>Equipment</Typography>
      <PropertyStepper step={6} propertyId={id} />
      <CreateEditEquipmentForm
        addEquipment={addEquipment}
        onSave={onSave}
        loading={loading}
      />
      <EquipmentTable equipments={equipments} />
    </Container>
  );
};

export default EquipmentCreateEdit;
