import axios from 'axios';

const base = {
  baseURL: process.env.REACT_APP_API_REST_V2,
  headers: { 'Content-Type': 'application/json' },
};

const axiosInstance = axios.create(base);

const responseError = async (error) => {
  return Promise.reject(error);
};

const responseOk = ({ data, config }) => {
  if (
    data.hasOwnProperty('status') &&
    !data.status &&
    !config.expectFalseStatus
  ) {
    throw new Error(data.message);
  }
  return data;
};

axiosInstance.interceptors.response.use(responseOk, responseError);
axiosInstance.interceptors.request.use(
  async (config) => {
    return {
      ...config,
      headers: {
        ...config.headers,
      },
    };
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;
