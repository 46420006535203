import React, { useContext, useEffect, useState } from 'react';
import CsvDownloader from 'react-csv-downloader';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { Container } from '../styles';
import { PropertyCard, RenewalExpirationDescription } from '../../components';
import { deleteProperty, getPropertiesByCompanies } from '../../api/property';
import { SessionContext } from '../../context/sessionContext';

const ButtonContainer = styled.div`
  display: flex;
  margin: 16px 0;
  justify-content: flex-end;
  @media only screen and (max-width: 750px) {
    justify-content: space-between;
  }
`;

const Search = styled.div`
  position: absolute;
  @media only screen and (max-width: 750px) {
    position: inherit;
    margin-top: 16px;
  }
`;

const Properties = () => {
  const history = useHistory();
  const [properties, setProperties] = useState([]);
  const { company } = useContext(SessionContext);

  const getProperties = async () => {
    const properties = await getPropertiesByCompanies(
      company.replaceAll(';', '%3B')
    );
    setProperties(properties);
  };

  const onEdit = (srNo) => history.push(`/property/${srNo}`);

  const onDelete = async (srNo) => {
    const deleted = await deleteProperty(srNo);
    setProperties(
      properties.filter((property) => property.srNo !== deleted.srNo)
    );
  };

  const download = () =>
    properties.map((item) => ({
      'Store #': item.no,
      Address: `${item.address1} ${item.address2 && `, ${item.address2}`} ${
        item.city
      }, ${item.state?.isoCode} ${item.zipCode}`,
      Owner: item.owner?.name,
      'Owner Phone/Email': `${item.owner?.phone}/${item.owner?.primaryEmail}`,
      'Landlord Phone/Email': `${item.landLord?.phone}/${item.landLord?.primaryEmail}`,
    }));

  const onDetails = (srNo) => history.push(`/propertyDetails/${srNo}`);

  useEffect(() => {
    getProperties();
  }, [company]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <Typography variant='h3'>Properties</Typography>
      <Search>
        <TextField
          id='standard-adornment-password'
          margin='dense'
          variant='outlined'
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Search>
      <ButtonContainer>
        <CsvDownloader filename='properties' datas={download}>
          <Button
            size='small'
            variant='contained'
            color='primary'
            style={{ backgroundColor: '#00c851', marginRight: 12 }}
          >
            Download Reports
          </Button>
        </CsvDownloader>
        <Button
          size='small'
          variant='contained'
          color='primary'
          style={{ backgroundColor: '#00c851' }}
          onClick={() => history.push('/property/new')}
        >
          Add New Property
        </Button>
      </ButtonContainer>
      <RenewalExpirationDescription />
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {properties?.map((property) => (
          <PropertyCard
            key={property._id}
            edit={onEdit}
            deleted={onDelete}
            details={onDetails}
            property={property}
          />
        ))}
      </div>
    </Container>
  );
};

export default Properties;
