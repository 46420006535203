import React, { useEffect, useState } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import { states } from '../../api/utils';

const StatePicker = ({ value = '', setValue, country, required }) => {
  const [myStates, setStates] = useState(null);

  useEffect(() => {
    getStates(country);
  }, [country]);

  const getStates = async (country) => {
    const allStates = await states(country);
    setStates(allStates);
  };

  const change = (e) => {
    const selectState = myStates.filter((i) => i.isoCode === e.target.value)[0];
    setValue('state', selectState);
  };

  return (
    <FormControl fullWidth variant='outlined'>
      <InputLabel>State</InputLabel>
      <Select
        labelId='state'
        required={required}
        name='state'
        value={value}
        onChange={change}
      >
        {myStates?.map((option) => (
          <MenuItem key={option.isoCode} value={option.isoCode}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default StatePicker;
