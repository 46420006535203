import React from 'react';
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
} from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import {
  FormButtonContainer,
  FormContainer,
  FormInput,
  ImageInput,
} from './styles';
import CountryPicker from '../pickers/country';
import StatePicker from '../pickers/states';
import Button from '../button';

const CreateEditPropertyForm = ({ form, onCancel, loading }) => {
  const readFile = (e) => {
    const files = e.target.files;

    if (files && files[0]) {
      const FR = new FileReader();

      FR.addEventListener('load', function (e) {
        document.getElementById('img').src = e.target.result;
        form.setFieldValue('imageBase64', e.target.result);
      });

      FR.readAsDataURL(files[0]);
    }
  };

  return (
    <form onSubmit={form.handleSubmit}>
      <FormContainer container spacing={3}>
        <FormContainer item xs={12} md={6}>
          <ImageInput>
            <input
              accept='image/*'
              id='raised-button-file'
              type='file'
              hidden
              onChange={readFile}
            />
            <label htmlFor='raised-button-file'>
              <Button variant='contained' component='span'>
                Upload Image
              </Button>
            </label>
            <br />
            <img id='img' alt='' src={form.values.image} />
          </ImageInput>
        </FormContainer>
        <FormContainer item xs={12} md={6}>
          <FormInput
            name='description'
            variant='outlined'
            label='description'
            multiline
            rowsMax={8}
            rows={8}
            value={form.values.description}
            onChange={form.handleChange}
          />
        </FormContainer>
        <FormContainer item xs={12} md={2}>
          <FormInput
            name='name'
            variant='outlined'
            label='Property Name'
            required
            value={form.values.name}
            onChange={form.handleChange}
          />
        </FormContainer>
        <FormContainer item xs={6} md={2}>
          <FormInput
            name='no'
            variant='outlined'
            label='Store No.'
            required
            value={form.values.no}
            onChange={form.handleChange}
          />
        </FormContainer>
        <FormContainer item xs={6} md={2}>
          <FormInput
            type='number'
            name='sqFt'
            variant='outlined'
            label='Sq. ft'
            value={form.values.sqFt}
            onChange={form.handleChange}
          />
        </FormContainer>
        <FormContainer item xs={6} md={2}>
          <FormInput
            type='number'
            name='monthlyPay'
            variant='outlined'
            label='Base Monthly Rent'
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>$</InputAdornment>
              ),
            }}
            value={form.values.monthlyPay}
            onChange={form.handleChange}
          />
        </FormContainer>
        <FormContainer item xs={6} md={2}>
          <FormInput
            type='number'
            name='nnnCharges'
            variant='outlined'
            label='Monthly NNN Charges'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>$</InputAdornment>
              ),
            }}
            value={form.values.nnnCharges}
            onChange={form.handleChange}
          />
        </FormContainer>
        <FormContainer item xs={12} md={2}>
          <FormInput
            type='number'
            disabled
            name='totalPay'
            variant='outlined'
            label='Base Monthly Rent'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>$</InputAdornment>
              ),
            }}
            value={form.values.monthlyPay + form.values.nnnCharges}
          />
        </FormContainer>
        <FormContainer item xs={12} md={2}>
          <FormInput
            name='address1'
            variant='outlined'
            label='Property Address 1'
            required
            value={form.values.address1}
            onChange={form.handleChange}
          />
        </FormContainer>
        <FormContainer item xs={12} md={2}>
          <FormInput
            name='address2'
            variant='outlined'
            label='Property Address 2'
            value={form.values.address2}
            onChange={form.handleChange}
          />
        </FormContainer>
        <FormContainer item xs={6} md={2}>
          <CountryPicker
            required
            value={form.values.country?.isoCode}
            setValue={form.setFieldValue}
          />
        </FormContainer>
        <FormContainer item xs={6} md={2}>
          <StatePicker
            required
            value={form.values.state?.isoCode}
            setValue={form.setFieldValue}
            country={form.values.country?.isoCode}
          />
        </FormContainer>
        <FormContainer item xs={6} md={2}>
          <FormInput
            name='city'
            variant='outlined'
            label='City'
            required
            value={form.values.city}
            onChange={form.handleChange}
          />
        </FormContainer>
        <FormContainer item xs={6} md={2}>
          <FormInput
            name='zipCode'
            variant='outlined'
            label='Zip Code'
            required
            value={form.values.zipCode}
            onChange={form.handleChange}
          />
        </FormContainer>
        <FormContainer item xs={12} md={3}>
          <FormInput
            name='leaseIncreaseOn'
            variant='outlined'
            label='Lease Increase On'
            type='date'
            InputLabelProps={{
              shrink: true,
            }}
            value={form.values.leaseIncreaseOn}
            onChange={form.handleChange}
          />
        </FormContainer>
        <FormContainer item xs={12} md={3}>
          <FormInput
            name='orgLeaseDate'
            variant='outlined'
            label='Orig. Lease Date'
            type='date'
            required
            InputLabelProps={{
              shrink: true,
            }}
            value={form.values.orgLeaseDate}
            onChange={form.handleChange}
          />
        </FormContainer>
        <FormContainer item xs={12} md={3}>
          <FormInput
            name='orgTerminationDate'
            variant='outlined'
            label='Orig. Tremination Date'
            type='date'
            required
            InputLabelProps={{
              shrink: true,
            }}
            value={form.values.orgTerminationDate}
            onChange={form.handleChange}
          />
        </FormContainer>
        <FormContainer item xs={12} md={3}>
          <FormInput
            name='currentTerminationDate'
            variant='outlined'
            label='Current Termination Date'
            type='date'
            required
            InputLabelProps={{
              shrink: true,
            }}
            value={form.values.currentTerminationDate}
            onChange={form.handleChange}
          />
        </FormContainer>
        <FormContainer item xs={8} md={2}>
          <FormInput
            type='number'
            name='increaseAmount'
            variant='outlined'
            label='Increase Amount'
            required={!!form?.values?.leaseIncreaseOn}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>$</InputAdornment>
              ),
            }}
            value={form.values.increaseAmount}
            onChange={form.handleChange}
          />
        </FormContainer>
        <FormContainer item xs={4} md={1}>
          <FormControlLabel
            control={
              <Checkbox
                checked={form.values.autoIncreaseAmount}
                onChange={form.handleChange}
                name='autoIncreaseAmount'
              />
            }
            label='Auto'
          />
        </FormContainer>
        <FormContainer item xs={12} md={2}>
          <FormInput
            type='number'
            name='terms'
            variant='outlined'
            label='Terms Available'
            required
            value={form.values.terms}
            onChange={form.handleChange}
          />
        </FormContainer>
        <FormContainer item xs={12} md={2}>
          <FormInput
            type='number'
            name='termsAvailableYear'
            variant='outlined'
            value={form.values.termsAvailableYear}
            onChange={form.handleChange}
          />
          <FormHelperText>Years</FormHelperText>
        </FormContainer>
        <FormContainer item xs={12} md={2}>
          <FormInput
            type='number'
            name='termsAvailableMonth'
            variant='outlined'
            value={form.values.termsAvailableMonth}
            onChange={form.handleChange}
          />
          <FormHelperText>Months</FormHelperText>
        </FormContainer>
        <FormContainer item xs={8} md={2}>
          <FormInput
            name='currentTermRenewalDate'
            variant='outlined'
            label='Curr. Term Renewal Date'
            type='date'
            required
            InputLabelProps={{
              shrink: true,
            }}
            value={form.values.currentTermRenewalDate}
            onChange={form.handleChange}
          />
        </FormContainer>
        <FormContainer item xs={4} md={1}>
          <FormControlLabel
            control={
              <Checkbox
                checked={form.values.autoRenewal}
                onChange={form.handleChange}
                name='autoRenewal'
              />
            }
            label='Auto'
          />
        </FormContainer>
        <FormContainer item xs={6} md={2}>
          <FormInput
            required
            type='number'
            name='renewalNoticeTime'
            variant='outlined'
            label='Renewal Notice Time'
            value={form.values.renewalNoticeTime}
            onChange={form.handleChange}
          />
        </FormContainer>
        <FormContainer item xs={6} md={2}>
          <FormInput
            select
            required
            label='Renewal Type'
            name='renewalType'
            value={form.values.renewalType}
            onChange={form.handleChange}
            SelectProps={{
              native: true,
            }}
            variant='outlined'
          >
            <option value='' hidden></option>
            <option value='days'>Days</option>
            <option value='months'>Months</option>
          </FormInput>
        </FormContainer>
        <FormContainer item xs={12} md={2}>
          <FormInput
            name='fullLeaseExpiration'
            variant='outlined'
            label='Full Lease Expiration'
            type='date'
            required
            InputLabelProps={{
              shrink: true,
            }}
            value={form.values.fullLeaseExpiration}
            onChange={form.handleChange}
          />
        </FormContainer>
      </FormContainer>
      <FormButtonContainer>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Button onClick={onCancel} variant='contained' color='secondary'>
              Cancel
            </Button>
            <Button
              variant='contained'
              type='submit'
              custom='success'
              endIcon={<ArrowForwardIosIcon />}
            >
              Save and Continue
            </Button>
          </>
        )}
      </FormButtonContainer>
    </form>
  );
};

export default CreateEditPropertyForm;
