import React, { Component } from 'react';
import axios from 'axios';
import ScrollAnimation from 'react-animate-on-scroll';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { POSTURL } from '../Navigation/Constant';

import { LoginForm, RegisterForm } from '../components';

import {
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBContainer,
  MDBCol,
  MDBBtn,
  MDBNavbar,
  MDBCollapse,
  MDBNavbarToggler,
  MDBModal,
  MDBModalBody,
  MDBRow,
  MDBMedia,
} from 'mdbreact';

class Home1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen1: false,
      // modal: false,
      modal8: false,
      modal3: false,
      username: '',
      email: '',
      password: '',
      email1: '',
      password1: '',
      inital_data: true,
      onForgot: false,
      inital_stage: true,
      showButton: true,
      ShowSubmit: false,
      second_data: false,
      register_now: true,
      confirmpassword: '',
      newpassword: '',
      reset_pas: false,
      login_data: false,
      onReg: false,
      accessctrl: '',
      activated: '',
      superadmin_id: '',
      type: '',
      invalid_pass: '',
      modal4: false,
      position: '',
      message: '',
      show_login_page: false,
      verified_email_status: false,
      res_pas_data: '',
      message1: '',
      modal1: false,
      alreadyRegistered: false,
      invite_open: false,
      accessctrl1: '',
      admin_name: '',
    };
  }

  toggle = (nr) => () => {
    let modalNumber = 'modal' + nr;
    this.setState({
      [modalNumber]: !this.state[modalNumber],
    });
  };

  componentDidMount() {
    var link = window.location.href.split('?');
    if (window.location.href.includes('login')) {
      this.setState({
        login_data: true,
        inital_data: true,
        register_now: false,
        inital_stage: true,
        showButton: true,
        reset_pas: false,
        invite_open: false,
      });
    }

    if (link.length === 3) {
      this.setState({
        reset_pas: true,
        login_data: false,
      });
      if (link[1] === 'type=forgetPassword') {
        this.setState({
          reset_pas: true,
          login_data: false,
          register_now: false,
        });
      }

      if (link[1] === 'SID=') {
        var invite_id = {
          invite_id: link[2],
        };
        this.setState({
          reset_pas: false,
          login_data: false,
          invite_open: true,
          register_now: false,
        });
        //console.log("inside model1_invites");

        axios
          .post(POSTURL + 'fetch_single_invites', invite_id)
          .then((res) => {
            //console.log("fetch_single_invites success ",res);
            var css = res.data.data;
            var status = res.data.status;
            if (status === 1) {
              if (css.alreadyRegistered === false) {
                this.setState({
                  username: css.username,
                  email: css.email,
                  alreadyRegistered: css.alreadyRegistered,
                  superadmin_id: css.addedByuser,
                  mobile: css.number,
                  accessctrl: css.accessctrl,
                });
                if (css.company_details[0]) {
                  this.setState({
                    admin_name: css.company_details[0].username,
                    companyName: css.company_details[0].companyName,
                  });
                }
              } else {
                //console.log("fjhdjh");
                this.setState({
                  modal1: true,
                });

                //console.log("this.state.model1",this.state.model1);
              }

              this.setState({
                accessctrl: css.accessctrl,
                activated: css.activated,
                superadmin_id: css.addedByuser,
              });
            } else {
              this.setState({
                reset_pas: false,
                login_data: false,
                invite_open: false,
                register_now: true,
              });
            }

            // history.push("/dashboard")
          }) // re-direct to dashboard after successful profile update
          .catch(
            (err) => console.log('error: ')
            // dispatch({
            // 	type: GET_ERRORS,
            // 	payload: err.response
            // })
          );
      }
    }

    if (link.length === 3) {
      if (link[1] === 'VerifyToken=') {
        //console.log("inside verfiyyy");
        invite_id = {
          invite_id: link[2],
        };

        this.setState({
          reset_pas: false,
          login_data: false,
        });
        //console.log("imodal1nside emailVerification");

        axios.post(POSTURL + 'emailVerification', invite_id).then((res) => {
          //console.log("emailVerification success ",res);
          var css = res.data.status;
          var data = res.data.message;
          if (css === 1) {
            // alert("email verification done successfully");

            setTimeout(
              function () {
                this.setState({
                  message:
                    'Email verification successful. Please log in to continue.',
                });
              }.bind(this),
              1000
            );
          } else {
            setTimeout(
              function () {
                this.setState({
                  message: data,
                });
              }.bind(this),
              1000
            );
          }
          this.setState({
            verified_email_status: true,
            login_data: true,
            inital_data: true,
            register_now: false,
          });
        });
      }
    }
  }
  register_now11 = (event) => {
    window.location.href = '/';
  };
  change_event = (event) => {
    event.preventDefault();
    this.setState({
      message1: '',
    });
  };

  onChangeHandler = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  toggleCollapse = () => {
    this.setState({ isOpen1: !this.state.isOpen1 });
  };

  onForgot = (event, data) => {
    event.preventDefault();
    this.setState({
      inital_data: false,
      onSubmitHandler: false,
      inital_stage: false,
      showButton: false,
      ShowSubmit: true,
      second_data: true,
      login_data: false,
      res_pas_data: '',
      message: '',
      invalid_pass: '',
    });
  };

  Submit = (event, data) => {
    this.setState({
      res_pas_data: '',
    });
    event.preventDefault();
    const forgot_User = {
      email: this.state.email1,
    };
    //console.log("forgot_User",forgot_User);
    axios
      .post(POSTURL + 'forget_password', forgot_User)
      .then((res) => {
        //console.log("forgot password success ",res);
        if (res.data.status === 1) {
          setTimeout(
            function () {
              this.setState({
                res_pas_data: 'Email sent successfully',
              });
            }.bind(this),
            1000
          );

          var link = window.location.href.split('?');
          //console.log("linking",link);
          // alert("Mail sent successfully!!")
          if (link.length === 3) {
            this.setState({
              reset_pas: true,
              second_data: false,
              login_data: false,
            });
          }
        } else {
          this.setState({
            res_pas_data: "Email doesn't exist",
          });
          setTimeout(
            function () {
              this.setState({
                res_pas_data: ' ',
              });
            }.bind(this),
            3000
          );

          this.setState({
            login_data: false,
          });
        }
      })
      .catch((err) => console.log('error: '));
    this.setState({
      email1: '',
    });

    // var link = window.location.href.split('?')
    //  //console.log("linking",link);
    //  if(link.length == 3)
    //  {
    //    this.setState({
    //      reset_pas:true,
    //    })
    //  }

    // else {
    //   alert(not found)
    // }
  };

  SaveHandler = (event) => {
    event.preventDefault();
    var link = window.location.href.split('=');
    //console.log("linking",link);
    const reset_User_pass = {
      newpassword: this.state.newpassword,
      confirmpassword: this.state.confirmpassword,
      id: link[2],
    };
    //console.log("reset_User_pass", reset_User_pass);

    if (this.state.newpassword === this.state.confirmpassword) {
      // alert("your password set successfully!");
      axios
        .post(POSTURL + 'reset_password_home', reset_User_pass)
        .then((res) => {
          //console.log("reset_password_home success ",res);
          if (res.data.status) {
            setInterval(
              function () {
                this.setState({
                  message: 'Password reset successful!',
                  show_login_page: true,
                  login_data: true,
                  register_now: false,
                });
              }.bind(this),
              2000
            );
          }
        })
        .catch((err) => console.log('error: '));
      this.setState({
        newpassword: '',
        confirmpassword: '',
        login_data: true,
        reset_pas: false,
      });
    } else {
      alert('new Password and confirm Password are not matched');
    }
  };

  loginClick = (event) => {
    event.preventDefault();
    this.setState({
      login_data: true,
      inital_data: true,
      register_now: false,
      inital_stage: true,
      showButton: true,
      reset_pas: false,
      invite_open: false,
    });
  };

  onReg = (event) => {
    event.preventDefault();
    this.setState({
      register_now: true,
      login_data: false,
      // inital_data:true,
      second_data: false,
    });
  };

  onReg1 = (event) => {
    event.preventDefault();
    this.setState({
      register_now: true,
      login_data: false,
      second_data: false,
      res_pas_data: '',
    });
  };
  onlogg = (event) => {
    event.preventDefault();
    this.setState({
      register_now: false,
      login_data: true,
      second_data: false,
      inital_data: true,
      showButton: true,
      inital_stage: true,
    });
  };
  class_apply = (event, data) => {
    //console.log("data",data);
    this.setState({
      type: data,
    });
    //console.log("this.state.type",this.state.type);
  };

  accept_invitation = (event) => {
    //console.log("accept_invitation");
    var link = window.location.href.split('?');
    // };
    //console.log("linking",link);

    if (link.length === 3) {
      if (link[1] === 'SID=') {
        var invite_id = {
          invite_id: link[2],
        };
        axios
          .post(POSTURL + 'addCompanyToProfile', invite_id)
          .then((res) => {
            //console.log("addCompanyToProfile success ",res);
            var css = res.data;
            if (css.status === 1) {
              this.setState({
                modal1: false,
              });
            }
          })
          .catch((err) => console.log('error: '));
      }
    }
  };

  render() {
    return (
      <div>
        <div className='homebg'>
          <MDBContainer className='d-flex uppernav' fluid>
            <MDBContainer className='d-flex uppernav_inner'>
              <MDBRow style={{ color: 'black' }}>
                <MDBModal
                  className='do_you'
                  isOpen={this.state.modal1}
                  toggle={this.toggle(1)}
                  size='sm'
                >
                  <MDBModalBody>
                    <MDBRow
                      className='justify-content-center'
                      style={{ paddingBottom: '10px' }}
                    >
                      Do you want to accept the invitation ?
                    </MDBRow>
                    <MDBRow className='justify-content-center'>
                      <MDBBtn
                        className='acchbut'
                        onClick={(event) => this.accept_invitation()}
                      >
                        Accept
                      </MDBBtn>
                      <MDBBtn className='canhbut' onClick={this.toggle(1)}>
                        Cancel
                      </MDBBtn>
                    </MDBRow>
                  </MDBModalBody>
                </MDBModal>
              </MDBRow>
              <MDBCol md='6'>
                <MDBContainer
                  className='text-left icongroupres'
                  style={{ paddingLeft: '30px' }}
                >
                  <img
                    alt=''
                    src='../../img/social-facebook-circular-button1.png'
                    style={{ height: '20px', marginRight: '10px' }}
                  />
                  <img
                    alt=''
                    src='../../img/twitter-circular-button1.png'
                    style={{ height: '20px', marginRight: '10px' }}
                  />
                  <img
                    alt=''
                    src='../../img/social-linkedin-circular-button1.png'
                    style={{ height: '20px', marginRight: '10px' }}
                  />
                </MDBContainer>
              </MDBCol>
              <MDBCol
                md='6'
                className='d-flex call_mail'
                style={{ paddingRight: '0px' }}
              >
                <div>
                  <MDBContainer>
                    <a href='mailto:info@myleaseremainder.com'>
                      <img
                        alt=''
                        src='../../img/mail.png'
                        style={{ paddingRight: '10px' }}
                      />
                      <span style={{ fontSize: '10px', color: '#fff' }}>
                        info@myleaseremainder.com
                      </span>
                    </a>
                  </MDBContainer>
                </div>
                {/* <div>
                    <MDBContainer style={{paddingLeft:"0px"}}>
                        <img alt="" src="../../img/call.png" style={{paddingRight:"10px"}} /><span style={{fontSize:"10px"}}>+1989 989 8989</span>
                    </MDBContainer>
                </div> */}
              </MDBCol>
            </MDBContainer>
          </MDBContainer>
          <MDBContainer>
            <MDBContainer className='second_navcontainer'>
              <h5 className='float-left'>
                <a href='https://www.myleasereminder.com'>
                  <img
                    alt=''
                    src='../../img/Logo.png'
                    className='img-fluid logo_mainnav'
                    style={{ height: '50px' }}
                  />
                </a>
              </h5>

              <MDBNavbar
                dark
                expand='md'
                className='justify-content-end second_nav'
              >
                <MDBNavbarToggler onClick={this.toggleCollapse} />
                <MDBCollapse
                  id='navbarCollapse3'
                  isOpen={this.state.isOpen1}
                  navbar
                >
                  <MDBNavbarNav className='hoverovernavmenu'>
                    <MDBNavItem style={{ padding: '0px 0px' }}>
                      <MDBNavLink
                        to='/'
                        className='activehomenavitem'
                        exact={true}
                        onChange={(event) => this.class_apply(event, 'home1')}
                      >
                        HOME
                      </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem style={{ padding: '0px 0px' }}>
                      <MDBNavLink
                        to='/aboutus'
                        onChange={(event) => this.class_apply(event, 'aboutus')}
                      >
                        ABOUT US
                      </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem style={{ padding: '0px 0px' }}>
                      <MDBNavLink
                        to='/pricing'
                        className=''
                        onChange={(event) => this.class_apply(event, 'pricing')}
                      >
                        PRICING
                      </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem style={{ padding: '0px 0px' }}>
                      <MDBNavLink
                        to='/contactus'
                        className=''
                        onChange={(event) =>
                          this.class_apply(event, 'contactus')
                        }
                      >
                        CONTACT US
                      </MDBNavLink>
                    </MDBNavItem>
                    {/*<MDBNavItem>
                      <MDBNavLink to="#!" style={{borderLeft: "1px solid white",paddingLeft:"10px"}} ><img alt="" src="../../img/login.png" className="img-fluid" onClick={this.loginClick}  alt="" style={{width:" 25px",height:" 25px"}} /></MDBNavLink>
                      <MDBModal isOpen={this.state.modal8} toggle={this.toggle(8)} fullHeight position="Edit">
											<MDBModalHeader toggle={this.toggle(8)}>MDBModal title</MDBModalHeader>
											<MDBModalBody>
											<MDBInput
												label="Company"
												type="text"
												id="company1"
												size="sm"
												onChange={this.onChange}
												value={this.state.company1}

											/>
											<MDBInput
												label="Name"
												type="text"
												id="name1"
												size="sm"
												onChange={this.onChange}
												value={this.state.name1}

											/>
											<MDBInput
												label="Phone"
												type="text"
												id="phone1"
												size="sm"
												onChange={this.onChange}
												value={this.state.phone1}

                        />
											</MDBModalBody>
											<MDBModalFooter>
											<MDBBtn color="secondary" onClick={this.toggle(8)}>Close</MDBBtn>
											<MDBBtn color="primary" onClick={this.Save_change}>Save changes</MDBBtn>
											</MDBModalFooter>
											</MDBModal>
                    </MDBNavItem>*/}
                  </MDBNavbarNav>
                </MDBCollapse>
                <div
                  style={{
                    borderLeft: '1px solid white',
                    paddingLeft: '30px',
                    marginTop: ' -13px',
                  }}
                >
                  <a href='#!'>
                    <img
                      alt=''
                      src='../../img/login.png'
                      className='img-fluid'
                      onClick={this.loginClick}
                      style={{ width: ' 25px', height: ' 25px' }}
                    />
                  </a>
                  <span className='login_text' onClick={this.loginClick}>
                    Login
                  </span>
                </div>
              </MDBNavbar>
            </MDBContainer>
            <MDBContainer className='float-left'>
              <div className='home_row'>
                {this.state.invite_open === false && (
                  <MDBCol md='8'>
                    <div className='intro_div'>
                      <div className='intro_divinner'>
                        <h6 style={{ fontWeight: 'unset' }}>EASY TO USE</h6>
                        {/*<h2 className="h2-responsive font-weight-bold">*/}
                        <h2 className='h2-responsive'>
                          LEASE TRACKING & LEASE REMINDERS
                        </h2>
                        {/*<hr className="hr-light" />*/}
                        <h6 className='mb-4'>
                          My Lease Reminder (MLR) helps businesses manage their
                          leased locations and sends upcoming lease renewals,
                          lease expirations and other reminders!
                        </h6>
                        <MDBBtn
                          rounded
                          color='success'
                          className='roundcorner learn_morebut'
                          type='submit'
                          style={{ padding: '9px 20px', textTransform: 'none' }}
                          href='https://www.myleasereminder.com/aboutus'
                        >
                          Learn More
                          <img
                            alt=''
                            src='../../img/Group 10.png'
                            style={{ paddingLeft: '10px' }}
                          />
                        </MDBBtn>
                      </div>
                    </div>
                  </MDBCol>
                )}
                {this.state.register_now === true && <RegisterForm />}
                {this.state.login_data === true && (
                  <LoginForm onReg={this.onReg} />
                )}

                {this.state.second_data === true && (
                  <MDBCol md='3'>
                    <div
                      className='formdiv11'
                      style={{
                        backgroundColor: 'rgba(23,67,88,0.5)',
                        marginBottom: '30px',
                        padding: '10px 10px 120px 10px',
                        height: '576px',
                      }}
                    >
                      <div
                        style={{
                          height: '110px',
                          color: 'white',
                          textAlign: 'center',
                        }}
                      >
                        {this.state.res_pas_data}
                      </div>

                      <div>
                        <h5
                          className='text-left login_head'
                          style={{ paddingTop: '40px' }}
                        >
                          Forgot Password
                        </h5>
                        <div className='form_div'>
                          <label
                            htmlFor='defaultFormCardNameEx'
                            className=' font-weight-light float-left'
                            style={{ fontWeight: '400' }}
                          >
                            Email Address
                          </label>
                          <input
                            type='email'
                            id='email1'
                            name={this.state.email1}
                            className='form-control'
                            required={true}
                            value={this.state.email1}
                            onChange={this.onChangeHandler}
                          />
                        </div>

                        <div className='form_div' style={{ height: '20px' }}>
                          <p
                            onClick={(event) => this.onReg1(event)}
                            style={{ cursor: 'pointer' }}
                          >
                            {' '}
                            Register Now ?{' '}
                          </p>
                        </div>
                        <div className='form_div' style={{ height: '20px' }}>
                          <p
                            onClick={(event) => this.onlogg(event)}
                            style={{ cursor: 'pointer' }}
                          >
                            {' '}
                            Login Now ?{' '}
                          </p>
                        </div>
                        <MDBBtn
                          rounded
                          color='success'
                          className='roundcorner register_mainpage'
                          type='submit'
                          onClick={this.Submit}
                          style={{
                            padding: '10px 32px',
                            textTransform: 'none',
                          }}
                        >
                          Submit
                          <img
                            alt=''
                            src='../../img/Group 10.png'
                            style={{ paddingLeft: '10px' }}
                          />
                        </MDBBtn>
                      </div>
                    </div>
                  </MDBCol>
                )}

                {this.state.reset_pas === true && (
                  <MDBCol md='3'>
                    <div>
                      <span>{this.state.res_pas_data}</span>

                      <div
                        className='formdiv1'
                        style={{ backgroundColor: 'rgba(23,67,88,0.5)' }}
                      >
                        <h4 className='text-left login_head'>Reset Password</h4>

                        <div className='form_div'>
                          <label
                            htmlFor='defaultFormCardNameEx'
                            className=' font-weight-light float-left'
                          >
                            New Password
                          </label>
                          <input
                            type='password'
                            id='newpassword'
                            name={this.state.newpassword}
                            className='form-control'
                            required={true}
                            value={this.state.newpassword}
                            onChange={this.onChangeHandler}
                            style={{ borderRadius: '0px' }}
                          />
                        </div>

                        <div className='form_div'>
                          <label
                            htmlFor='defaultFormCardNameEx'
                            className=' font-weight-light float-left'
                          >
                            Confirm Password
                          </label>
                          <input
                            type='password'
                            id='confirmpassword'
                            name={this.state.confirmpassword}
                            className='form-control'
                            required={true}
                            value={this.state.confirmpassword}
                            onChange={this.onChangeHandler}
                            style={{ borderRadius: '0px' }}
                          />
                        </div>
                        <div>
                          <MDBBtn
                            rounded
                            color='success'
                            className='roundcorner register_mainpage'
                            style={{
                              padding: '12px 45px',
                              textTransform: 'none',
                            }}
                            type='submit'
                            onClick={this.SaveHandler}
                          >
                            Save
                            <img
                              alt=''
                              src='../../img/Group 10.png'
                              style={{ paddingLeft: '10px' }}
                            />
                          </MDBBtn>
                        </div>
                      </div>
                    </div>
                  </MDBCol>
                )}
              </div>
            </MDBContainer>
          </MDBContainer>
        </div>
        <MDBContainer>
          <MDBRow>
            <MDBCol md='3'>
              <a href='https://apps.apple.com/us/app/my-lease-reminder/id1577539638'>
                <img
                  alt='Apple iOS App Store'
                  src='../../img/App_Store_Badge.png'
                />
              </a>
            </MDBCol>
            <MDBCol md='3'>
              <a href='https://play.google.com/store/apps/details?id=com.myleasereminder'>
                <img alt='Google Play' src='../../img/google-play-badge.png' />
              </a>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <div className='contenthome_div' md='12'>
          <MDBContainer className='mt-100' style={{ marginBottom: '100px' }}>
            <MDBRow>
              <MDBCol md='6'>
                <h6 className='text-left'>WELCOME TO</h6>
                <h2 className='text-left'>MY LEASE REMINDER</h2>
                <p className='text-left'>
                  MLR helps businesses manage their lease locations and sends
                  upcoming lease renewal, lease expiration, lease increase and
                  other important remainders!
                </p>
                {/* <p className="text-left">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
    Lorem Ipsum has been the industrys standard dummy text ever since the 1500s
   , when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p> */}
              </MDBCol>
              <MDBCol md='6' className='video_divouter'>
                <div className='video_div'>
                  <img
                    alt=''
                    src='../../img/music-and-multimedia.png'
                    className='img-fluid'
                  />
                </div>
                {/*<iframe className="embed-responsive-item video_div" src="https://www.youtube.com/embed/v64KOxKVLVg" allowfullscreen ></iframe>*/}
              </MDBCol>
            </MDBRow>
          </MDBContainer>
          <MDBContainer className='mt-70' fluid>
            <MDBRow>
              <MDBCol md='5' style={{ padding: '0px' }}>
                <img
                  alt=''
                  src='../../img/Mask Group 2.png'
                  className='img-fluid img1'
                />
              </MDBCol>
              <MDBCol
                md='7'
                style={{ backgroundColor: '#f6f6f6', padding: '14px' }}
              >
                <MDBRow>
                  <MDBCol
                    md='12'
                    className='text-justify'
                    style={{ paddingLeft: '70px' }}
                  >
                    <span>
                      Features include the following and more!
                      <br />
                      <h1>FEATURES</h1>
                    </span>
                  </MDBCol>
                  <MDBCol
                    md='6'
                    className='text-left homecol1'
                    style={{ padding: '0px 30px' }}
                  >
                    <ul>
                      <li className='list-unstyled content_list'>
                        <MDBMedia>
                          <MDBMedia left middle className='mr-3' href='#'>
                            <ScrollAnimation animateIn='fadeInUp'>
                              <MDBMedia
                                object
                                src='../../img/letter.png'
                                alt=''
                                className='home_list_img'
                              />
                            </ScrollAnimation>
                          </MDBMedia>
                          <MDBMedia body>
                            <ScrollAnimation animateIn='fadeInUp'>
                              Keep track of property details and automatically
                              receive important reminders{' '}
                            </ScrollAnimation>
                          </MDBMedia>
                        </MDBMedia>
                      </li>
                      <li className='list-unstyled content_list'>
                        <MDBMedia>
                          <MDBMedia left middle className='mr-3' href='#'>
                            <ScrollAnimation animateIn='fadeInUp'>
                              <MDBMedia
                                object
                                src='../../img/folder.png'
                                alt=''
                                className='home_list_img'
                              />
                            </ScrollAnimation>
                          </MDBMedia>
                          <MDBMedia body>
                            <ScrollAnimation animateIn='fadeInUp'>
                              Keep track of lease agreements and important
                              documents
                            </ScrollAnimation>
                          </MDBMedia>
                        </MDBMedia>
                      </li>
                      <li className='list-unstyled content_list'>
                        <MDBMedia>
                          <MDBMedia left middle className='mr-3' href='#'>
                            <ScrollAnimation animateIn='fadeInUp'>
                              <MDBMedia
                                object
                                src='../../img/administration.png'
                                alt=''
                                className='home_list_img'
                              />
                            </ScrollAnimation>
                          </MDBMedia>
                          <MDBMedia body>
                            <ScrollAnimation animateIn='fadeInUp'>
                              Share property information with others within your
                              organization
                            </ScrollAnimation>
                          </MDBMedia>
                        </MDBMedia>
                      </li>
                    </ul>
                  </MDBCol>
                  <MDBCol
                    md='6'
                    className='text-left homecol2'
                    style={{ padding: '0px 30px' }}
                  >
                    <ul>
                      <li className='list-unstyled content_list'>
                        <MDBMedia>
                          <MDBMedia left middle className='mr-3' href='#'>
                            <ScrollAnimation animateIn='fadeInUp'>
                              <MDBMedia
                                object
                                src='../../img/document.png'
                                alt='Generic placeholder image'
                                className='home_list_img'
                              />
                            </ScrollAnimation>
                          </MDBMedia>
                          <MDBMedia body>
                            <ScrollAnimation animateIn='fadeInUp'>
                              Keep track of equipment and other warranties
                            </ScrollAnimation>
                          </MDBMedia>
                        </MDBMedia>
                      </li>
                      <li className='list-unstyled content_list'>
                        <MDBMedia>
                          <MDBMedia left middle className='mr-3' href='#'>
                            <ScrollAnimation animateIn='fadeInUp'>
                              <MDBMedia
                                object
                                src='../../img/list.png'
                                alt='Generic placeholder image'
                                className='home_list_img'
                              />
                            </ScrollAnimation>
                          </MDBMedia>
                          <MDBMedia body>
                            <ScrollAnimation animateIn='fadeInUp'>
                              Access key contact information for utilities,
                              signage and more
                            </ScrollAnimation>
                          </MDBMedia>
                        </MDBMedia>
                      </li>
                      <li className='list-unstyled content_list'>
                        <MDBMedia>
                          <MDBMedia left middle className='mr-3' href='#'>
                            <ScrollAnimation animateIn='fadeInUp'>
                              <MDBMedia
                                object
                                src='../../img/notebook.png'
                                alt='Generic placeholder image'
                                className='home_list_img'
                              />
                            </ScrollAnimation>
                          </MDBMedia>
                          <MDBMedia body>
                            <ScrollAnimation animateIn='fadeInUp'>
                              Access security information such as safe
                              combinations and security cameras
                            </ScrollAnimation>
                          </MDBMedia>
                        </MDBMedia>
                      </li>
                    </ul>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
          <MDBContainer className='testimonialmain'></MDBContainer>
          <MDBContainer className='register_now'>
            <h2 className='text-center'>GIVE IT A TRY</h2>
            <MDBBtn
              rounded
              color='success'
              className='roundcorner text-center regnow'
              type='submit'
              style={{ textTransform: 'none' }}
              onClick={this.register_now11}
            >
              Register Now
              <img
                alt=''
                src='../../img/Group 10.png'
                style={{ paddingLeft: '10px' }}
              />
            </MDBBtn>
            <div>
              <span>&copy;2020, Austin App House. All Rights Reserved</span>
              <span id='siteseal'>
                <script
                  async
                  type='text/javascript'
                  src='https://seal.godaddy.com/getSeal?sealID=UlgFF8MZE4Uywkz6Il9yZY2t5QbNXyl6Xx7gkmkw0Rf1KIRx3GMHOU4cQhxK'
                ></script>
              </span>
            </div>
          </MDBContainer>
        </div>
      </div>
    );
  }
}

export default Home1;
