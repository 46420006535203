import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { MDBCol, MDBBtn } from 'mdbreact';

import { SessionContext } from '../../context/sessionContext';

const LoginForm = ({ onReg }) => {
  const [loading, setLoading] = useState(false);
  const { signIn } = useContext(SessionContext);
  const history = useHistory();
  const form = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async (values) => {
      try {
        setLoading(true);
        await signIn(values);
      } catch (err) {
        toast.error('User or Password Incorrect');
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <MDBCol className='formhomecol' md='3'>
      <form onSubmit={form.handleSubmit}>
        <div className='formdiv' style={{ padding: '0px 0px 10px 0px' }}>
          <h5 className='text-left reg_head'>Login</h5>
          <div className='form_div'>
            <label
              htmlFor='defaultFormCardNameEx'
              className=' font-weight-light float-left'
              style={{ fontWeight: '400' }}
            >
              Email Address
            </label>
            <input
              name='email'
              type='email'
              className='form-control'
              required={true}
              value={form.values.email}
              onChange={form.handleChange}
            />
          </div>

          <div className='form_div' style={{ paddingBottom: '0px' }}>
            <label
              htmlFor='defaultFormCardNameEx'
              className=' font-weight-light float-left'
              style={{ fontWeight: '400' }}
            >
              Password
            </label>
            <input
              name='password'
              type='password'
              className='form-control'
              required={true}
              value={form.values.password}
              onChange={form.handleChange}
            />
          </div>
          <div className='form_div' style={{ height: '20px' }}>
            <p onClick={(event) => onReg(event)} style={{ cursor: 'pointer' }}>
              Register Now ?
            </p>
          </div>
          <div className='form_div' style={{ height: '20px' }}>
            <p
              onClick={() => history.push('/forgotPassword')}
              style={{ cursor: 'pointer' }}
            >
              Forgot password
            </p>
          </div>
          <div className='text-center mt-4 black-text'>
            {loading ? (
              <div className='spinner-border text-danger' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
            ) : (
              <MDBBtn
                rounded
                color='success'
                className='roundcorner register_mainpage'
                type='submit'
                style={{
                  padding: '10px 32px',
                  textTransform: 'none',
                }}
              >
                Login
                <img
                  src='../../img/Group 10.png'
                  alt='Group 10'
                  style={{ paddingLeft: '10px' }}
                />
              </MDBBtn>
            )}
          </div>
        </div>
      </form>
    </MDBCol>
  );
};

export default LoginForm;
