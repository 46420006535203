import React, { useState } from 'react';
import styled from 'styled-components';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import { useHistory, useLocation } from 'react-router-dom';

const MyStepper = styled(Stepper)`
  .MuiStepIcon-root.MuiStepIcon-active {
    color: #183650 !important;
  }
`;

const PropertyStepper = ({ step, propertyId }) => {
  const location = useLocation();
  const history = useHistory();
  const [activeStep] = useState(step);

  const steps = [
    { label: 'Add Store Details', link: `/property/${propertyId}` },
    { label: 'Add Property Owner', link: `/owner/${propertyId}` },
    { label: 'Add Landlord', link: `/landlord/${propertyId}` },
    { label: 'Add Notes', link: `/notes/${propertyId}` },
    { label: 'Add Utility', link: `/utility/${propertyId}` },
    { label: 'Add Security', link: `/security/${propertyId}` },
    { label: 'Add Equipment', link: `/equipment/${propertyId}` },
    { label: 'Add Documents', link: `/document/${propertyId}` },
  ];

  useState(() => {
    if (!location.pathname.includes('property') && propertyId === 'new') {
      history.push('/property/new');
    }
  }, [location.pathname]);

  return (
    <MyStepper alternativeLabel nonLinear activeStep={activeStep}>
      {steps.map((step) => (
        <Step key={step.label}>
          <StepButton onClick={() => history.push(step.link)}>
            {step.label}
          </StepButton>
        </Step>
      ))}
    </MyStepper>
  );
};

export default PropertyStepper;
