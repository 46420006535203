import React from 'react';
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
} from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import { FormButtonContainer, FormContainer, FormInput } from './styles';
import CountryPicker from '../pickers/country';
import StatePicker from '../pickers/states';
import Button from '../button';

const CreateEditPropertyForm = ({
  form,
  onCancel,
  loading,
  handleCheckbox,
}) => (
  <form style={{ textAlign: 'start' }} onSubmit={form.handleSubmit}>
    {!!handleCheckbox && (
      <FormControlLabel
        control={
          <Checkbox onChange={handleCheckbox} style={{ color: '#183650' }} />
        }
        label='Same as Owner'
      />
    )}
    <FormContainer container spacing={3}>
      <FormContainer item xs={12} md={4}>
        <FormInput
          name='name'
          variant='outlined'
          label='Full Name'
          value={form.values.name}
          onChange={form.handleChange}
        />
      </FormContainer>
      <FormContainer item xs={12} md={4}>
        <FormInput
          name='primaryEmail'
          variant='outlined'
          label='Primary email'
          value={form.values.primaryEmail}
          onChange={form.handleChange}
        />
      </FormContainer>
      <FormContainer item xs={12} md={4}>
        <FormInput
          name='otherEmail'
          variant='outlined'
          label='Other Email'
          value={form.values.otherEmail}
          onChange={form.handleChange}
        />
      </FormContainer>
      <FormContainer item xs={12} md={6}>
        <FormInput
          name='address1'
          variant='outlined'
          label='Address 1'
          value={form.values.address1}
          onChange={form.handleChange}
        />
      </FormContainer>
      <FormContainer item xs={12} md={6}>
        <FormInput
          name='address2'
          variant='outlined'
          label='Address 2'
          value={form.values.address2}
          onChange={form.handleChange}
        />
      </FormContainer>
      <FormContainer item xs={6} md={3}>
        <CountryPicker
          value={form.values.country?.isoCode}
          setValue={form.setFieldValue}
        />
      </FormContainer>
      <FormContainer item xs={6} md={3}>
        <StatePicker
          value={form.values.state?.isoCode}
          setValue={form.setFieldValue}
          country={form.values.country?.isoCode}
        />
      </FormContainer>
      <FormContainer item xs={12} md={3}>
        <FormInput
          name='city'
          variant='outlined'
          label='city'
          value={form.values.city}
          onChange={form.handleChange}
        />
      </FormContainer>
      <FormContainer item xs={12} md={3}>
        <FormInput
          name='zipCode'
          variant='outlined'
          label='Zip Code'
          value={form.values.zipCode}
          onChange={form.handleChange}
        />
      </FormContainer>
      <FormContainer item xs={12} md={3}>
        <FormInput
          name='phone'
          variant='outlined'
          label='Primary Phone'
          value={form.values.phone}
          onChange={form.handleChange}
        />
      </FormContainer>
      <FormContainer item xs={12} md={3}>
        <FormInput
          name='otherPhone'
          variant='outlined'
          label='Other Phone'
          value={form.values.otherPhone}
          onChange={form.handleChange}
        />
      </FormContainer>
    </FormContainer>
    <FormButtonContainer>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Button onClick={onCancel} variant='contained' color='secondary'>
            Cancel
          </Button>
          <Button
            variant='contained'
            type='submit'
            custom='success'
            endIcon={<ArrowForwardIosIcon />}
          >
            Save and Continue
          </Button>
        </>
      )}
    </FormButtonContainer>
  </form>
);

export default CreateEditPropertyForm;
