import React, { useState, useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import Modal from 'react-modal';
import Cookies from 'universal-cookie';

import { SessionContext } from '../context/sessionContext';

function IdelTimerContainer() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { logout } = useContext(SessionContext);
  const history = useHistory();
  const idleTimerRef = useRef(null);
  const customStyles = {
    content: {
      top: '35%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      width: '40%',
      transform: 'translate(-40%, -10%)',
    },
  };

  const onIdle = () => {
    console.log('user is idel');
    const cookie = new Cookies();

    if (cookie.get('myCat')) {
      setModalIsOpen(true);
      cookie.set('myHide', false);

      cookie.remove('myCat');
      cookie.remove('clientDashboardSelectedTab');
    } else {
      setModalIsOpen(false);
    }
  };

  /*const logout = () => {
    setModalIsOpen(false);
    const cookie = new Cookies();

    cookie.set('myHide', false);

    localStorage.removeItem('user');
    localStorage.removeItem('');

    cookie.remove('myCat');
    cookie.remove('clientDashboardSelectedTab');

    //console.logg(cookie.get('myCat'));
    window.location.href = '/';
  };*/
  return (
    <div>
      <Modal isOpen={modalIsOpen} style={customStyles}>
        <p style={{ textAlign: 'center' }}>
          Session Expired due to inactivity!
        </p>
        <div style={{ textAlign: 'center' }}>
          <button
            style={{
              backgroundColor: '#00C851',
              textAlign: 'center',
              borderRadius: '10em',
              color: 'white',
              width: '84px',
              height: '33px',
              padding: '7px',
              border: 'none',
            }}
            onClick={() => {
              logout();
              setModalIsOpen(!modalIsOpen);
              history.push('/');
            }}
          >
            OK
          </button>
        </div>
      </Modal>
      <IdleTimer
        ref={idleTimerRef}
        timeout={10 * 60 * 1000}
        onIdle={onIdle}
      ></IdleTimer>
    </div>
  );
}

export default IdelTimerContainer;
