import React, { Component } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { POSTURL } from '../Navigation/Constant';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBNavbar,
  MDBCollapse,
  MDBNavbarToggler,
} from 'mdbreact';

import { LoginForm, RegisterForm } from '../components';

class Aboutus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      modal8: false,
      modal3: false,
      username: '',
      email: '',
      password: '',
      email1: '',
      password1: '',
      inital_data: true,
      onForgot: false,
      inital_stage: true,
      showButton: true,
      ShowSubmit: false,
      second_data: false,
      register_now: false,
      confirmpassword: '',
      newpassword: '',
      reset_pas: false,
      login_data: false,
      onReg: false,
      accessctrl: '',
      activated: '',
      superadmin_id: '',
      type: '',
      invalid_pass: '',
      modal4: false,
      position: '',
      message: '',
      show_login_page: false,
      verified_email_status: false,
      res_pas_data: '',
      message1: '',
      modal1: false,
    };
  }

  toggle = (nr) => () => {
    let modalNumber = 'modal' + nr;
    this.setState({
      [modalNumber]: !this.state[modalNumber],
    });
  };

  componentDidMount() {
    let link = window.location.href.split('?');

    if (link.length === 3) {
      this.setState({
        reset_pas: true,
        login_data: false,
      });
      if (link[1] === 'type=forgetPassword') {
        this.setState({
          reset_pas: true,
          login_data: false,
          register_now: false,
        });
      }

      if (link[1] === 'SID=') {
        let invite_id = {
          invite_id: link[2],
        };
        this.setState({
          reset_pas: false,
          login_data: false,
        });

        axios
          .post(POSTURL + 'fetch_single_invites', invite_id)
          .then((res) => {
            let css = res.data.data;
            if (css.alreadyRegistered === false) {
              this.setState({
                username: css.username,
                email: css.email,
              });
            } else {
              //console.log("fjhdjh");
              this.setState({
                modal1: true,
              });

              //console.log("this.state.model1",this.state.model1);
            }

            this.setState({
              accessctrl: css.accessctrl,
              activated: css.activated,
              superadmin_id: css.superadmin_id,
            });

            // history.push("/dashboard")
          }) // re-direct to dashboard after successful profile update
          .catch(
            (err) => console.log('error: ')
            // dispatch({
            // 	type: GET_ERRORS,
            // 	payload: err.response
            // })
          );
      }
    }

    if (link.length === 3) {
      if (link[1] === 'VerifyToken=') {
        //console.log("inside verfiyyy");
        let invite_id = {
          invite_id: link[2],
        };

        this.setState({
          reset_pas: false,
          login_data: false,
        });
        //console.log("imodal1nside emailVerification");

        axios.post(POSTURL + 'emailVerification', invite_id).then((res) => {
          //console.log("emailVerification success ",res);
          let css = res.data.status;
          let data = res.data.message;
          if (css === 1) {
            // alert("email verification done successfully");

            setTimeout(
              function () {
                this.setState({
                  message:
                    'Email verification successful, please log in to continue.',
                });
              }.bind(this),
              1000
            );
          } else {
            setTimeout(
              function () {
                this.setState({
                  message: data,
                });
              }.bind(this),
              1000
            );
          }
          this.setState({
            verified_email_status: true,
            login_data: true,
            inital_data: true,
            register_now: false,
          });
        });
      }
    }
  }
  register_now11 = (event) => {
    window.location.href = '/';
  };
  change_event = (event) => {
    event.preventDefault();
    this.setState({
      message1: '',
    });
  };

  change_login_event = (event) => {
    //console.log("sdfjd");
    event.preventDefault();
    this.setState({
      message: '',
      invalid_pass: '',
    });
  };

  mySubmitHandler = (event) => {
    event.preventDefault();
    this.setState({
      message: '',
    });

    let link = window.location.href.split('?');
    // };
    //console.log("linking",link);
    let invite_id = {};
    if (link.length === 3) {
      //console.log("sddswd");
      if ((link[1] = 'SID=')) {
        invite_id = {
          invite_id: link[2],
        };
      }
    }
    //console.log("invite_id",invite_id);
    let newUser = {};
    if (invite_id) {
      newUser = {
        username: this.state.username,
        email: this.state.email,
        password: this.state.password,
        accessctrl: this.state.accessctrl,
        activated: this.state.activated,
        superadmin_id: this.state.superadmin_id,
        companyName: this.state.companyName,
      };
    } else {
      newUser = {
        username: this.state.username,
        email: this.state.email,
        password: this.state.password,
        companyName: this.state.companyName,
      };
    }

    //console.log("newUser",newUser);

    axios
      .post(POSTURL + 'register_user', newUser)
      .then((res) => {
        //console.log("register_user success ",res);
        let data = res.data.message;
        //   this.setState({
        //      login_data:true
        // });
        //console.log("dtaa",data);
        if (res.data.status === 1) {
          setTimeout(
            function () {
              this.setState({
                message:
                  'Registration done successfully Please check your email for verification',
                show_login_page: true,
                login_data: true,
                inital_data: true,
                inital_stage: true,

                register_now: false,
              });
            }.bind(this),
            1000
          );
        } else {
          setTimeout(
            function () {
              this.setState({
                message1: data,
              });
            }.bind(this),
            1000
          );
        }
      }) // re-direct to dashboard after successful profile update
      .catch(
        (err) => console.log('error: ')
        // dispatch({
        // 	type: GET_ERRORS,
        // 	payload: err.response
        // })
      );

    this.setState({
      username: '',
      email: '',
      password: '',
      companyName: '',
    });
  };
  something = (event) => {
    //console.log("inside something");
    if (event.keyCode === 13) {
      //console.log('enter')
      event.preventDefault();
      const cookie = new Cookies();

      cookie.remove('myHide');

      const loginUser = {
        email: this.state.email1,
        password: this.state.password1,
      };
      //console.log("loginUser",loginUser);

      axios
        .post(POSTURL + 'user_login', loginUser)
        .then((res) => {
          //console.log("user_login success ",res);
          const cookie = new Cookies();
          cookie.set('myCat', res.data.data);
          cookie.set('myCat_new', res.data.data);

          //console.log(cookie.get('myCat'));
          if (res.data.status === 1) {
            if (res.data.data.role === 'main_admin') {
              //console.log("inside main_admin");
              window.location.href = '/admin_panel';
            } else {
              //console.log("inside elsee");

              window.location.href = '/properties';
            }
          } else if (res.data.status === 0) {
            setTimeout(
              function () {
                this.setState({
                  position: 'invalid  username and Password',
                });
              }.bind(this),
              2000
            );
          } else if (res.data.status === 2) {
            setTimeout(
              function () {
                this.setState({
                  position: 'Please verify your email address!',
                });
              }.bind(this),
              2000
            );
          }
        }) // re-direct to dashboard after successful profile update
        .catch(
          (err) => console.log('error: ')
          // dispatch({
          // 	type: GET_ERRORS,
          // 	payload: err.response
          // })
        );

      this.setState({
        email1: '',
        password1: '',
      });
    }
  };

  myChangeHandler = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  onSubmitHandler = (event) => {
    event.preventDefault();
    const cookie = new Cookies();

    cookie.remove('myHide');

    const loginUser = {
      email: this.state.email1,
      password: this.state.password1,
    };
    //console.log("loginUser",loginUser);

    axios
      .post(POSTURL + 'user_login', loginUser)
      .then((res) => {
        //console.log("user_login success ",res);
        const cookie = new Cookies();
        cookie.set('myCat', res.data.data);
        cookie.set('myCat_new', res.data.data);

        //console.log(cookie.get('myCat'));
        //console.log(cookie.get('myCat_new'));

        if (res.data.status === 1) {
          if (res.data.data[0].role === 'main_admin') {
            //console.log("inside main_admin");

            window.location.href = '/admin_panel';
          } else {
            //console.log("inside elsee");

            window.location.href = '/properties';
          }
        } else {
          // alert("Invalid username and password");
          setTimeout(
            function () {
              this.setState({
                invalid_pass: 'Invalid username and password',
              });
            }.bind(this),
            3000
          );
        }
      }) // re-direct to dashboard after successful profile update
      .catch(
        (err) => console.log('error: ')
        // dispatch({
        // 	type: GET_ERRORS,
        // 	payload: err.response
        // })
      );

    this.setState({
      email1: '',
      password1: '',
    });
  };

  onChangeHandler = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  toggleCollapse = () => {
    this.setState({ isOpen1: !this.state.isOpen1 });
  };

  onForgot = (event, data) => {
    event.preventDefault();
    this.setState({
      inital_data: false,
      onSubmitHandler: false,
      inital_stage: false,
      showButton: false,
      ShowSubmit: true,
      second_data: true,
      login_data: false,
      res_pas_data: '',
      message: '',
      invalid_pass: '',
    });
  };

  Submit = (event, data) => {
    this.setState({
      res_pas_data: '',
    });
    event.preventDefault();
    const forgot_User = {
      email: this.state.email1,
    };
    //console.log("forgot_User",forgot_User);
    axios
      .post(POSTURL + 'forget_password', forgot_User)
      .then((res) => {
        //console.log("forgot password success ",res);
        if (res.data.status === 1) {
          setTimeout(
            function () {
              this.setState({
                res_pas_data: 'Email sent successfully',
              });
            }.bind(this),
            1000
          );

          let link = window.location.href.split('?');
          //console.log("linking",link);
          // alert("Mail sent successfully!!")
          if (link.length === 3) {
            this.setState({
              reset_pas: true,
              second_data: false,
              login_data: false,
            });
          }
        } else {
          // alert("email id not found");
          this.setState({
            res_pas_data: "Email doesn't exist",
          });
          setTimeout(
            function () {
              this.setState({
                res_pas_data: ' ',
              });
            }.bind(this),
            3000
          );

          this.setState({
            login_data: false,
          });
        }
      })
      .catch((err) => console.log('error: '));
    this.setState({
      email1: '',
    });
  };

  SaveHandler = (event) => {
    event.preventDefault();
    let link = window.location.href.split('=');
    const reset_User_pass = {
      newpassword: this.state.newpassword,
      confirmpassword: this.state.confirmpassword,
      id: link[2],
    };

    if (this.state.newpassword === this.state.confirmpassword) {
      axios
        .post(POSTURL + 'reset_password_home', reset_User_pass)
        .then((res) => {
          if (res.data.status) {
            setInterval(
              function () {
                this.setState({
                  message: 'Password reset successful!',
                  show_login_page: true,
                  login_data: true,
                  register_now: false,
                });
              }.bind(this),
              2000
            );
          }
        })
        .catch((err) => console.log('error: '));
      this.setState({
        newpassword: '',
        confirmpassword: '',
        login_data: true,
        reset_pas: false,
      });
    } else {
      alert('new Password and confirm Password are not matched');
    }
  };

  loginClick = (event) => {
    event.preventDefault();
    this.setState({
      login_data: true,
      inital_data: true,
      register_now: false,
      inital_stage: true,
      showButton: true,
      reset_pas: false,
    });
  };

  onReg = (event) => {
    event.preventDefault();
    this.setState({
      register_now: true,
      login_data: false,
      // inital_data:true,
      second_data: false,
    });
  };

  onReg1 = (event) => {
    event.preventDefault();
    this.setState({
      register_now: true,
      login_data: false,
      second_data: false,
      res_pas_data: '',
    });
  };
  onlogg = (event) => {
    event.preventDefault();
    this.setState({
      register_now: false,
      login_data: true,
      second_data: false,
      inital_data: true,
      showButton: true,
      inital_stage: true,
    });
  };
  class_apply = (event, data) => {
    //console.log("data",data);
    this.setState({
      type: data,
    });
    //console.log("this.state.type",this.state.type);
  };

  accept_invitation = (event) => {
    //console.log("accept_invitation");
    let link = window.location.href.split('?');
    // };
    //console.log("linking",link);

    if (link.length === 3) {
      if (link[1] === 'SID=') {
        let invite_id = {
          invite_id: link[2],
        };
        axios
          .post(POSTURL + 'addCompanyToProfile', invite_id)
          .then((res) => {
            //console.log("addCompanyToProfile success ",res);
            let css = res.data;
            if (css.status === 1) {
              this.setState({
                modal1: false,
              });
            }
          })
          .catch((err) => console.log('error: '));
      }
    }
  };

  register_now = (event) => {
    window.location.href = '/';
  };
  toggleCollapse = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  render() {
    return (
      <div className='animation1'>
        <div>
          <div className='homebg' style={{ height: '700px' }}>
            <MDBContainer className='d-flex uppernav' fluid>
              <MDBContainer className='d-flex uppernav_inner'>
                <MDBCol md='6'>
                  <MDBContainer className='text-left icongroupres'>
                    <img
                      alt=''
                      src='../../img/social-facebook-circular-button1.png'
                      style={{ height: '20px', marginRight: '10px' }}
                    />
                    <img
                      alt=''
                      src='../../img/twitter-circular-button1.png'
                      style={{ height: '20px', marginRight: '10px' }}
                    />
                    <img
                      alt=''
                      src='../../img/social-linkedin-circular-button1.png'
                      style={{ height: '20px', marginRight: '10px' }}
                    />
                  </MDBContainer>
                </MDBCol>
                <MDBCol md='6' className='d-flex call_mail'>
                  <div>
                    <MDBContainer>
                      <a href='mailto:info@myleaseremainder.com'>
                        <img
                          alt=''
                          src='../../img/mail.png'
                          style={{ paddingRight: '10px' }}
                        />
                        <span style={{ fontSize: '10px', color: '#fff' }}>
                          info@myleaseremainder.com
                        </span>
                      </a>
                    </MDBContainer>
                  </div>
                  {/* <div>
                         <MDBContainer>
                             <img alt="" src="../../img/call.png" style={{paddingRight:"10px"}} /><span style={{fontSize:"10px"}}>+1989 989 8989</span>
                         </MDBContainer>
                     </div> */}
                </MDBCol>
              </MDBContainer>
            </MDBContainer>
            <MDBContainer>
              <MDBContainer className='second_navcontainer'>
                <h5 className='float-left'>
                  <a href='https://www.myleasereminder.com'>
                    <img
                      alt=''
                      src='../../img/Logo.png'
                      className='img-fluid logo_mainnav'
                      style={{ height: '50px' }}
                    />
                  </a>
                </h5>

                <MDBNavbar
                  dark
                  expand='md'
                  className='justify-content-end second_nav'
                >
                  <MDBNavbarToggler onClick={this.toggleCollapse} />
                  <MDBCollapse
                    id='navbarCollapse3'
                    isOpen={this.state.isOpen}
                    navbar
                  >
                    <MDBNavbarNav className='hoverovernavmenu'>
                      <MDBNavItem style={{ padding: '0px 0px' }}>
                        <MDBNavLink to='/'>HOME</MDBNavLink>
                      </MDBNavItem>
                      <MDBNavItem style={{ padding: '0px 0px' }}>
                        <MDBNavLink className='activehomenavitem' to='/aboutus'>
                          ABOUT US
                        </MDBNavLink>
                      </MDBNavItem>
                      <MDBNavItem style={{ padding: '0px 0px' }}>
                        <MDBNavLink to='/pricing'>PRICING</MDBNavLink>
                      </MDBNavItem>
                      <MDBNavItem style={{ padding: '0px 0px' }}>
                        <MDBNavLink to='/contactus'>CONTACT US</MDBNavLink>
                      </MDBNavItem>
                      <MDBNavItem></MDBNavItem>
                    </MDBNavbarNav>
                  </MDBCollapse>
                  <div
                    style={{
                      borderLeft: '1px solid white',
                      paddingLeft: '30px',
                      marginTop: ' -13px',
                    }}
                  >
                    <a href='#!'>
                      <img
                        alt=''
                        src='../../img/login.png'
                        className='img-fluid'
                        onClick={this.loginClick}
                        style={{ width: ' 25px', height: ' 25px' }}
                      />
                    </a>
                    <span className='login_text' onClick={this.loginClick}>
                      Login
                    </span>
                  </div>
                </MDBNavbar>
              </MDBContainer>
              <MDBContainer className='float-left'>
                <MDBRow>
                  <MDBCol md='8' />

                  {this.state.register_now === true && <RegisterForm />}
                  {this.state.login_data === true && (
                    <LoginForm onReg={this.onReg} />
                  )}

                  {this.state.second_data === true && (
                    <MDBCol md='3'>
                      <div
                        className='formdiv11 logindivresponsive'
                        style={{
                          backgroundColor: 'rgba(23,67,88,0.5)',
                          marginBottom: '30px',
                          padding: '2px 10px 60px 10px',
                          height: '576px',
                        }}
                      >
                        <div
                          style={{
                            color: 'white',
                            textAlign: 'center',
                            height: '58px',
                          }}
                        >
                          {this.state.res_pas_data}
                        </div>
                        <div>
                          <h5
                            className='text-left login_head'
                            style={{ paddingTop: '40px' }}
                          >
                            Forgot Password
                          </h5>
                          <div className='form_div'>
                            <label
                              htmlFor='defaultFormCardNameEx'
                              className=' font-weight-light float-left'
                              style={{ fontWeight: '400' }}
                            >
                              Email Address
                            </label>
                            <input
                              type='email'
                              id='email1'
                              name={this.state.email1}
                              className='form-control'
                              required={true}
                              value={this.state.email1}
                              onChange={this.onChangeHandler}
                            />
                          </div>

                          <div className='form_div' style={{ height: '20px' }}>
                            <p
                              onClick={(event) => this.onReg1(event)}
                              style={{ cursor: 'pointer' }}
                            >
                              {' '}
                              Register Now ?{' '}
                            </p>
                          </div>
                          <div className='form_div' style={{ height: '20px' }}>
                            <p
                              onClick={(event) => this.onlogg(event)}
                              style={{ cursor: 'pointer' }}
                            >
                              {' '}
                              Login Now ?{' '}
                            </p>
                          </div>
                          <MDBBtn
                            rounded
                            color='success'
                            className='roundcorner register_mainpage'
                            type='submit'
                            onClick={this.Submit}
                            style={{
                              padding: '10px 32px',
                              textTransform: 'none',
                            }}
                          >
                            Submit
                            <img
                              alt=''
                              src='../../img/Group 10.png'
                              style={{ paddingLeft: '10px' }}
                            />
                          </MDBBtn>
                        </div>
                      </div>
                    </MDBCol>
                  )}

                  {this.state.reset_pas === true && (
                    <MDBCol md='3'>
                      <div>
                        <span style={{ color: 'white' }}>
                          {this.state.res_pas_data}
                        </span>

                        <div
                          className='formdiv1'
                          style={{ backgroundColor: 'rgba(23,67,88,0.5)' }}
                        >
                          <h4 className='text-left login_head'>
                            Reset Password
                          </h4>

                          <div className='form_div'>
                            <label
                              htmlFor='defaultFormCardNameEx'
                              className=' font-weight-light float-left'
                            >
                              New Password
                            </label>
                            <input
                              type='password'
                              id='newpassword'
                              name={this.state.newpassword}
                              className='form-control'
                              required={true}
                              value={this.state.newpassword}
                              onChange={this.onChangeHandler}
                              style={{ borderRadius: '0px' }}
                            />
                          </div>

                          <div className='form_div'>
                            <label
                              htmlFor='defaultFormCardNameEx'
                              className=' font-weight-light float-left'
                            >
                              Confirm Password
                            </label>
                            <input
                              type='password'
                              id='confirmpassword'
                              name={this.state.confirmpassword}
                              className='form-control'
                              required={true}
                              value={this.state.confirmpassword}
                              onChange={this.onChangeHandler}
                              style={{ borderRadius: '0px' }}
                            />
                          </div>
                          <div>
                            <MDBBtn
                              rounded
                              color='success'
                              className='roundcorner register_mainpage'
                              style={{
                                padding: '12px 45px',
                                textTransform: 'none',
                              }}
                              type='submit'
                              onClick={this.SaveHandler}
                            >
                              Save
                              <img
                                alt=''
                                src='../../img/Group 10.png'
                                style={{ paddingLeft: '10px' }}
                              />
                            </MDBBtn>
                          </div>
                        </div>
                      </div>
                    </MDBCol>
                  )}
                </MDBRow>
              </MDBContainer>
            </MDBContainer>
          </div>
        </div>
        <MDBContainer className='mt-70 aboutus_div'>
          <MDBRow>
            <MDBCol md='7' className='text-left'>
              <h1>
                Why pay a lot of money to keep track of all your properties when
                you dont have to?
              </h1>
              {/* <p className="font13 italicclass">For a very small monthly fee per store, you can</p> */}
              <p className='dec_aboutus'>
                For a very small monthly fee per store, you can...
              </p>
            </MDBCol>
            <MDBCol md='5' className=''>
              <img
                alt=''
                src='../../img/slide1@2x.png'
                style={{ width: '450px' }}
                className='aboutus_img1'
              />
            </MDBCol>
          </MDBRow>
          <MDBRow className='flag_div'>
            <MDBCol md='4' className=''>
              <div className='flag flag1 text-center'>
                <h5>Keep Track of</h5>
                <ul>
                  <li>Property Details</li>
                  <li>Important Dates</li>
                  <li>Owner and Landlord Info</li>
                  <li>Important Documents</li>
                </ul>
                <h5 style={{}}>
                  <img
                    alt=''
                    src='../../img/cursor@2x.png'
                    style={{ width: '80px' }}
                  />
                </h5>
              </div>
            </MDBCol>
            <MDBCol md='4' className=''>
              <div className='flag flag2 text-center'>
                <h5>Be Automtically Reminded of</h5>
                <ul>
                  <li>Upcoming Lease Renewals</li>
                  <li>Upcoming Lease Expirations</li>
                  <li>Upcoming Lease Increases</li>
                </ul>
                <h5 style={{ paddingTop: '20px' }}>
                  <img
                    alt=''
                    src='../../img/bell@2x.png'
                    style={{ width: '70px' }}
                  />
                </h5>
              </div>
            </MDBCol>
            <MDBCol md='4' className=''>
              <div className='flag flag3 text-center'>
                <h5>Easy Access to</h5>
                <ul>
                  <li>Utility Providers</li>
                  <li>Equipment and Warranties</li>
                  <li>Safe Combinations & Security Cameras</li>
                </ul>
                <h5 style={{}}>
                  <img
                    alt=''
                    src='../../img/phonebook@2x.png'
                    style={{ width: '60px' }}
                  />
                </h5>
              </div>
            </MDBCol>
          </MDBRow>
          <div className='give_itdiv'>
            <h2 className='text-center'>GIVE IT A TRY FOR FREE!</h2>
            <MDBBtn
              rounded
              color='success'
              className='roundcorner text-center'
              type='submit'
              style={{ textTransform: 'none' }}
              onClick={this.register_now}
            >
              Register Now
              <img
                alt=''
                src='../../img/Group 10.png'
                style={{ paddingLeft: '10px' }}
              />
            </MDBBtn>
          </div>
          <div className='aboutus_text'>
            <p>
              MLR helps businesses manage their lease location and sends
              upcoming lease renewal, lease expiration and other remainders!
            </p>
            {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
             Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
            , when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p> */}
          </div>
        </MDBContainer>
        <div className='aboutusblank_div'></div>
      </div>
    );
  }
}

export default Aboutus;
