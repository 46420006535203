import React, { Component } from 'react';
import {
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBModal,
  MDBModalBody,
} from 'mdbreact';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { POSTURL } from '../Navigation/Constant';
import ReactTooltip from 'react-tooltip';

const normalizeInput = (value, previousValue) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) return currentValue;
    if (cvLength < 7)
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  }
};

class Admin extends Component {
  constructor() {
    super();
    this.state = {
      activeItemClassicTabs1: '1',
      admin_user: [],
      fetch_invoice: [],
      suspended: '',
      isChecked: true,
      isChecked1: true,
      checked: false,
      indexToEditContact: -1,
      modal13: false,
      delete_id: '',
    };

    this.handleChange = this.handleChange.bind(this);
  }

  toggle = (nr, _id) => () => {
    //console.log("insode toggle ",nr);
    let modalNumber = 'modal' + nr;
    this.setState({
      [modalNumber]: !this.state[modalNumber],
      delete_id: _id,
    });
  };
  componentDidMount() {
    var cookie = new Cookies();
    if (cookie.get('myCat')) {
      var user_data = cookie.get('myCat');
      var id = {
        id: user_data[0].id,
      };

      if (user_data[0].role !== 'main_admin') {
        window.history.back();
      }
    }
    //console.log("insoeeee vnfdkgkjdfgdgfdkgjggfd");

    axios
      .post(POSTURL + 'admin_usermanagement', id)
      .then((res) => {
        //console.log("admin_usermanagement success ",res);
        var css = res.data.data;
        if (css) {
          this.setState({
            admin_user: css,
          });
        }
      }) // re-direct to dashboard after successful profile update
      .catch((err) => console.log('error: '));
  }

  delete_data = () => {
    var id = {
      id: this.state.delete_id,
    };
    axios
      .post(POSTURL + 'delete_user', id)
      .then((res) => {
        //console.log("delete_user success ",res);
        var status = res.data.status;
        if (status === 1) {
          let user_data;
          let cookie = new Cookies();
          if (cookie.get('myCat')) {
            user_data = cookie.get('myCat');
            //console.log("user_id",user_data[0].id);
          }
          let id = {
            id: user_data[0].id,
          };

          axios
            .post(POSTURL + 'admin_usermanagement', id)
            .then((res) => {
              //console.log("admin_usermanagement success ",res);
              var css = res.data.data;
              if (css) {
                this.setState({
                  admin_user: css,
                });
                this.setState({ modal13: false });
              }
            })
            .catch((err) => console.log('error: '));
        } else {
        }
      }) // re-direct to dashboard after successful profile update
      .catch((err) => console.log('error: '));
  };

  toggleClassicTabs1 = (tab) => () => {
    if (this.state.activeItemClassicTabs1 !== tab) {
      this.setState({
        activeItemClassicTabs2: tab,
      });
    }
  };

  onCheckedChange = (e) => {
    this.setState({
      isChecked1: !this.state.isChecked,
    });
  };
  handleChange(checked) {
    //console.log("checked",checked);
    this.setState({ checked });
  }

  myChangeHandler = (event) => {
    this.setState({ [event.target.id]: event.target.value });
    this.setState({
      message: '',
    });
  };

  myEditedPhnChangeHandler = ({ target: { value } }) => {
    this.setState((prevState) => ({
      ['mobile' + this.state.indexToEditContact]: normalizeInput(
        value,
        prevState['mobile' + this.state.indexToEditContact]
      ),
    }));
  };

  contactEdit = (event, data, i) => {
    if (
      this.state['email' + i] !== '' &&
      !this.validateEmail(this.state['email' + i])
    ) {
      alert('Please enter valid email address.');
    } else {
      var ProjectArr = [...this.state.admin_user];
      ProjectArr[i].username = this.state['username' + i];
      ProjectArr[i].email = this.state['email' + i];
      ProjectArr[i].mobile = this.state['mobile' + i];
      this.setState({ admin_user: ProjectArr, indexToEditContact: -1 });
    }

    const updateUser = {
      username: this.state['username' + i],
      email: this.state['email' + i],
      number: this.state['mobile' + i],
      _id: data._id,
    };
    //console.log("updateUser",updateUser);
    axios
      .post(POSTURL + 'superAdminEdit_user', updateUser)
      .then((res) => {
        var status = res.data.status;
        if (status === 1) {
          let user_data;
          var cookie = new Cookies();
          if (cookie.get('myCat')) {
            user_data = cookie.get('myCat');
          }
          let id = {
            id: user_data[0].id,
          };

          axios
            .post(POSTURL + 'admin_usermanagement', id)
            .then((res) => {
              //console.log("admin_usermanagement success ",res);
              var css = res.data.data;
              if (css) {
                this.setState({
                  admin_user: css,
                });
              }
            })
            .catch((err) => console.log('error: '));
        }
      })
      .catch((err) => console.log('error: '));
  };

  validateEmail(email) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  }

  redirect_to_admin_tab = (data) => {
    //console.log("redirect_to_admin_tab",data);
    this.props.history.push(`/admin_tab?${data}`);

    // window.location.href="admin_tab"
  };
  render() {
    // //console.log("month",this.state.month);
    // { (this.state.month.length > 0) &&
    //
    //
    //     //console.log("myArray1",this.state.month);
    //
    //    }
    //    const options = this.state.month;
    //    //console.log("options",options);
    return (
      <MDBContainer
        className='mt-70 propertiescontainer billcontainer adminpanel_cont'
        flex
        fluid
      >
        <MDBRow style={{ paddingBottom: '10px' }}>
          <MDBCol size='6'>
            <span className='float-left'>
              <h2 style={{ color: '#183650', paddingTop: '10px' }}>Users</h2>
            </span>
          </MDBCol>
          {/*<MDBCol size="6"><MDBBtn rounded outline color="warning" className="float-left">Users</MDBBtn></MDBCol>*/}
        </MDBRow>
        <MDBRow>
          <MDBCol size='12'>
            <MDBTable className='text-left tableusers' responsive>
              <MDBTableHead>
                <tr>
                  <th className='tablehead'>Sr No</th>
                  <th className='tablehead'>Full Name</th>
                  <th className='tablehead'>Email</th>
                  <th className='tablehead'>Phone Number</th>
                  <th className='tablehead'>Number of Properties</th>
                  <th className='tablehead'>Subscription</th>
                  <th className='tablehead'>Number of Users</th>
                  <th className='tablehead'>Actions</th>
                  {/*
     <th className="tablehead">Suspended</th>

   */}
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {this.state.admin_user.map((admin_user1, i) => {
                  if (this.state.indexToEditContact !== i) {
                    return (
                      <tr>
                        <td
                          onClick={(event) =>
                            this.redirect_to_admin_tab(admin_user1._id)
                          }
                        >
                          {i + 1}
                        </td>
                        <td
                          onClick={(event) =>
                            this.redirect_to_admin_tab(admin_user1._id)
                          }
                        >
                          {admin_user1.username}
                        </td>
                        <td
                          onClick={(event) =>
                            this.redirect_to_admin_tab(admin_user1._id)
                          }
                        >
                          {admin_user1.email}
                        </td>
                        <td
                          onClick={(event) =>
                            this.redirect_to_admin_tab(admin_user1._id)
                          }
                        >
                          {admin_user1.mobile}
                        </td>
                        <td
                          onClick={(event) =>
                            this.redirect_to_admin_tab(admin_user1._id)
                          }
                        >
                          {admin_user1.properties}
                        </td>
                        <td
                          onClick={(event) =>
                            this.redirect_to_admin_tab(admin_user1._id)
                          }
                        >
                          {admin_user1.subscription}
                        </td>
                        <td
                          onClick={(event) =>
                            this.redirect_to_admin_tab(admin_user1._id)
                          }
                        >
                          {admin_user1.total_users}
                        </td>
                        <td className='d-flex'>
                          <span
                            className='d-inline'
                            outline
                            color='dark'
                            style={{
                              padding: '5px',
                              borderRadius: '5px',
                              marginRight: '5px',
                              border: '0.5px solid lightgrey',
                              cursor: 'pointer',
                            }}
                          >
                            <img
                              src='../../img/document2.png'
                              alt=''
                              data-tip='Edit'
                              style={{
                                height: '20px',
                                marginTop: '0px',
                                width: '20px',
                              }}
                              onClick={(event) => {
                                this.setState({
                                  indexToEditContact: i,
                                  ['username' + i]: admin_user1.username,
                                  ['email' + i]: admin_user1.email,
                                  ['mobile' + i]: admin_user1.mobile,
                                });
                              }}
                            />
                            <ReactTooltip />
                          </span>
                          <span
                            className='d-inline'
                            outline
                            color='dark'
                            style={{
                              padding: '5px',
                              marginRight: '5px',
                              borderRadius: '5px',
                              border: '0.5px solid lightgrey',
                              cursor: 'pointer',
                            }}
                          >
                            <img
                              src='../../img/bin.png'
                              alt=''
                              data-tip='Delete'
                              onClick={this.toggle(13, admin_user1._id)}
                              style={{
                                height: '20px',
                                marginTop: '0px',
                                width: '20px',
                              }}
                            />
                            <ReactTooltip />
                          </span>
                        </td>
                        {/*  <td>
<div className="example">
       <h2>Simple usage</h2>
       <label>
         <span>Switch with default style</span>
         <Switch
          id = "suspended"
           onChange={this.handleChange}
           checked={this.state.checked}
           className="react-switch"
         />
       </label>
       <p>The switch is <span>{this.state.checked ? 'on' : 'off'}</span>.</p>
     </div>
  <div className='custom-control custom-switch'>
      <input
        type='checkbox'
        className='custom-control-input'
        id='customSwitches'
        value="true"
        defaultChecked={this.state.suspended}
        onChange={this.onCheckedChange}



      />
      <label className='custom-control-label' htmlFor='customSwitches' style={{color:"white"}}>
      .
      </label>*/}
                        {/*

        <label>
      <Switch
       onChange={this.handleChange}
            checked={this.state.checked}
            className="react-switch" />
            </label>
    <p>The switch is <span>{this.state.checked ? 'on' : 'off'}</span>.</p>
    </div>
  </td>
    */}
                      </tr>
                    );
                  } else {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>
                          <div className='form-group'>
                            <input
                              type='text'
                              className='form-control'
                              id={'username' + i}
                              value={this.state['username' + i]}
                              onChange={this.myChangeHandler}
                              placeholder='Name'
                            />
                          </div>
                        </td>
                        <td>
                          <div className='form-group'>
                            <input
                              type='email'
                              className='form-control'
                              id={'email' + i}
                              value={this.state['email' + i]}
                              onChange={this.myChangeHandler}
                              placeholder='email'
                            />
                          </div>
                        </td>
                        <td>
                          <div className='form-group'>
                            <input
                              type='text'
                              className='form-control'
                              id={'mobile' + i}
                              name='mobile'
                              value={this.state['mobile' + i]}
                              onChange={this.myEditedPhnChangeHandler}
                              placeholder='Mobile'
                            />
                          </div>
                          {this.state.error && (
                            <p className='error'>{this.state.error}</p>
                          )}
                        </td>
                        <td>{admin_user1.properties}</td>
                        <td>{admin_user1.subscription}</td>
                        <td>{admin_user1.total_users}</td>

                        <td>
                          <span>
                            <MDBBtn
                              color='success'
                              className='roundcorner save_editbutton'
                              type='submit'
                              onClick={(event) =>
                                this.contactEdit(event, admin_user1, i)
                              }
                            >
                              Save
                            </MDBBtn>
                          </span>
                          <span>
                            <MDBBtn
                              color='success'
                              className='roundcorner save_editbutton'
                              type='submit'
                              onClick={(event) => {
                                this.setState({
                                  indexToEditContact: -1,
                                  ['i' + 1]: i + 1,
                                  ['username' + i]: admin_user1.username,
                                  ['email' + i]: admin_user1.email,
                                  ['mobile' + i]: admin_user1.mobile,
                                });
                              }}
                            >
                              Cancel
                            </MDBBtn>
                          </span>
                          {/*  <span>
         <MDBTooltip
           domElement
           tag="span"
           placement="top"
         >
         <MDBIcon style={{marginTop: 10}} far icon="check-circle mr-3" onClick={event => this.contactEdit(event, accMang, i)}/></span>
         <span>Save</span>    </MDBTooltip>

         <MDBTooltip
            domElement
            tag="span"
            placement="top"
          >
           <span> <MDBIcon style={{marginTop: 10}} far icon="times-circle" onClick={event => {
             this.setState({
               indexToEditContact: -1,
               ["i"+1]:i,
               ["username" + i]: accMang.username,
               ["email" + i]: accMang.email,
               ["mobile" + i]: accMang.mobile,
               ["accessctrl" + i]: accMang.accessctrl,
             })
           }} /></span>
           <span>Close</span>
         </MDBTooltip>*/}
                        </td>
                      </tr>
                    );
                  }
                })}
              </MDBTableBody>
            </MDBTable>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBModal
            isOpen={this.state.modal13}
            toggle={this.toggle(13)}
            size='sm'
            backdrop={false}
          >
            {/*

  <MDBModalHeader toggle={this.toggle(12)}></MDBModalHeader>
*/}
            <MDBModalBody>
              <MDBRow>Are you sure you want to delete this user?</MDBRow>
              <MDBRow className='justify-content-center'>
                <MDBBtn
                  color='success'
                  className='btndelprop_yes'
                  onClick={(event) => this.delete_data()}
                >
                  Yes
                </MDBBtn>
                <MDBBtn
                  color='danger'
                  className='btndelprop_no'
                  onClick={this.toggle(13)}
                >
                  NO
                </MDBBtn>
              </MDBRow>
            </MDBModalBody>
          </MDBModal>
        </MDBRow>
        <div className=''></div>
      </MDBContainer>
    );
  }
}

export default Admin;
