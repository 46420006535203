import React from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { MDBBtn, MDBCol, MDBRow } from 'mdbreact';

import { changePassword } from '../../api/user';

const ChangePassword = () => {
  const form = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    onSubmit: async (values) => {
      const { oldPassword, newPassword, confirmPassword } = values;
      if (newPassword !== confirmPassword) {
        toast.error('New password and confirm password must be the same');
      } else if (newPassword.length < 8) {
        toast.error('too_short');
      } else if (newPassword.length > 50) {
        toast.error('too_long');
      } else if (newPassword.search(/\d/) === -1) {
        toast.error(
          'Password must have at least one capital letter, one number and one special character'
        );
      } else if (newPassword.search(/[a-zA-Z]/) === -1) {
        toast.error(
          'Password must have at least one capital letter, one number and one special character'
        );
      } else if (newPassword.search(/[`!@#$%^&*_+\-=;:\\|,.?~]/) === -1) {
        toast.error(
          'Password must have at least one capital letter, one number and one special character'
        );
      } else {
        try {
          await changePassword({
            oldPassword,
            newPassword,
            userId: localStorage.getItem('user'),
          });
          toast.success('Password successfully change');
        } catch {
          toast.error('Password Incorrect');
        }
      }
    },
  });

  return (
    <MDBRow
      className='passsettings_div'
      style={{ paddingTop: '40px', paddingLeft: '40px' }}
    >
      <MDBCol md='3'>
        <form
          style={{ textAlign: 'left', width: '260px' }}
          onSubmit={form.handleSubmit}
        >
          <h4
            className='mb-4'
            style={{ color: '#183650', fontWeight: '500', zIndex: '-1' }}
          >
            Change Password
          </h4>
          <label className='grey-text float-left' style={{ fontSize: '12px' }}>
            Old Password
          </label>
          <input
            type='password'
            name='oldPassword'
            className='form-control'
            required={true}
            value={form.values.oldPassword}
            onChange={form.handleChange}
          />
          <br />
          <label
            htmlFor='defaultFormRegisterEmailEx'
            className='grey-text float-left'
            style={{ fontSize: '12px' }}
          >
            New Password
          </label>
          <input
            type='password'
            name='newPassword'
            className='form-control'
            required={true}
            value={form.values.newPassword}
            onChange={form.handleChange}
          />
          <br />
          <label
            htmlFor='defaultFormRegisterConfirmEx'
            className='grey-text float-left'
            style={{ fontSize: '12px' }}
          >
            Confirm Password
          </label>
          <input
            type='password'
            name='confirmPassword'
            className='form-control'
            required={true}
            value={form.values.confirmPassword}
            onChange={form.handleChange}
          />
          <div className='text-center mt-4'>
            <MDBBtn
              color='success'
              type='submit'
              className='roundcorner savepass'
            >
              Save
            </MDBBtn>
          </div>
        </form>
      </MDBCol>
    </MDBRow>
  );
};

export default ChangePassword;
