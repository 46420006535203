import React, { useContext, useEffect } from 'react';

import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import './App.css';

import CookieConsent from 'react-cookie-consent';
import Error from './Navigation/404';

import Home1 from './Navigation/Home1';
import Admin_panel from './Navigation/Admin';
import Admin_tab from './Navigation/Admintab';
import ScrollToTop from './Navigation/scrollToTop';

import Users from './Navigation/Users';
import Contactus from './Navigation/Contactus';
import Aboutus from './Navigation/About_us';
import Pricing from './Navigation/Pricing';
import Contenthome from './Navigation/Contenthome';
import Setting from './Navigation/Setting';
import HOC from './Navigation/HOC';
import Footer from './Navigation/Footer';

import PropertyCreateEdit from './screens/properties/createEdit';
import OwnerCreateEdit from './screens/properties/owner';
import LandlordCreateEdit from './screens/properties/landlord';
import NotesCreateEdit from './screens/properties/notes';
import UtilitiesCreateEdit from './screens/properties/utilities';
import SecuritiesCreateEdit from './screens/properties/security';
import EquipmentCreateEdit from './screens/properties/equipment';
import PropertiesDetails from './screens/properties/details';
import DocumentsCreateEdit from './screens/properties/documents';
import PRO from './screens/properties/properties';
import BillPage from './screens/bill/bill';
import ConfirmUser from './screens/landing/confirmUser';
import ForgotPassword from './screens/landing/forgotPassword';

import './Navigation/css/Hori.css';
import './Navigation/css/Homepagecss.css';
import './Navigation/css/Responsive.css';
import './Navigation/css/stylecss.css';
import './Navigation/css/Steeper.css';
import './Navigation/css/Propertiescss.css';
import './Navigation/css/Step.css';
import './Navigation/css/Popup.css';
import 'react-phone-number-input/style.css';

import { updateUserExpiration } from './api/user';
import { PrivateRoute, Navigation } from './components';
import { SessionContext } from './context/sessionContext';

const App = () => {
  const { userId, userData, setUserData } = useContext(SessionContext);

  const update = async () => {
    const data = await updateUserExpiration({
      id: userData._id,
      workFor: !!userData.workFor ? userData.workFor : null,
    });
    setUserData({
      ...userData,
      subscriptionExpiryDate: data.subscriptionExpiryDate,
    });
  };

  useEffect(() => {
    !!userId && !!userData?._id && update();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Router>
      <ScrollToTop />
      <div className='App'>
        <CookieConsent>
          This website uses cookies to enhance the user experience.
        </CookieConsent>

        <Route
          exact
          path='/confirmUser/:code/:username'
          component={ConfirmUser}
        />

        {userId && (
          <div className='App2'>
            <HOC />
            <Navigation />

            <Route exact path='/'>
              {userData?.subscription === 'free' ? (
                <Redirect to='/bill' />
              ) : (
                <Redirect to='/properties' />
              )}
            </Route>

            <PrivateRoute
              exact
              path='/property/:id'
              component={PropertyCreateEdit}
            />
            <PrivateRoute exact path='/owner/:id' component={OwnerCreateEdit} />
            <PrivateRoute
              exact
              path='/landlord/:id'
              component={LandlordCreateEdit}
            />
            <PrivateRoute exact path='/notes/:id' component={NotesCreateEdit} />
            <PrivateRoute
              exact
              path='/utility/:id'
              component={UtilitiesCreateEdit}
            />
            <PrivateRoute
              exact
              path='/document/:id'
              component={DocumentsCreateEdit}
            />
            <Route exact path='/bill' component={BillPage} />
            <PrivateRoute
              exact
              path='/propertyDetails/:id'
              component={PropertiesDetails}
            />
            <PrivateRoute
              exact
              path='/equipment/:id'
              component={EquipmentCreateEdit}
            />
            <PrivateRoute
              exact
              path='/security/:id'
              component={SecuritiesCreateEdit}
            />

            <PrivateRoute exact path='/properties' component={PRO} />
            <PrivateRoute exact path='/users' component={Users} />
            <PrivateRoute exact path='/setting' component={Setting} />
            <PrivateRoute exact path='/admin_panel' component={Admin_panel} />
            <PrivateRoute exact path='/admin_tab' component={Admin_tab} />
          </div>
        )}

        {!userId && (
          <>
            <Route exact path='/' component={Home1} />
            <Route exact path='/forgotPassword' component={ForgotPassword} />
            <Route exact path='/home1' component={Contenthome} />
            <Route exact path='/aboutus' component={Aboutus} />
            <Route exact path='/pricing' component={Pricing} />
            <Route exact path='/contactus' component={Contactus} />
            <Route exact path='/bill'>
              <Redirect to='/#login' />
            </Route>
          </>
        )}

        <Route exact path='/404' component={Error} />

        <CookieConsent>
          This website uses cookies to enhance the user experience.
        </CookieConsent>
      </div>

      {!userId && <Footer />}
    </Router>
  );
};

export default App;
