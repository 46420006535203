import React, { useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { FormButtonContainer, FormContainer, FormInput } from './styles';
import Button from '../button';

const SecurityForm = ({ addSecurity, onSave, loading }) => {
  const [security, setSecurity] = useState({
    item: '',
    location: '',
    username: '',
    password: '',
    secondaryPassword: '',
  });

  const handleChange = (e) =>
    setSecurity({ ...security, [e.target.name]: e.target.value });

  return (
    <>
      <FormContainer container spacing={3}>
        <FormContainer item xs={12} md={3}>
          <FormInput
            name='item'
            variant='outlined'
            label='Security Item'
            value={security.item}
            onChange={handleChange}
          />
        </FormContainer>
        <FormContainer item xs={12} md={3}>
          <FormInput
            name='location'
            variant='outlined'
            label='Location'
            value={security.location}
            onChange={handleChange}
          />
        </FormContainer>
        <FormContainer item xs={12} md={3}>
          <FormInput
            name='username'
            variant='outlined'
            label='Username'
            value={security.username}
            onChange={handleChange}
          />
        </FormContainer>
        <FormContainer item xs={12} md={3}>
          <FormInput
            name='password'
            variant='outlined'
            label='Password/Security Code'
            value={security.password}
            onChange={handleChange}
          />
        </FormContainer>
        <FormContainer item xs={12} md={3}>
          <FormInput
            name='secondaryPassword'
            variant='outlined'
            label='Secondary Code'
            value={security.secondaryPassword}
            onChange={handleChange}
          />
        </FormContainer>
      </FormContainer>
      <FormButtonContainer>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <div>
              <Button variant='contained' color='secondary'>
                Cancel
              </Button>
              <Button
                variant='contained'
                custom='success'
                onClick={() => {
                  addSecurity(security);
                  setSecurity({
                    item: '',
                    location: '',
                    username: '',
                    password: '',
                    secondaryPassword: '',
                  });
                }}
                endIcon={<AddCircleOutlineIcon />}
              >
                Add
              </Button>
            </div>
            <Button
              variant='contained'
              type='submit'
              custom='success'
              onClick={onSave}
              endIcon={<ArrowForwardIosIcon />}
            >
              Save and Continue
            </Button>
          </>
        )}
      </FormButtonContainer>
    </>
  );
};
export default SecurityForm;
