import React, { Component } from 'react';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBTabPane,
  MDBTabContent,
} from 'mdbreact';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { POSTURL } from '../Navigation/Constant';

import { ChangePasswordForm, ProfileForm } from '../components';

const normalizeInput = (value, previousValue) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) return currentValue;
    if (cvLength < 7)
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  }
};

class Setting extends Component {
  constructor() {
    super();
    this.state = {
      oldpassword: '',
      newpassword: '',
      confirmpassword: '',
      message: '',
      message11: '',
      onChangePass: true,
      onProfile: false,
      compelted1: true,
      activeItemClassicTabs1: '1',
      role: '',
      number: '',
    };
  }

  componentDidMount() {
    const cookie = new Cookies();
    var user_id1 = cookie.get('myCat');

    if (user_id1) {
      this.setState({
        role: user_id1[0].role,
      });
    }
  }

  myphnChangeHandler = ({ target: { value } }) => {
    //console.log("value",value);
    this.setState((prevState) => ({
      number: normalizeInput(value, prevState.number),
    }));
  };

  toggleClassicTabs1 = (tab) => () => {
    this.setState({
      message: '',
    });
    if (this.state.activeItemClassicTabs1 !== tab) {
      this.setState({
        activeItemClassicTabs1: tab,
      });
    }
    if (tab === 1) {
      this.setState({
        compelted1: true,
        compelted2: false,
      });

      this.setState({
        onChangePass: true,
        onProfile: false,
      });
    }

    if (tab === 2) {
      this.setState({
        compelted1: false,
        compelted2: true,
      });

      this.setState({
        onChangePass: false,
        onProfile: true,
      });

      const cookie = new Cookies();

      axios
        .post(POSTURL + 'fetch_single_user', { user_id: cookie.get('myCat') })
        .then((res) => {
          var data = res.data.data;

          this.setState({
            _id: data._id,
            username: data.username,
            email: data.email,
            number: data.mobile,
            companyName: data.companyName,
          });
        })
        .catch((err) => console.log('error: '));
    }
  };

  myChangeHandler = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  render() {
    return (
      <MDBContainer className='mt-70 usercontainer' flex fluid>
        <MDBRow style={{ paddingBottom: '10px' }}>
          <MDBCol size='6'>
            <span className='float-left'>
              <h2
                style={{
                  color: '#183650',
                  fontWeight: '400',
                  paddingTop: '10px',
                }}
              >
                Setting
              </h2>
            </span>
          </MDBCol>
        </MDBRow>

        <div>
          <MDBNav
            tabs
            className='bgcolordark usertab '
            style={{ color: 'white', fontSize: '15px' }}
          >
            <MDBNavItem>
              <MDBNavLink
                to='#!'
                className=''
                active={this.state.activeItemClassicTabs1 === '1'}
                style={{ zIndex: '-1' }}
              ></MDBNavLink>
            </MDBNavItem>

            <MDBNavItem>
              <MDBNavLink
                to='#!'
                className={
                  this.state.compelted1 ? 'background_color_active' : ''
                }
                active={this.state.activeItemClassicTabs1 === '1'}
                onClick={this.toggleClassicTabs1('1')}
              >
                Change Password
              </MDBNavLink>
            </MDBNavItem>

            <MDBNavItem>
              <MDBNavLink
                to='#!'
                className={
                  this.state.compelted2 ? 'background_color_active' : ''
                }
                active={this.state.activeItemClassicTabs1 === '2'}
                onClick={this.toggleClassicTabs1('2')}
              >
                Profile
              </MDBNavLink>
            </MDBNavItem>
          </MDBNav>
          <MDBTabContent activeItem={this.state.activeItemClassicTabs1}>
            <MDBTabPane tabId='1'>
              <div className='animation'>
                {this.state.activeItemClassicTabs1 === '1' && (
                  <ChangePasswordForm />
                )}
              </div>
            </MDBTabPane>

            <MDBTabPane tabId='2'>
              <div className='animation'>
                {this.state.activeItemClassicTabs1 === '2' && <ProfileForm />}
              </div>
            </MDBTabPane>
          </MDBTabContent>
        </div>

        <div className='blank_div_settings'></div>
      </MDBContainer>
    );
  }
}

export default Setting;
