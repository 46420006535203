import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { MDBRow, MDBCol, MDBBtn } from 'mdbreact';
import { toast } from 'react-toastify';

import { findUser, updateUser } from '../../api/user';

const Profile = () => {
  const form = useFormik({
    initialValues: {
      username: '',
      email: '',
      mobile: '',
    },
    onSubmit: async (values) => {
      try {
        await updateUser(localStorage.getItem('user'), values);
        toast.success('User updated');
      } catch {
        toast.error('An error ocurred, please try again leather');
      }
    },
  });

  const findMe = async () => {
    const me = await findUser(localStorage.getItem('user'));
    form.setValues({
      username: me.username,
      email: me.email,
      mobile: me.mobile,
    });
  };

  useEffect(() => {
    findMe();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MDBRow
      className='profilesettings_div'
      style={{ paddingTop: '40px', paddingLeft: '40px' }}
    >
      <MDBCol md='3'>
        <form
          style={{ textAlign: 'left', width: '260px' }}
          onSubmit={form.handleSubmit}
        >
          <h4
            className='mb-4'
            style={{
              color: '#183650',
              fontWeight: '500',
              zIndex: '-1',
            }}
          >
            Profile
          </h4>
          <label
            htmlFor='defaultFormRegisterNameEx'
            className='grey-text float-left'
            style={{ fontSize: '12px' }}
          >
            Full Name
          </label>
          <input
            type='text'
            name='username'
            className='form-control'
            required={true}
            value={form.values.username}
            onChange={form.handleChange}
          />
          <br />

          <label
            htmlFor='defaultFormRegisterConfirmEx'
            className='grey-text float-left'
            style={{ fontSize: '12px' }}
          >
            Email Address
          </label>
          <input
            type='text'
            name='email'
            className='form-control'
            required={true}
            disabled={true}
            value={form.values.email}
            onChange={form.handleChange}
          />
          <br />
          <label
            htmlFor='defaultFormRegisterConfirmEx'
            className='grey-text float-left'
            style={{ fontSize: '12px' }}
          >
            Phone Number
          </label>
          <input
            type='text'
            name='mobile'
            className='form-control'
            value={form.values.mobile}
            onChange={form.handleChange}
          />

          <div className='text-center mt-4'>
            <MDBBtn
              color='success'
              type='submit'
              className='roundcorner savesett'
            >
              Save
            </MDBBtn>
          </div>
        </form>
      </MDBCol>
    </MDBRow>
  );
};

export default Profile;
