import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const UtilitiesTable = ({ utilities }) => (
  <TableContainer component={Paper}>
    <Table aria-label='simple table'>
      <TableHead>
        <TableRow>
          <TableCell>#</TableCell>
          <TableCell>Utility</TableCell>
          <TableCell>Company Name</TableCell>
          <TableCell>Phone</TableCell>
          <TableCell>Account</TableCell>
          <TableCell>PIN</TableCell>
          <TableCell>UserName</TableCell>
          <TableCell>Password</TableCell>
          <TableCell>Note</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {utilities?.map((utility, index) => (
          <TableRow key={index}>
            <TableCell>{index + 1}</TableCell>
            <TableCell>{utility.name}</TableCell>
            <TableCell>{utility.companyName}</TableCell>
            <TableCell>{utility.phone}</TableCell>
            <TableCell>{utility.account}</TableCell>
            <TableCell>{utility.pin}</TableCell>
            <TableCell>{utility.username}</TableCell>
            <TableCell>{utility.password}</TableCell>
            <TableCell>{utility.note}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default UtilitiesTable;
