import React, { useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { FormButtonContainer, FormContainer, FormInput } from './styles';
import Button from '../button';

const EquipmentForm = ({ addEquipment, onSave, loading }) => {
  const [equipment, setEquipment] = useState({
    item: '',
    purchaseDate: '',
    from: '',
    model: '',
    serial: '',
    invoice: '',
    expirationDate: '',
    note: '',
  });

  const handleChange = (e) =>
    setEquipment({ ...equipment, [e.target.name]: e.target.value });

  return (
    <>
      <FormContainer container spacing={3}>
        <FormContainer item xs={12} md={3}>
          <FormInput
            name='item'
            variant='outlined'
            label='Item Description'
            value={equipment.item}
            onChange={handleChange}
          />
        </FormContainer>
        <FormContainer item xs={12} md={3}>
          <FormInput
            name='purchaseDate'
            variant='outlined'
            label='Purchase Date'
            type='date'
            InputLabelProps={{
              shrink: true,
            }}
            value={equipment.purchaseDate}
            onChange={handleChange}
          />
        </FormContainer>
        <FormContainer item xs={12} md={3}>
          <FormInput
            name='model'
            variant='outlined'
            label='Model #'
            value={equipment.model}
            onChange={handleChange}
          />
        </FormContainer>
        <FormContainer item xs={12} md={3}>
          <FormInput
            name='invoice'
            variant='outlined'
            label='Invoice/Receipt#'
            value={equipment.invoice}
            onChange={handleChange}
          />
        </FormContainer>
        <FormContainer item xs={12} md={3}>
          <FormInput
            name='from'
            variant='outlined'
            label='Purchase From'
            value={equipment.from}
            onChange={handleChange}
          />
        </FormContainer>
        <FormContainer item xs={12} md={3}>
          <FormInput
            name='serial'
            variant='outlined'
            label='Serial #'
            value={equipment.serial}
            onChange={handleChange}
          />
        </FormContainer>
        <FormContainer item xs={12} md={3}>
          <FormInput
            name='expirationDate'
            variant='outlined'
            label='Equipment Exp. Date'
            type='date'
            InputLabelProps={{
              shrink: true,
            }}
            value={equipment.expirationDate}
            onChange={handleChange}
          />
        </FormContainer>
        <FormContainer item xs={12} md={3}>
          <FormInput
            name='note'
            variant='outlined'
            label='Note'
            value={equipment.note}
            onChange={handleChange}
          />
        </FormContainer>
      </FormContainer>
      <FormButtonContainer>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <div>
              <Button variant='contained' color='secondary'>
                Cancel
              </Button>
              <Button
                variant='contained'
                custom='success'
                onClick={() => {
                  addEquipment(equipment);
                  setEquipment({
                    item: '',
                    purchaseDate: '',
                    from: '',
                    model: '',
                    serial: '',
                    invoice: '',
                    expirationDate: '',
                    note: '',
                  });
                }}
                endIcon={<AddCircleOutlineIcon />}
              >
                Add
              </Button>
            </div>
            <Button
              variant='contained'
              type='submit'
              custom='success'
              onClick={onSave}
              endIcon={<ArrowForwardIosIcon />}
            >
              Save and Continue
            </Button>
          </>
        )}
      </FormButtonContainer>
    </>
  );
};
export default EquipmentForm;
