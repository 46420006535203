import React, { useContext, useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';

import { findWorker, deleteUser } from '../../api/user';
import { SessionContext } from '../../context/sessionContext';
import UpdateUserModal from '../modals/updateuser';

const UsersTable = () => {
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const { userData } = useContext(SessionContext);

  const getUsers = async () => {
    if (userData.role === 'owner') {
      const myUsers = await findWorker(userData._id);
      setUsers(myUsers);
    } else {
      const myUsers = await findWorker(userData.workFor);
      setUsers(myUsers);
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteUser(id);
      window.location.reload();
    } catch {
      toast.error('Could not delete user');
    }
  };

  const handleEdit = (user) => {
    setUser(user);
    setShowModal(true);
  };

  useEffect(() => {
    getUsers();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <UpdateUserModal
        isOpen={showModal}
        user={user}
        onClose={() => setShowModal(false)}
      />
      <MDBTable
        className='text-left tableusers'
        responsive
        style={{ zIndex: '-1' }}
      >
        <MDBTableHead>
          <tr>
            <th className='tablehead'>Sr No</th>
            <th className='tablehead'>Full Name</th>
            <th className='tablehead'>Email</th>
            <th className='tablehead'>Phone Number</th>
            <th className='tablehead'>Access Control</th>
            <th className='tablehead'>Actions</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody className='font12'>
          {users.map((user, i) => {
            return (
              <tr>
                <td>{i + 1}</td>
                <td>{user.username}</td>
                <td>{user.email}</td>
                <td>{user.mobile}</td>
                <td>{user.role}</td>

                <td>
                  {/*<span
                    className='d-inline emailrevbtn'
                    outline
                    color='dark'
                    style={{
                      border: '0.5px solid #747373',
                      padding: '5px',
                      marginRight: '4px',
                      borderRadius: '5px',
                      cursor: 'pointer',
                    }}
                  >
                    <MDBIcon
                      icon='fa fa-key'
                      data-tip='Email Management'
                      style={{
                        height: '16px',
                        marginTop: '0px',
                        width: '16px',
                      }}
                    />
                    <ReactTooltip />
                  </span>*/}

                  <span
                    className='d-inline  edit_user'
                    onClick={() => handleEdit(user)}
                    outline
                    color='dark'
                    style={{
                      border: '0.5px solid #747373',
                      padding: '5px',
                      marginRight: '4px',
                      borderRadius: '5px',
                      cursor: 'pointer',
                    }}
                  >
                    <img
                      alt=''
                      src='../../img/document2.png'
                      data-tip='Edit'
                      style={{
                        height: '16px',
                        marginTop: '0px',
                        width: '16px',
                      }}
                    />
                    <ReactTooltip />
                  </span>

                  <span
                    className='d-inline '
                    onClick={() => handleDelete(user._id)}
                    outline
                    color='dark'
                    style={{
                      border: '0.5px solid #747373',
                      padding: '6px',
                      marginRight: '0px',
                      borderRadius: '5px',
                      cursor: 'pointer',
                    }}
                  >
                    <img
                      alt=''
                      src='../../img/bin.png'
                      data-tip='Delete'
                      style={{
                        height: '16px',
                        marginTop: '0px',
                        width: '16px',
                      }}
                    />
                    <ReactTooltip />
                  </span>
                </td>
              </tr>
            );
          })}
        </MDBTableBody>
      </MDBTable>
    </>
  );
};

export default UsersTable;
