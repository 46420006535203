import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { useFormik } from 'formik';
import { Typography } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';

import { Container } from '../styles';
import { CreateEditPropertyForm, PropertyStepper } from '../../components';
import {
  createProperty,
  updateProperty,
  getPropertyById,
} from '../../api/property';
import { toast } from 'react-toastify';
import { SessionContext } from '../../context/sessionContext';

const PropertyCreateEdit = () => {
  const { id } = useParams();
  const { company } = useContext(SessionContext);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const form = useFormik({
    initialValues: {
      imageBase64: '',
      description: '',
      name: '',
      no: '',
      sqFt: 0,
      monthlyPay: 0,
      nnnCharges: 0,
      address1: '',
      address2: '',
      country: { isoCode: 'US', name: 'United States' },
      state: '',
      city: '',
      zipCode: '',
      leaseIncreaseOn: '',
      orgLeaseDate: '',
      orgTerminationDate: '',
      currentTerminationDate: '',
      increaseAmount: '',
      terms: '',
      termsAvailableYear: 0,
      termsAvailableMonth: 0,
      currentTermRenewalDate: '',
      renewalNoticeTime: '',
      renewalType: '',
      fullLeaseExpiration: '',
      autoRenewal: false,
      autoIncreaseAmount: false,
    },
    onSubmit: async (values) => {
      setLoading(true);

      Object.keys(values).forEach((key) => {
        if (moment(values[key], 'YYYY-MM-DD', true).isValid()) {
          values[key] = new Date(values[key]).toISOString();
        }
        if (values[key] === '') {
          delete values[key];
        }
      });

      try {
        if (id !== 'new') {
          await updateProperty({ ...values }, id);
          history.push(`/owner/${id}`);
        } else {
          const property = await createProperty({
            ...values,
            companyId: company.split(';')[0],
          });
          history.push(`/owner/${property.srNo}`);
        }
      } catch {
        toast.error('An error ocurred');
      } finally {
        setLoading(false);
      }
    },
  });

  const getProperty = async () => {
    const property = await getPropertyById(id);
    Object.keys(property).forEach((key) => {
      if (moment(property[key], moment.ISO_8601, true).isValid()) {
        property[key] = moment(property[key]).format('YYYY-MM-DD');
      }
    });
    form.setValues(property);
  };

  useEffect(() => {
    if (id !== 'new') {
      getProperty();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <Typography variant='h3'>Add New Property</Typography>
      <PropertyStepper step={0} propertyId={id} />
      <CreateEditPropertyForm
        form={form}
        onCancel={() => history.push('/properties')}
        loading={loading}
      />
    </Container>
  );
};

export default PropertyCreateEdit;
