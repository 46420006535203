import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';

export const Description = styled.div`
  width: ${(props) => props.width ?? '300px'};
  text-align: ${(props) => props.textAlign ?? 'start'};
  margin-bottom: 30px;
  @media only screen and (max-width: 750px) {
    width: 100%;
    & > div {
      margin: 0 !important;
    }
  }
`;

export const DescriptionPrice = styled(Description)`
  margin-bottom: 0px;
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  @media only screen and (max-width: 750px) {
    flex-direction: column-reverse;
  }
`;

export const Card = styled(Paper)`
  padding: 15px;
  background-color: #183650 !important;
  color: white !important;
  border-radius: 10px !important;
`;
