import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Typography } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';

import { Container } from '../styles';
import {
  CreateEditSecurityForm,
  PropertyStepper,
  SecuritiesTable,
} from '../../components';
import { updateProperty, getPropertyById } from '../../api/property';

const SecuritiesCreateEdit = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [securities, setSecurities] = useState([]);
  const { id } = useParams();

  const addSecurity = (security) => setSecurities([...securities, security]);

  const onSave = async () => {
    setLoading(true);
    try {
      await updateProperty({ securities }, id);
      history.push(`/equipment/${id}`);
    } catch {
      toast.error('An error ocurred');
    } finally {
      setLoading(false);
    }
  };

  const getProperty = async () => {
    const property = await getPropertyById(id);
    if (property.securities.length > 0) {
      setSecurities(property.securities);
    }
  };

  useEffect(() => {
    getProperty();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <Typography variant='h3'>Security</Typography>
      <PropertyStepper step={5} propertyId={id} />
      <CreateEditSecurityForm
        addSecurity={addSecurity}
        onSave={onSave}
        loading={loading}
      />
      <SecuritiesTable securities={securities} />
    </Container>
  );
};

export default SecuritiesCreateEdit;
