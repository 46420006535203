import React, { useContext, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';

import { SessionContext } from '../../context/sessionContext';
import { updateUser } from '../../api/user';

const UpdateUserModal = ({ user, isOpen, onClose }) => {
  const { userData } = useContext(SessionContext);
  const form = useFormik({
    initialValues: {
      username: '',
      email: '',
      mobile: '',
      role: '',
      companies: [],
    },
    onSubmit: async (values) => {
      try {
        await updateUser(user._id, values);
        toast.success('User updated successfully');
      } catch {
        toast.error('Could not update user');
      } finally {
        onClose();
      }
    },
  });

  useEffect(() => {
    console.log(user);
    form.setValues({
      username: user.username,
      email: user.email,
      mobile: user.mobile,
      role: user.role,
      companies: user.companies,
    });
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby='form-dialog-title'>
      <DialogTitle id='form-dialog-title'>Edit User</DialogTitle>
      <DialogContent>
        <TextField
          id='username'
          name='username'
          label='Full name'
          variant='outlined'
          disabled
          style={{ marginBottom: '1em' }}
          value={form.values.username}
          fullWidth
        />
        <TextField
          id='email'
          name='email'
          label='Email'
          variant='outlined'
          disabled
          style={{ marginBottom: '1em' }}
          value={form.values.email}
          fullWidth
        />
        <TextField
          id='mobile'
          name='mobile'
          label='Phone Number'
          variant='outlined'
          disabled
          style={{ marginBottom: '1em' }}
          value={form.values.email}
          fullWidth
        />
        <TextField
          id='role'
          name='role'
          label='Role'
          style={{ marginBottom: '1em' }}
          value={form.values.role}
          onChange={form.handleChange}
          variant='outlined'
          select
          fullWidth
        >
          <MenuItem value='admin'>Admin</MenuItem>
          <MenuItem value='supervisor'>Supervisor</MenuItem>
        </TextField>

        <p style={{ marginBottom: '1em' }}>Companies:</p>
        {userData?.companies?.map((company) => (
          <FormControlLabel
            key={company._id}
            control={
              <Checkbox
                name='companies'
                style={{ color: '#183650' }}
                checked={form.values.companies?.includes(company._id)}
                onChange={form.handleChange}
                value={company._id}
              />
            }
            label={company.name}
          />
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          Cancel
        </Button>
        <Button onClick={form.handleSubmit} color='primary'>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateUserModal;
