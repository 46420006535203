import React, { useEffect, useState } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import { countries } from '../../api/utils';

const CountryPicker = ({ value = '', setValue, required }) => {
  const [myCountries, setCountries] = useState(null);

  useEffect(() => {
    getCountries();
  }, []);

  const getCountries = async () => {
    const allCountries = await countries();
    setCountries(allCountries);
  };

  const change = (e) => {
    const selectCountry = myCountries.filter(
      (i) => i.isoCode === e.target.value
    )[0];
    setValue('country', selectCountry);
  };

  return (
    <FormControl fullWidth variant='outlined'>
      <InputLabel>Country</InputLabel>
      <Select
        labelId='country'
        required={required}
        name='country'
        value={value}
        onChange={change}
      >
        {myCountries?.map((option) => (
          <MenuItem key={option.isoCode} value={option.isoCode}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CountryPicker;
