import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { Typography } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';

import { Container } from '../styles';
import { CreateEditPersonForm, PropertyStepper } from '../../components';
import { updateProperty, getPropertyById } from '../../api/property';

const OwnerCreateEdit = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const form = useFormik({
    initialValues: {
      name: '',
      primaryEmail: '',
      otherEmail: '',
      address1: '',
      address2: '',
      country: {},
      state: {},
      city: '',
      zipCode: '',
      phone: '',
      otherPhone: '',
    },
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await updateProperty({ owner: { ...values } }, id);
        history.push(`/landlord/${id}`);
      } catch {
        toast.error('An error ocurred');
      } finally {
        setLoading(false);
      }
    },
  });

  const getProperty = async () => {
    const property = await getPropertyById(id);
    if (property.owner) {
      form.setValues(property.owner);
    }
  };

  useEffect(() => {
    getProperty();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <Typography variant='h3'>Owner</Typography>
      <PropertyStepper step={1} propertyId={id} />
      <CreateEditPersonForm
        form={form}
        onCancel={() => history.push('/properties')}
        loading={loading}
      />
    </Container>
  );
};

export default OwnerCreateEdit;
