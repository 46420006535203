import React, { createContext, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';

import { login } from '../api/user';

export const SessionContext = createContext();

export const SessionContextProvider = (props) => {
  const [userId, setUser] = useState(localStorage.getItem('user') ?? null);
  const [userData, setUserData] = useState(
    localStorage.getItem('userData')
      ? JSON.parse(localStorage.getItem('userData'))
      : null
  );
  const [company, setCompany] = useState(
    localStorage.getItem('company') ?? null
  );

  const signIn = async (data) => {
    const loginUser = await login(data);
    setCompany(loginUser.companies[0]._id);
    setUser(loginUser._id);
    setUserData(loginUser);
    localStorage.setItem('user', loginUser._id);
    localStorage.setItem('company', loginUser.companies[0]._id);
    localStorage.setItem('userData', JSON.stringify(loginUser));

    const cookie = new Cookies();
    cookie.remove('myHide');
  };

  const logout = () => {
    const cookie = new Cookies();
    cookie.set('myHide', false);
    localStorage.removeItem('user');
    localStorage.removeItem('company');
    localStorage.removeItem('userData');
    setUser(null);
    setUserData(null);
    setCompany(null);
    cookie.remove('myCat');
    cookie.remove('clientDashboardSelectedTab');
  };

  useEffect(() => {
    const cookie = new Cookies();
    cookie.set('myCat', company);
  }, [company]);

  return (
    <SessionContext.Provider
      value={{
        userId,
        company,
        setCompany,
        signIn,
        logout,
        userData,
        setUserData,
      }}
    >
      {props.children}
    </SessionContext.Provider>
  );
};
