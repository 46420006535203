import React, { useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { FormButtonContainer, FormContainer, FormInput } from './styles';
import Button from '../button';

const UtilitiesForm = ({ addUtility, onSave, loading, onCancel }) => {
  const [utility, setUtility] = useState({
    name: '',
    companyName: '',
    phone: '',
    account: '',
    pin: '',
    username: '',
    password: '',
    note: '',
  });

  const handleChange = (e) =>
    setUtility({ ...utility, [e.target.name]: e.target.value });

  return (
    <>
      <FormContainer container spacing={3}>
        <FormContainer item xs={12} md={3}>
          <FormInput
            name='name'
            variant='outlined'
            label='Utility'
            value={utility.name}
            onChange={handleChange}
          />
        </FormContainer>
        <FormContainer item xs={12} md={3}>
          <FormInput
            name='companyName'
            variant='outlined'
            label='Company Name'
            value={utility.companyName}
            onChange={handleChange}
          />
        </FormContainer>
        <FormContainer item xs={12} md={3}>
          <FormInput
            name='account'
            variant='outlined'
            label='Account'
            value={utility.account}
            onChange={handleChange}
          />
        </FormContainer>
        <FormContainer item xs={12} md={3}>
          <FormInput
            name='pin'
            variant='outlined'
            label='PIN'
            value={utility.pin}
            onChange={handleChange}
          />
        </FormContainer>
        <FormContainer item xs={12} md={3}>
          <FormInput
            name='username'
            variant='outlined'
            label='User Name'
            value={utility.username}
            onChange={handleChange}
          />
        </FormContainer>
        <FormContainer item xs={12} md={3}>
          <FormInput
            name='phone'
            variant='outlined'
            label='Phone'
            value={utility.phone}
            onChange={handleChange}
          />
        </FormContainer>
        <FormContainer item xs={12} md={3}>
          <FormInput
            name='password'
            variant='outlined'
            label='Password'
            value={utility.password}
            onChange={handleChange}
          />
        </FormContainer>
        <FormContainer item xs={12} md={3}>
          <FormInput
            name='note'
            variant='outlined'
            label='Note'
            value={utility.note}
            onChange={handleChange}
          />
        </FormContainer>
      </FormContainer>
      <FormButtonContainer>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <div>
              <Button variant='contained' color='secondary' onClick={onCancel}>
                Cancel
              </Button>
              <Button
                variant='contained'
                custom='success'
                onClick={() => {
                  addUtility(utility);
                  setUtility({
                    name: '',
                    companyName: '',
                    phone: '',
                    account: '',
                    pin: '',
                    username: '',
                    password: '',
                    note: '',
                  });
                }}
                endIcon={<AddCircleOutlineIcon />}
              >
                Add
              </Button>
            </div>
            <Button
              variant='contained'
              type='submit'
              custom='success'
              onClick={onSave}
              endIcon={<ArrowForwardIosIcon />}
            >
              Save and Continue
            </Button>
          </>
        )}
      </FormButtonContainer>
    </>
  );
};
export default UtilitiesForm;
