import React from 'react';
import { toast } from 'react-toastify';
import { Typography } from '@material-ui/core';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

import { createStripe, updateStripe } from '../../api/bill';
import Button from '../button';

const StripeCard = ({ bill, userData, setUserData }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { token, error } = await stripe.createToken(
      elements.getElement(CardElement)
    );

    if (!!error) {
      toast.error('An error ocurred');
    } else {
      if (!!userData.cusId && !!userData.pmId) {
        const user = await updateStripe(userData._id, token.id);
        setUserData(user);
      } else {
        const user = await createStripe(userData._id, token.id);
        setUserData(user);
      }
    }
  };

  return (
    <div style={{ textAlign: 'left' }}>
      {!!bill.card ? (
        <>
          <Typography variant='h6'>Current Credit Card</Typography>
          <Typography>
            xxxx-xxxx-xxxx-{bill.card.last4} Exp Date: {bill.card.exp_month}/
            {bill.card.exp_year}
          </Typography>
        </>
      ) : (
        <Typography variant='h6'>No Credit Card</Typography>
      )}
      <form onSubmit={handleSubmit}>
        <div style={{ margin: '20px 0', width: '100%', maxWidth: 400 }}>
          <CardElement />
        </div>
        <Button custom='success' variant='outlined' type='submit'>
          Add Credit Card
        </Button>
      </form>
    </div>
  );
};

export default StripeCard;
