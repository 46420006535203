import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

import Button from '../button';
import useWindowSize from '../../utils/useWindowSize';
import { Container, Description, DescriptionPrice } from './styles';

const PlanDescription = ({
  subscription,
  onSubscription,
  onChancelSubscription,
  showFree,
  isSubscribe,
}) => {
  const [width] = useWindowSize();

  return (
    <>
      {isSubscribe && (
        <Typography
          variant={width > 775 ? 'h4' : 'h6'}
          style={{ textAlign: 'left', marginBottom: 30 }}
        >
          You are currently subscribed to - {subscription}
          <br />
          <Button custom='success' onClick={onChancelSubscription}>
            Cancel subscription
          </Button>
        </Typography>
      )}
      <Container>
        {showFree && (
          <Description width={showFree ? '33.33%' : '50%'}>
            <Card style={{ marginRight: 60 }}>
              <CardActionArea onClick={() => onSubscription('pro')}>
                <CardContent>
                  <Typography variant='h4'>30 Day Trial</Typography>
                  <Container>
                    <Description width='50%'>
                      <Typography style={{ color: '#00c851' }} variant='h4'>
                        FREE
                      </Typography>
                    </Description>
                  </Container>
                  <Typography>
                    <CheckIcon style={{ color: '#00c851' }} fontSize='large' />
                    Unlimited number of properties
                  </Typography>
                  <Typography>
                    <CheckIcon style={{ color: '#00c851' }} fontSize='large' />
                    Unlimited users with Roles and Permissions
                  </Typography>
                  <Typography>
                    <CheckIcon style={{ color: '#00c851' }} fontSize='large' />
                    Track Utility Companies
                  </Typography>
                  <Typography>
                    <CheckIcon style={{ color: '#00c851' }} fontSize='large' />
                    Track Equipment and Warranties
                  </Typography>
                  <Typography>
                    <CheckIcon style={{ color: '#00c851' }} fontSize='large' />
                    Track Security Information
                  </Typography>
                  <Typography>
                    <CheckIcon style={{ color: '#00c851' }} fontSize='large' />
                    Upload Lease Documents
                  </Typography>
                  <Typography>
                    <CheckIcon style={{ color: '#00c851' }} fontSize='large' />
                    Create and Manage Multiple Companies
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Description>
        )}
        <Description width={showFree ? '33.33%' : '50%'}>
          <Card style={{ marginRight: 30 }}>
            <CardActionArea onClick={() => onSubscription('basic')}>
              <CardContent>
                <Typography variant='h4'>Basic</Typography>
                <Container>
                  <DescriptionPrice width='100%'>
                    <Typography style={{ color: '#00c851' }} variant='h4'>
                      $9.99 / Month
                    </Typography>
                  </DescriptionPrice>
                  <Description width='100%' textAlign='flex-start'>
                    <Typography style={{ color: '#00c851' }} variant='h4'>
                      $99.00 / Year
                      <Typography style={{ color: '#ea0e0e' }}>
                        Save 20%
                      </Typography>
                    </Typography>
                  </Description>
                </Container>
                <Typography>
                  <CheckIcon style={{ color: '#00c851' }} fontSize='large' />
                  Unlimited number of properties
                </Typography>
                <Typography>
                  <CheckIcon style={{ color: '#00c851' }} fontSize='large' />
                  Unlimited users with Roles and Permissions
                </Typography>
                <Typography>
                  <CheckIcon style={{ color: '#00c851' }} fontSize='large' />
                  Track Utility Companies
                </Typography>
                <Typography>
                  <CheckIcon style={{ color: '#00c851' }} fontSize='large' />
                  Track Equipment and Warranties
                </Typography>
                <Typography>
                  <CheckIcon style={{ color: '#00c851' }} fontSize='large' />
                  Track Security Information
                </Typography>
                <Typography>
                  <ClearIcon style={{ color: '#ea0e0e' }} fontSize='large' />
                  Upload Lease Documents
                </Typography>
                <Typography>
                  <ClearIcon style={{ color: '#ea0e0e' }} fontSize='large' />
                  Create and Manage Multiple Companies
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Description>
        <Description width={showFree ? '33.33%' : '50%'}>
          <Card style={{ marginLeft: 30 }}>
            <CardActionArea onClick={() => onSubscription('pro')}>
              <CardContent>
                <Typography variant='h4'>Pro</Typography>
                <Container>
                  <DescriptionPrice width='100%'>
                    <Typography style={{ color: '#00c851' }} variant='h4'>
                      $29.99 / Month
                    </Typography>
                  </DescriptionPrice>
                  <Description width='100%' textAlign='flex-start'>
                    <Typography style={{ color: '#00c851' }} variant='h4'>
                      $289.00 / Year
                      <Typography style={{ color: '#ea0e0e' }}>
                        Save 20%
                      </Typography>
                    </Typography>
                  </Description>
                </Container>
                <Typography>
                  <CheckIcon style={{ color: '#00c851' }} fontSize='large' />
                  Unlimited number of properties
                </Typography>
                <Typography>
                  <CheckIcon style={{ color: '#00c851' }} fontSize='large' />
                  Unlimited users with Roles and Permissions
                </Typography>
                <Typography>
                  <CheckIcon style={{ color: '#00c851' }} fontSize='large' />
                  Track Utility Companies
                </Typography>
                <Typography>
                  <CheckIcon style={{ color: '#00c851' }} fontSize='large' />
                  Track Equipment and Warranties
                </Typography>
                <Typography>
                  <CheckIcon style={{ color: '#00c851' }} fontSize='large' />
                  Track Security Information
                </Typography>
                <Typography>
                  <CheckIcon style={{ color: '#00c851' }} fontSize='large' />
                  Upload Lease Documents
                </Typography>
                <Typography>
                  <CheckIcon style={{ color: '#00c851' }} fontSize='large' />
                  Create and Manage Multiple Companies
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Description>
      </Container>
    </>
  );
};

export default PlanDescription;
