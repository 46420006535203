import React, { useContext } from 'react';
import { useHistory, useParams } from 'react-router';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { AccountContext } from '../../context/account';

const ConfirmUser = () => {
  const history = useHistory();
  const { confirmSignUp } = useContext(AccountContext);
  const { code, username } = useParams();

  if (code && username) {
    confirmSignUp(username, code);
  }

  return (
    <div style={{ marginTop: 100 }}>
      <Typography variant='h2' gutterBottom>
        Your Registration has been confirmed!
      </Typography>
      <Button
        size='large'
        variant='contained'
        color='primary'
        onClick={() => history.push('/#login')}
      >
        <Typography variant='h3' component='h2'>
          LOGIN
        </Typography>
      </Button>
    </div>
  );
};

export default ConfirmUser;
