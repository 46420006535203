import React, { useContext, useState } from 'react';
import { useFormik } from 'formik';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { MDBBtn, MDBCol, MDBRow } from 'mdbreact';

import { invite } from '../../api/user';
import { SessionContext } from '../../context/sessionContext';
import { toast } from 'react-toastify';

const InviteUserForm = () => {
  const [loading, setLoading] = useState(false);
  const { userData } = useContext(SessionContext);
  const [companiesIds, setIds] = useState(
    userData.companies.map((company) => company._id)
  );

  const form = useFormik({
    initialValues: {
      username: '',
      email: '',
      role: '',
    },
    onSubmit: async (values) => {
      setLoading(true);
      try {
        if (userData.role === 'owner') {
          await invite({ ...values, companiesIds, ownerId: userData._id });
        } else {
          await invite({ ...values, companiesIds, ownerId: userData.workFor });
        }
        toast.success('Invitation successfully send');
      } catch {
        toast.error('An error ocurred, please try again leather');
      } finally {
        setLoading(false);
      }
    },
  });

  const handleCompanies = (e) => {
    if (companiesIds.includes(e.target.value)) {
      setIds(companiesIds.filter((id) => id !== e.target.value));
      return;
    }
    setIds([...companiesIds, e.target.value]);
  };

  return (
    <MDBRow style={{ marginTop: '50px' }} className='animation'>
      <MDBCol md='6' style={{ paddingLeft: '55px' }}>
        <form onSubmit={form.handleSubmit}>
          <h4
            className='mb-4'
            style={{
              color: '#183650',
              fontWeight: '500',
              textAlign: 'left',
            }}
          >
            Send Invites
          </h4>
          <MDBRow className='invrow'>
            <MDBCol md='6'>
              <label
                htmlFor='defaultFormRegisterNameEx'
                className='grey-text float-left font12'
              >
                Full Name
              </label>
              <input
                required
                type='text'
                name='username'
                className='form-control'
                style={{ borderRadius: '0.25rem' }}
                value={form.values.username}
                onChange={form.handleChange}
              />
              <br />
              <label
                htmlFor='defaultFormRegisterEmailEx'
                className='grey-text float-left font12'
              >
                Email
              </label>
              <input
                required
                type='email'
                name='email'
                className='form-control'
                style={{ borderRadius: '0.25rem' }}
                value={form.values.email}
                onChange={form.handleChange}
              />
              <br />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'start',
                }}
              >
                <label
                  htmlFor='defaultFormRegisterConfirmEx'
                  className='grey-text float-left font12'
                >
                  Companies
                </label>
                {userData?.companies?.map((company) => (
                  <FormControlLabel
                    key={company._id}
                    control={
                      <Checkbox
                        style={{ color: '#183650' }}
                        checked={companiesIds.includes(company._id)}
                        value={company._id}
                        onChange={handleCompanies}
                      />
                    }
                    label={company.name}
                  />
                ))}
              </div>
            </MDBCol>
            <MDBCol md='6'>
              <label
                htmlFor='defaultFormRegisterConfirmEx'
                className='grey-text float-left font12'
              >
                Phone Number
              </label>
              <input
                type='text'
                name='mobile'
                className='form-control'
                style={{ borderRadius: '0.25rem' }}
                value={form.values.mobile}
                onChange={form.handleChange}
              />
              <br />
              <div>
                <label className='grey-text float-left font12'>
                  Access Control
                </label>
                <select
                  required
                  name='role'
                  class='browser-default custom-select invsel'
                  style={{ borderRadius: '0.25rem' }}
                  onChange={form.handleChange}
                >
                  <option value='' disabled selected hidden>
                    Choose AccessControl...
                  </option>
                  <option value='admin'>Admin</option>
                  <option value='supervisor'>Supervisor</option>
                </select>
              </div>
            </MDBCol>
          </MDBRow>
          <div style={{ textAlign: 'left' }}>
            {loading ? (
              <div className='spinner-border text-danger' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
            ) : (
              <MDBBtn
                type='submit'
                color='success'
                className='roundcorner'
                rounded
                style={{
                  padding: '10px 20px',
                  marginTop: '20px',
                  lineHeight: '1.2',
                }}
              >
                Invite
              </MDBBtn>
            )}
          </div>
        </form>
      </MDBCol>
    </MDBRow>
  );
};

export default InviteUserForm;
